import React, { Component, createRef } from 'react'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckSquare, faArrowCircleRight, faBoxOpen, faQuestionCircle, faCheck
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faDochub } from '@fortawesome/free-brands-svg-icons/faDochub'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons/faSuitcase'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
import Complements from '../components/complements'
import offer7 from '../image/offer-7.png'
import offer4 from '../image/offer-4.jpg'
import offer2 from '../image/offer-2.png'
import offer9 from '../image/offer-9.png'
import '../pages/purchase/stylesheet.scss'
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";

library.add(faCheckSquare, faArrowCircleRight)

class InstructionsEmbed extends Component {
  constructor(props) {
    super(props)
    this.facebookPart = createRef()

      this.state = {
          visibleModale: false
      }
  }

  renderGroup = (step, challenge) => {
    return (
      <div className="square-pack" ref={this.facebookPart}>
        <div className="square-pack__left">
          <FontAwesomeIcon icon={faFacebookF} />
        </div>
        <div className="square-pack__right">
          <div className="square-pack__right__facebook">
            Facebook
          </div>
          <div className="square-pack__right__instructions">
            Demande à rejoindre <br />le groupe facebook
          </div>
        </div>
      </div>
    )
  }

    renderPack = (step, version) => {
      return (
        <div className="square-pack">
          <div className="square-pack__left">
            <FontAwesomeIcon icon={faSuitcase} />
          </div>
          <div className="square-pack__right square-pack__right--big">
            <div className="square-pack__right__facebook square-pack__right--hide">
                Pack démarrage
            </div>
            <div className="square-pack__right__instructions square-pack__right__instructions--bold">
              Télécharge<br /> ton pack <br />de démarrage
            </div>
          </div>
        </div>
      )
    }
    renderPack5 = (step, version) => {
      return (
        <div className="square-pack">
          <div className="square-pack__left">
            <FontAwesomeIcon icon={faSuitcase} />
          </div>
          <div className="square-pack__right square-pack__right--big">
            <div className="square-pack__right__facebook square-pack__right--hide">
                Pack transformation
            </div>
            <div className="square-pack__right__instructions square-pack__right__instructions--bold">
              Télécharge<br /> ton pack <br />de transformation
            </div>
          </div>
        </div>
      )
    }

    infoMFC5 = () => {
      const { isPack } = this.state
      const { downloadPdf } = this.props
      return (

        <Modal
          visible
          title="TÉLÉCHARGE LES PDFS DU MFC SUMMER SHREDDING 2"
          closable
          className="checkout__modal__root"
          onCancel={() => this.setState({ visibleModale: false })}
          footer={[
            <Button key="submit" type="primary" onClick={() => this.setState({ visibleModale: false })}>
                        fermer
            </Button>,
          ]}
        >
          <div className="checkout__modal">

            <div className="checkout__modal__half">

              <div className="checkout__modal__item checkout__modal__item--pdf" onClick={() => downloadPdf('/my_healthy_book.pdf')}>
                <div className="checkout__modal__item__photo">
                  <FontAwesomeIcon icon={faFilePdf} />
                </div>
                <div className="checkout__modal__item__text">
                                PDF NUMÉRO 1
                  <div className="checkout__modal__item__text__desc">
                                    My Healthy Book
                  </div>
                </div>
              </div>
                <div className="checkout__modal__item checkout__modal__item--pdf" onClick={() => downloadPdf('/guide_motivation_nutrition.pdf')}>
                    <div className="checkout__modal__item__photo">
                        <FontAwesomeIcon icon={faFilePdf} />
                    </div>
                    <div className="checkout__modal__item__text">
                        PDF NUMÉRO 2
                        <div className="checkout__modal__item__text__desc">
                            Guide Motivation Nutrition
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout__modal__half">
              <div className="checkout__modal__item checkout__modal__item--pdf checkout__modal__item__small" onClick={() => downloadPdf('/guide_de_transformation.pdf')}>
                <div className="checkout__modal__item__photo">
                    <FontAwesomeIcon icon={faFilePdf} />
                </div>
                <div className="checkout__modal__item__text">
                    PDF NUMÉRO 3
                  <div className="checkout__modal__item__text__desc">
                      Guide Transformation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    infoMFC55 = () => {
      const { isPack } = this.state
      const { downloadPdf } = this.props
      return (

        <Modal
          visible
          title="TÉLÉCHARGE LES PDFS DU MFC SUMMER SHREDDING 2"
          closable
          className="checkout__modal__root"
          onCancel={() => this.setState({ visibleModale: false })}
          footer={[
            <Button key="submit" type="primary" onClick={() => this.setState({ visibleModale: false })}>
                        fermer
            </Button>,
          ]}
        >
          <div className="checkout__modal">

            <div className="checkout__modal__half">

              <div className="checkout__modal__item checkout__modal__item--pdf" onClick={() => downloadPdf('/my_healthy_book_ss2.pdf')}>
                <div className="checkout__modal__item__photo">
                  <FontAwesomeIcon icon={faFilePdf} />
                </div>
                <div className="checkout__modal__item__text">
                                PDF NUMÉRO 1
                  <div className="checkout__modal__item__text__desc">
                                    My Healthy Book
                  </div>
                </div>
              </div>
                <div className="checkout__modal__item checkout__modal__item--pdf" onClick={() => downloadPdf('/guide_motivation_nutrition_ss2.pdf')}>
                    <div className="checkout__modal__item__photo">
                        <FontAwesomeIcon icon={faFilePdf} />
                    </div>
                    <div className="checkout__modal__item__text">
                        PDF NUMÉRO 2
                        <div className="checkout__modal__item__text__desc">
                            Guide Motivation Nutrition
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout__modal__half">
              <div className="checkout__modal__item checkout__modal__item--pdf checkout__modal__item__small" onClick={() => downloadPdf('/guide_de_transformation_ss2.pdf')}>
                <div className="checkout__modal__item__photo">
                    <FontAwesomeIcon icon={faFilePdf} />
                </div>
                <div className="checkout__modal__item__text">
                    PDF NUMÉRO 3
                  <div className="checkout__modal__item__text__desc">
                      Guide Transformation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    render() {
      const {
        groupeUrl, isLive, downloadPdf, version, name, youtubeCode, starterPackPdfLink, rules, mfc
      } = this.props

      const instructsOld = (version === 45 || version === 55) ? (
        [
          'Toutes les séances REPLAY sont ici sur ton espace personnel et uniquement ici !',
          'Chaque semaine, je dois effectuer les 3 à 5 HIIT du programme',
          'Je ne dois jamais cumuler 2 HIIT dans la même journée OU cumuler avec une autre séance à la suite.',
          'Un planning de l’édition live est mis à ta disposition mais tu peux organiser ta semaine comme tu le souhaites.',
          'Le programme se suffit à lui même, le but est d’aller au bout de ton programme.'
        ]
      ) : (isLive ? [
        'Chaque semaine, je dois effectuer 2 à 5 HIIT',
        'Je ne dois jamais cumuler 2 HIIT dans la même journée',
        'Je dois privilégier les séances au maximum en LIVE',
        `Je dois suivre au minimum 2 HIIT par semaine pendant mes ${(version === 45 || version === 55) ? '6 semaines' : '2 mois'} de programme (je rattrape plus tard mes séances si nécessaire)`
      ] : [
        'Chaque semaine, je dois effectuer 2 à 5 HIIT',
        'Je ne dois jamais cumuler 2 HIIT dans la même journée',
        'Je dois effectuer mes séances quand je le souhaite dans ma semaine',
        `Je dois faire au minimum 2 HIIT par semaine pendant mes  ${(version === 45  || version === 55 )? '6 semaines' : '2 mois'} de programme`
      ])

      const instructs = rules

      return (
        <React.Fragment>
            { this.state.visibleModale && mfc.edition === 5 && this.infoMFC5()}
            { this.state.visibleModale && mfc.edition === 55 && this.infoMFC55()}
          <div className="instructions__packs">
            {
                  groupeUrl && (
                  <a href={groupeUrl} rel="noopener noreferrer" target="_blank" className="instructions__packs__pack">
                    { this.renderGroup(1, name) }
                  </a>
                  )
              }
            {
                mfc && (mfc.edition === 5 || mfc.edition === 55) ? (
                  <a onClick={() => this.setState({visibleModale: true})} className="instructions__packs__pack" style={{ paddingTop: 11 }}>
                    { this.renderPack5((groupeUrl ? 2 : 1), version) }
                  </a>
                ) : (

                  starterPackPdfLink && (
                  <a onClick={() => downloadPdf(starterPackPdfLink)} className="instructions__packs__pack" style={{ paddingTop: 11 }}>
                    { this.renderPack((groupeUrl ? 2 : 1), version) }
                  </a>
                  ))
              }
          </div>

          <div className="instructions__pack-info">
            {
                  starterPackPdfLink && (
                  <Link to="/help">
                          Des problèmes pour visualiser le pack <FontAwesomeIcon icon={faQuestionCircle} />
                  </Link>
                  )
              }
          </div>
          <div className="instructions__disclaimer">
             Vidéo à regarder impérativement avant de commencer ton programme
          </div>
          <iframe
            style={{ marginTop: 15 }}
            className="youtube-player"
            frameBorder="0"
            allowFullScreen="1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            width="640"
            height="360"
          // src={`https://www.youtube.com/embed/${youtubeCode}?rel=0&amp;autoplay=0&amp;autohide=1&amp;showinfo=0&amp;enablejsapi=1&amp;widgetid=1;wmode=transparent`}
            src={`https://www.youtube.com/embed/${youtubeCode}?autoplay=0;mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent`}
            id="widget2"
          />
          <div className="instructions">
            <div className="instructions__title">
            Les commandements du MyFIIT! <div className="instructions__title__sub">Pour accomplir ton programme :</div>
            </div>
            { instructs && instructs.map(i => (
              <div className="instructions__todo" key={`loop-${i}`}>
                <div className="instructions__todo__checkbox">
                  <FontAwesomeIcon icon="check-square" />
                </div>
                <div className="instructions__todo__text">{i}</div>
              </div>
            ))}

            {
              /* (
              <div className="instructions__todo" style={{ marginTop: 15 }}>
                <div className="instructions__todo__checkbox">
                  <FontAwesomeIcon icon="arrow-circle-right" />
                </div>
                <div className="instructions__todo__text">
                  <a className="instructions__material__link" href={`/${name}/materiel`}>Matériel conseillé</a>
                </div>
              </div>
            ) */
          }
          </div>

          <Complements name={name} />
        </React.Fragment>
      )
    }
}

export default InstructionsEmbed
