import React, { Component } from 'react'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Complements from '../components/complements'
import Youtube from '../components/youtube'
import Title from '../components/title'
import AppContainer from "../containers/AppContainer";

library.add(faCheckSquare)

const instructs = ['Chaque semaine, je dois effectuer 2 à 5 HIIT', 'Je ne dois jamais cumuler 2 HIIT dans la même journée', 'Je dois effectuer mes séances quand je le souhaite dans ma semaine', 'Je dois faire au minimum 2 HIIT par semaine pendant mes 2 mois de programme (je rattrape plus tard mes séances si nécessaire)']

class Instructions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {}

  render() {
    const { groupeUrl, version, name } = this.props

    return (
      <AppContainer>
        <Header icon="arrow-left" navigationLeft="/" navigationRight="/my-fiit-challenges" />
        <Breadcrumb icon="broadcast-tower" title={`MFC ${version}`} italic="Live" />
        <div className="main-content">
          <Youtube video="s15nsmeumhg" />
          <div className="instructions">
            <div className="instructions__title">
              Les commandements du MyFiit! Pour accomplir ton programme :
            </div>
            { instructs.map(i => (
              <div className="instructions__todo" key={`loop-${i}`}>
                <div className="instructions__todo__checkbox">
                  <FontAwesomeIcon icon="check-square" />
                </div>
                <div className="instructions__todo__text">{i}</div>
              </div>
            ))}
          </div>
          <a href={groupeUrl} target="_blank" rel="noopener noreferrer" className="instructions__join">
            Clique ici et demande à rejoindre le groupe
          </a>
          <Complements name={name} />
        </div>
      </AppContainer>
    )
  }
}

export default Instructions
