import React, { Component } from 'react'
import './stylesheet.scss'

import { withCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import Header from '../../components/header'
import AppContainer from '../../containers/AppContainer'

class Vimeo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0
    }
  }

  render() {
    const { active } = this.state
    console.log(this.props)
    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/my-fiit-challenges" isBackground />
        <div className="test">
          <div className="test__content">
            LIVE EMBED
            <iframe src={`https://vimeo.com/event/${this.props.match.params.id}/embed`} width="640" height="360" allow="autoplay; fullscreen" allowFullScreen />
            <code>
              {`<iframe src={https://vimeo.com/event/${this.props.match.params.id}/embed} width="640" height="360" allow="autoplay; fullscreen" allowFullScreen />`}
            </code>
          </div>
          <div className="test__content">
            EXISTING VIDEO
            <iframe src={`https://player.vimeo.com/video/${this.props.match.params.id}`} width="640" height="360" allow="autoplay; fullscreen" allowFullScreen />
            <code>
              {`<iframe src={https://player.vimeo.com/video/${this.props.match.params.id}} width="640" height="360" allow="autoplay; fullscreen" allowFullScreen />`}
            </code>
          </div>
        </div>

      </AppContainer>
    )
  }
}

export default withCookies(withRouter(Vimeo))
