/**
 * Requesting a dispatch to make easier to make request
 * @param {String} type - The type to dispatch
 * @param {Promise} request - The request
 * @param {Function} dispatch - The dispatch function
 * by Brockard
 */

import { setToken, getToken, removeToken } from '../token'
import { refreshToken } from '../auth/actions'

export const dispatchRequest = async (type = 'REQUEST', request, dispatch, other) => {
  dispatch({
    type
  })

  try {
    const response = await request
    const opt = other && { [other]: true }
    return (
      dispatch({
        type: `${type}_SUCCESS`,
        data: response.data,
        opt
      }) && other && setTimeout(() => {
        dispatch({
          type: `${type}_SUCCESS`,
          data: response.data,
          [other]: false
        })
      }, 1000)
    )
  } catch (err) {
    if (getToken('mfc-token')
    && getToken('mfc-token') !== ''
    && getToken('mfc-token') !== 'undefined') {
      return dispatch(refreshToken(getToken('mfc-token')))
    }
    return dispatch({
      type: `${type}_ERROR`,
      message: err.message,
      [other]: false
    })
  }
}
