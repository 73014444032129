import React, { Component } from 'react'
import '../complements/stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'

library.add(faArrowCircleRight)

class Removal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/" goBackNav goBackLabel="Acceuil" isBackground isRemove />
        {/* <Breadcrumb title="Compléments alimentaires" /> */ }
        <div className="mfc mfc--no-padding mfc--live mfc--presentation mfc--presentation--small">
          <div className="mfc__left">
            <div className="mfc__left__content">
              <div style={{ marginTop: 15 }}>
                <a className="complements-part__head__back" href={`/`}>Retour</a>
              </div>
              <div className="mfc__left__content__global" style={{padding: 30}}>
                <div style={{color: 'red', fontWeight: 700, marginTop: 40}}>
                  ATTENTION, si vous retirez votre compte facebook de la plateforme MyFiitChallenge, nous ne serons plus en mesure de vous identifier, vos achats et actions sur le site seront perdus.
                  <br /><br /><br />
                </div>
                <div>
                  Rendez-vous sur <a target={'_blank'} href='https://www/facebook.com'>facebook.com</a><br />
                  Entrez dans le menu "Réglages" qui se trouve en haut à droite du site<br />
                  Selectionnez "Paramètres et confidentialité", puis "Paramètres", dans le menu de gauche<br />
                  Seléctionnez "Applications et site webs" vous y verrez "MyFiitChallenge"<br />
                  Cliquez sur "Supprimer"
                </div>
                <br /><br />
                <div>
                  Vous pouvez également nous contacter <a style={{borderRadius: 8, padding: 8, background: '#FD553F', color: '#FFFFFF'}} href="mailto:contact@myfiitchallenge.com?subject=Suppression de mes données MyFiitchallenge&body=Bonjour, je souhaiterai retirer mes données personnelles du site MyfiitChallenge.com">cliquant ici</a> nous prendrons contact avec vous et nous nous chargerons de retirer l'intégralité de vos données de notre site.
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    )
  }
}

export default withRouter(Removal)
