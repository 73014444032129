import React, { Component } from 'react'
import './stylesheet.scss'

import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faPowerOff, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { withCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import * as logo from '../../image/logo-mfc-blanc.png'
import { deleteUser } from '../../redux/auth/actions'

library.add(faThLarge, faPowerOff, faArrowLeft)

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  logoutFb = () => {
    const { cookies } = this.props
    cookies.remove('mfc-token')
    cookies.remove('facebook-user')
    if (window.FB) {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.logout((resp) => {
            localStorage.clear()
            const cookies = document.cookie.split(';')

            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i]
              const eqPos = cookie.indexOf('=')
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
              document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
            }
            window.location.href = '/'
          })
        } else {
          window.location.href = '/'
        }
      })
    } else {
      window.location.href = '/'
    }
  }

  render() {
    const {
      icon, navigationLeft, navigationRight, onClick, goBackNav, goBackLabel, account, isBackground, isReplay, children, help, powerOff, challenge, isDashboard, isBack, isRemove
    } = this.props

    const handleDeleteAccount = () => {
      const confirmation = window.confirm("Êtes-vous sûr de vouloir définitivement supprimer votre compte ? toutes vos données seront perdues, ainsi que vos achats et vos challenges");

      if (confirmation) {
        this.props.deleteUser()
        this.logoutFb();
      }
    }

    return (
      <div className={classNames({
        header: true,
        'header--image': isBackground,
        'header--small': help
      })}
      >
        {
          (help) ? (
            <div className="header__left-part" />
          ) : (isDashboard ? (
            <div onClick={() => this.logoutFb()} className="header__left-part">
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
          ) : (
            <Link to="/my-fiit-challenges" className="header__left-part">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          )
          )
        }

        <div className="header__center-part">
          { isBackground && (
          <div className={classNames({
            'header__center-part__logo': true,
            'header__center-part__logo--help': help
          })}
          >
            <img src={logo} />
          </div>
          )}
          {
            !help && (
            <>
              <div className="header__center-part__avatar">
                <img src={account && account.user && account.user.avatar} />
              </div>
              <div className="header__center-part__name">
                { account && account.user && account.user.name }
              </div>
            </>
            )
          }
          {
            isRemove ?  (
              <div className="header-help-container">
                <div className="header-help-container__title">Gestion du compte Facebook</div>
                <div className="header-help-container__question">Vous souhaitez retirer votre compte facebook de notre site ?</div>
                <div className="header-help-container__question">Nous vous indiquons les démarches à suivre</div>
              </div>
              ) : (
              <>
                {
                  isBackground && !help ? (
                    <>
                      <div className="header__center-part__logout" style={{display: 'flex', gap: 8}}>
                        <Link to="/help" className="header__center-part__icon">
                          Se connecter à un autre compte {" "}
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </Link>
                        <div onClick={handleDeleteAccount} style={{cursor: 'pointer'}}>/ supprimer mon compte</div>
                      </div>
                    </>
                  ) : (
                    <>
                      {
                        help ? (
                          <div className="header-help-container">
                            <div className="header-help-container__title">Section AIDE</div>
                            <div className="header-help-container__question">Vous rencontrez des problèmes d'utilisation ?</div>
                            <div className="header-help-container__question">Regardez notre section de tutoriels</div>
                          </div>
                        ) : (
                          <div className="header__center-part__logout">
                            {
                              challenge || `Version ${isReplay ? 'REPLAY' : 'LIVE'}`
                            }
                          </div>
                        )
                      }
                    </>
                  )
                }
              </>

            )}

          <div className="header__center-part__children">
            { children }
          </div>
        </div>
        <div style={{display: 'flex', flexDirection:'row'}}>
          {
            (!help ? (
                <Link to="/help" style={{color: '#FFF', textDecoration: "underline"}}>
                  Aide
                </Link>
              ) : (
                <div onClick={() => window.history.back()} className="header__right-part header__right-part--large">
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )
            )
          }{" "}-{" "}
          <Link to="/politique-de-confidentialite" style={{color: '#FFF', textDecoration: "underline"}}>
            Politique de confidentialité
          </Link>
          
        </div>
      </div>
    )
  }
}


export default connect(undefined, {
  deleteUser
})(withCookies(Header));
