import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faStopwatch
} from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import * as Scroll from 'react-scroll'
import Header from '../../components/header'
import AppContainer from '../../containers/AppContainer'
import capture1 from '../../image/capture1.png'
import capture2 from '../../image/capture2.png'
import capture3 from '../../image/capture3.png'
import capture4 from '../../image/capture4.png'
import capture5 from '../../image/capture5.png'
import capture6 from '../../image/capture6.png'
import capture7 from '../../image/capture7.png'
import capture8 from '../../image/capture8.png'
import capture9 from '../../image/capture9.png'
import './stylesheet.scss'
library.add(faStopwatch)
const Element = Scroll.Element
const scroll = Scroll.animateScroll
export const HelpCache = () => (
  <div className="help-container__section">
    <div className="help-container__section__title">
        Vider le cache du navigateur
    </div>
    <div className="help-container__section__text">
        Si vous rencontrez un dysfonctionnement sur votre espace personnel, ou des erreurs d’affichage, nous vous invitons dans un premier temps à vider le cache de votre navigateur.
    </div>
    <div className="help-container__section__text">
      <u>Voici les raccourcis sur ordinateur, à effectuer avec le navigateur ouvert :</u>
    </div>
    <div className="help-container__section__text">
        - CTRL + F5 depuis un PC WINDOWS<br />
        - ⌘ + Maj + R sur MAC
    </div>
    <div className="help-container__section__text">
        Vous pouvez également vider le cache directement depuis les options/paramètres du navigateur, dans les rubriques Vie privée ou Confidentialité > Cookie et données de site / Données de navigation.
    </div>
    <div className="help-container__section__text">
      <b>Iphone</b>: Rendez-vous dans réglages > Safari > Effacer historique, données de site
    </div>
  </div>
)
export const HelpConnexion = () => (
  <div className="help-container__section">
    <div className="help-container__section__title">
      Connexion à l’espace personnel
    </div>
    <div className="help-container__section__text">
      My Fiit Challenge utilise la fonctionnalité facebook login, Vous devez donc OBLIGATOIREMENT disposer d’un compte facebook pour accéder à l’espace personnel.<br /><br />
      Si vous ne disposez pas de compte facebook, nous vous invitons à en créer un spécifiquement pour l’inscription. La création est gratuite, rapide, et peut être anonyme en utilisant un pseudonyme et une image de votre choix à la place de votre photo.<br /><br />
      Si vous ne souhaitez pas créer de compte facebook, l’inscription sera impossible.
    </div>
    <div className="help-container__section__title">
      Le bouton de connexion facebook ne répond pas ?
    </div>
    <div className="help-container__section__text">
        Tout d'abord, assurez-vous <b>de ne pas vous connecter à l'espace personnel via un lien depuis un e-mail.</b> Copiez collez le lien directement dans votre navigateur :<br />
        <a href="https://espace-personnel.myfiitchallenge.com/" rel="noopener noreferrer" target="_blank">https://espace-personnel.myfiitchallenge.com/</a> <br /><br />
        Vous pouvez également passer par une recherche google "myfiitchallenge", puis "connexion" (bouton en haut à droite la page d'accueil du site).<br /><br />

        En cas de souci persistant, nous vous invitons à vider votre cache (voir rubrique correspondante sur la page d’aide) ou utiliser un autre navigateur/appareil (pour plus d’informations consultez « problèmes au niveau du paiement » sur la page d’aide).<br />
    </div>
    <div className="help-container__section__title">
      IMPORTANT
    </div>
    <div className="help-container__section__text">
      Facebook bloque très fréquemment les comptes utilisant des pseudonymes originaux. Nous conseillons l’utilisation de prénoms et noms courants si vous souhaitez utiliser un pseudonyme.<br /><br />
      Nous déconseillons vivement la création ou l’utilisation de comptes type « Elo Die », « Ju Lie », « Ka Rine », qui complique fortement l’identification par nos équipes sur notre base de données.<br /><br />
      Avant l’inscription et l’achat, ASSUREZ-VOUS de bien être connecté(e) sur l’espace personnel avec le compte facebook que vous souhaitez utiliser, <b>il ne sera pas possible de le changer par la suite</b> (voir « comment changer de compte facebook » sur la page d’aide au besoin).
    </div>
  </div>
)

export const HelpMail = () => (
  <div className="help-container__section">
    <div className="help-container__section__title">
        Vous n’avez pas reçu le mail et ce dernier n’est pas dans votre dossier indésirables/spams ?
    </div>
    <div className="help-container__section__text">
        GMAIL : vérifiez vos dossiers « Promotions » et « Réseaux », ces derniers sont accessibles sous forme d’onglets.
    </div>
    <div className="help-container__section__text">
      <u>Sur ordinateur:</u>
    </div>
    <div className="help-container__section__text">
      <div className="help-container__section__text__captures">
        <div className="help-container__section__text__captures__capture">
          <img src={capture6} />
        </div>
      </div>
    </div>
    <div className="help-container__section__text">
          Si vous n’avez pas les onglets, vous avez sans doute modifié les paramètres d’apparence, et les dossiers seront accessibles directement depuis le menu à gauche (cliquer sur « plus », puis descendez)
    </div>
    <div className="help-container__section__text">
      <u>Sur application Smartphone :</u>
    </div>
    <div className="help-container__section__text">
      <div className="help-container__section__text__captures">
        <div className="help-container__section__text__captures__capture">
          <img src={capture7} />
        </div>
      </div>
    </div>
    <div className="help-container__section__text">
      <b>OUTLOOK</b>: vérifiez votre dossier « Autres », via l’onglet en haut de la boite, à côté de l’onglet « Prioritaire »
    </div>
    <div className="help-container__section__text">
      <u>Sur ordinateur:</u>
    </div>
    <div className="help-container__section__text">
      <div className="help-container__section__text__captures">
        <div className="help-container__section__text__captures__capture">
          <img src={capture8} />
        </div>
      </div>
    </div>
    <div className="help-container__section__text">
      <u>Sur smartphone:</u>
    </div>
    <div className="help-container__section__text">
      <div className="help-container__section__text__captures">
        <div className="help-container__section__text__captures__capture">
          <img src={capture9} />
        </div>
      </div>
    </div>
    <br /><br /><br />
  </div>
)

export const HelpPay = () => (
  <div className="help-container__section">
    <div className="help-container__section__title">
      Votre paiement mouline et n’aboutit pas ? Vous rencontrez une page blanche ?
    </div>
    <div className="help-container__section__text">
        Tout d'abord, assurez-vous <b>de ne pas vous connecter à l'espace personnel via un lien depuis un e-mail.</b> Copiez collez le lien directement dans votre navigateur :<br />
        <a href="https://espace-personnel.myfiitchallenge.com/" rel="noopener noreferrer" target="_blank">https://espace-personnel.myfiitchallenge.com/</a> <br /> <br />
        Vous pouvez également passer par une recherche google "myfiitchallenge", puis "connexion" (bouton en haut à droite la page d'accueil du site).<br /><br />
        En cas de souci persistant, testez un maximum de navigateurs différents.<br /><br />
      <u>Voici certains navigateurs que nous recommandons :</u>
      <div className="help-container__section__text__browsers">
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/fr/f/fb/Google_Chrome_logo2.png" />
          <span>Chrome</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg" />
          <span>Safari</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1200px-Firefox_logo%2C_2019.svg.png" />
          <span>Firefox</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Opera_2015_icon.svg/1200px-Opera_2015_icon.svg.png" />
          <span>Opera</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/fr/2/20/Logo_Microsoft_Edge.png" />
          <span>Microsoft Edge</span>
        </div>
      </div>
    </div>
    <div className="help-container__section__text">
      Nous vous invitons à vider le cache de votre navigateur (voir « vider le cache » sur la page d’aide).<br /><br />
      Vous pouvez également essayer de désinstaller votre navigateur, puis le réinstaller.<br /><br />
      En cas de souci persistant, assurez-vous que les bloqueurs de publicité/pop up intégrés aux navigateurs (ou type adblock) soient bien désactivés.<br /><br />
      Si vous ne parvenez toujours pas à finaliser le paiement, nous vous invitons à essayer depuis un autre appareil (ordinateur, smartphone, tablette), en testant divers navigateurs parmi la liste ci-dessus.
    </div>
  </div>
)

export const HelpPdfs = () => (
  <div className="help-container__section">
    <div className="help-container__section__title">
        Vous ne parvenez pas à visualiser les pdf ?
    </div>
    <div className="help-container__section__text">

        Tout d'abord, assurez-vous <b>de ne pas vous connecter à l'espace personnel via un lien depuis un e-mail.</b> Copiez collez le lien directement dans votre navigateur :<br />
        <a href="https://espace-personnel.myfiitchallenge.com/" rel="noopener noreferrer" target="_blank">https://espace-personnel.myfiitchallenge.com/</a> <br /><br />
        Vous pouvez également passer par une recherche google "myfiitchallenge", puis "connexion" (bouton en haut à droite la page d'accueil du site).<br /><br />
        Accédez à votre espace personnel via votre navigateur préféré. Le pdf doit s’ouvrir automatiquement dans une nouvelle page après un simple appui/clic sur le bouton<br /><br />
        IMPORTANT : Veuillez patienter quelques secondes/minutes en fonction de votre connexion internet, le temps que le fichier se télécharge.<br /><br />
        Si vous ne parvenez pas à visualiser le pdf en suivant les instructions ci-dessus, nous vous conseillons de tester avec d’autres navigateurs, de préférence nouvellement installés.<br /><br />
    </div>
    <div className="help-container__section__text">
      <u>Voici certains navigateurs que nous recommandons :</u>
      <div className="help-container__section__text__browsers">
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/fr/f/fb/Google_Chrome_logo2.png" />
          <span>Chrome</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg" />
          <span>Safari</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1200px-Firefox_logo%2C_2019.svg.png" />
          <span>Firefox</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Opera_2015_icon.svg/1200px-Opera_2015_icon.svg.png" />
          <span>Opera</span>
        </div>
        <div className="help-container__section__text__browsers__browser">
          <img src="https://upload.wikimedia.org/wikipedia/fr/2/20/Logo_Microsoft_Edge.png" />
          <span>Microsoft Edge</span>
        </div>
      </div>
    </div>
    <div className="help-container__section__text">
        Nous vous invitons à vider le cache de votre navigateur (voir « vider le cache » sur la page d’aide).<br /><br />
        Vous pouvez également essayer de désinstaller votre navigateur, puis le réinstaller.<br /><br />
        En cas de souci persistant, assurez-vous que les bloqueurs de publicité/pop up intégrés aux navigateurs (ou type adblock) soient bien désactivés.<br /><br />
        Vous ne parvenez toujours pas à visualiser le pdf ? Votre appareil rencontre peut être un dysfonctionnement et nous vous invitons à essayer avec un autre (ordinateur, Smartphone, tablette), en testant divers navigateurs parmi la liste ci-dessus.<br /><br />
        Sur ordinateur, le clic peut soit ouvrir le pdf dans un nouvel onglet, ou le télécharger dans un dossier par défaut, en fonction du navigateur utilisé.<br /><br />
        Si vous ne parvenez pas à ouvrir le fichier téléchargé, assurez vous que le logiciel <b>Adobe Acrobat Reader</b> soit installé sur votre machine (disponible gratuitement sur internet).
    </div>
  </div>
)

class Help extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.active !== 0) {
      this.goToHelp()
    }
  }

    goToHelp = () => {
      Scroll.scroller.scrollTo('helpElement', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -300
      })
    }

    renderHelpFacebook = () => {
      return (
        <div className="help-container__section">
          <div className="help-container__section__title">
                    Vous ne parvenez pas à changer le compte facebook ?
          </div>
          <div className="help-container__section__text">
                    Veuillez suivre ces étapes (le navigateur utilisé est Google Chrome, recommandé, les étapes restent
                    cependant similaires avec n’importe quel navigateur : safari, opera, edge, etc)
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__puce">
              <div className="help-container__section__text__puce__left">1.</div>
              <div
                className="help-container__section__text__puce__right help-container__section__text__puce__right--link"
              >
                            Déconnectez-vous de l'espace-personnel MyfiitChallenge après avoir pris connaissance de ce
                            tutoriel en cliquant ici: <span onClick={() => this.logoutFb()}>déconnexion</span>
              </div>
            </div>
            <div className="help-container__section__text__puce">
              <div className="help-container__section__text__puce__left">2.</div>
              <div className="help-container__section__text__puce__right">Avec le même navigateur (Chrome dans
                            cette démonstration), rechercher « Facebook », puis ouvrez la page sur votre
                            navigateur. <span>ATTENTION</span>, si cela lance l’application Facebook, il faudra
                            recommencer en cliquant directement sur le lien « Connexion » juste en dessous :
              </div>
            </div>
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__captures">
              <div className="help-container__section__text__captures__capture">
                <img src={capture5} />
              </div>
            </div>
          </div>
          <div className="help-container__section__text">
                    Le lien « connexion » lance également l’application ? Faite un appui long, puis « ouvrir dans un
                    nouvel onglet » :
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__captures">
              <div className="help-container__section__text__captures__capture">
                <img src={capture4} />
              </div>
            </div>
          </div>
          <div className="help-container__section__text__puce">
            <div className="help-container__section__text__puce__left">3.</div>
            <div className="help-container__section__text__puce__right">Déconnectez le mauvais compte : cliquez
                        sur le menu en haut à droite (3 lignes) :
            </div>
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__captures">
              <div className="help-container__section__text__captures__capture">
                <img src={capture3} />
              </div>
            </div>
          </div>
          <div className="help-container__section__text">
                    Cliquez sur « Se déconnecter » tout en bas de la page (scroller) :
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__captures">
              <div className="help-container__section__text__captures__capture">
                <img src={capture2} />
              </div>
            </div>
          </div>
          <div className="help-container__section__text__puce">
            <div className="help-container__section__text__puce__left">4.</div>
            <div className="help-container__section__text__puce__right">Connectez le bon compte. Entrez le
                        numéro de mobile ou l’adresse e-mail liée au compte facebook que vous souhaitez utiliser pour
                        suivre le myfiitchallenge.
            </div>
          </div>
          <div className="help-container__section__text">
            <div className="help-container__section__text__captures">
              <div className="help-container__section__text__captures__capture">
                <img src={capture1} />
              </div>
            </div>
          </div>
          <div className="help-container__section__text">
            <span>IMPORTANT :</span> Cette adresse e-mail n’aura AUCUN LIEN avec l’adresse e-mail que vous
                    souhaitez utiliser sur le myfiitchallenge, il s’agit simplement de l’adresse mail que vous aviez
                    utilisée pour créer le compte facebook. <br /><br />Nous ne l’utilisons pas sur l’espace personnel. Vous pourrez
                    choisir l’adresse e-mail DE VOTRE CHOIX sur notre plateforme.
          </div>
          <div className="help-container__section__text__puce">
            <div className="help-container__section__text__puce__left">5.</div>
            <div className="help-container__section__text__puce__right">Retournez sur l’espace personnel
                        myfiitchallenge, toujours avec le même navigateur, cliquez sur le bouton FACEBOOK « Connexion »,
                        vous devriez maintenant pouvoir vous connecter via le compte connecté à l’étape 4.
            </div>
          </div>
          <br /><br /><br />
        </div>
      )
    }

    logoutFb = () => {
      const { cookies } = this.props
      cookies.remove('mfc-token')
      cookies.remove('facebook-user')
      if (window.FB) {
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            window.FB.logout((resp) => {
              localStorage.clear()
              const cookies = document.cookie.split(';')

              for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i]
                const eqPos = cookie.indexOf('=')
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
              }
              window.location.href = '/'
            })
          } else {
            window.location.href = '/'
          }
        })
      } else {
        window.location.href = '/'
      }
    }

    render() {
      const { active } = this.state
      return (
        <AppContainer>
          <Header icon="power-off" navigationRight="/my-fiit-challenges" help isBackground />
          <div className="help-container">
            <div className="help-container--hide">
              <Link to="/my-fiit-challenges" className="help-container__home">Accueil</Link>
              <div className="help-container__title">Section AIDE</div>
              <div className="help-container__question">Vous rencontrez des problèmes d'utilisation ?</div>
              <div className="help-container__question">Regardez notre section de tutoriels</div>
            </div>


            <div className="help-container__boxes">
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 1 ? 0 : 1 })}>
                <div className="help-container__boxes__box__question">
                Vous avez des questions sur la connexion avec Facebook?
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                  Voir la solution
                  </div>
                </div>
              </div>
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 3 ? 0 : 3 })}>
                <div className="help-container__boxes__box__question">
                        Vous n'êtes pas connecté avec le bon profil facebook? Comment changer de compte?
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                            Voir la solution
                  </div>
                </div>
              </div>
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 2 ? 0 : 2 })}>
                <div className="help-container__boxes__box__question">
                Vous rencontrez des problèmes au niveau du paiement d'un programme ?
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                  Voir la solution
                  </div>
                </div>
              </div>
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 4 ? 0 : 4 })}>
                <div className="help-container__boxes__box__question">
                Vous n'avez pas reçu le mail et celui-ci n'est pas dans vos indésirables / spams ?
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                  Voir la solution
                  </div>
                </div>
              </div>
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 5 ? 0 : 5 })}>
                <div className="help-container__boxes__box__question">
                Vous n'arrivez pas à télécharger / consulter les fichiers PDF? (pack démarrage / séances)
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                  Voir la solution
                  </div>
                </div>
              </div>
              <div className="help-container__boxes__box" onClick={() => this.setState({ active: active === 6 ? 0 : 6 })}>
                <div className="help-container__boxes__box__question">
                Vous rencontrez un dysfonctionnement avec le site? Vider le cache.
                </div>
                <div className="help-container__boxes__box__action">
                  <div className="help-container__boxes__box__action__button">
                  Voir la solution
                  </div>
                </div>
              </div>

            </div>
            {
            active !== 0 && (
              <>
                <Element name="helpElement">
                  <div className="help-container__content">
                    { active === 1 && <HelpConnexion /> }
                    { active === 2 && <HelpPay /> }
                    { active === 3 && this.renderHelpFacebook() }
                    { active === 4 && <HelpMail /> }
                    { active === 5 && <HelpPdfs /> }
                    { active === 6 && <HelpCache /> }
                  </div>
                </Element>
                  <div className="help-container__back-to-top" onClick={() => scroll.scrollToTop()}>
                      <FontAwesomeIcon icon={faArrowUp} />
                  </div>
              </>
            )
          }
              <div style={{marginTop: 30}}>
                  consultez notre politique de confidentialité <Link to="/politique-de-confidentialite">Ici</Link>
              </div>
              <div style={{marginTop: 30}}>
                  consultez nos conditions de services <Link to="/conditions-de-service">Ici</Link>
              </div>
              <div style={{marginTop: 30}}>
                  Vous ne trouvez pas votre question, consultez la FAQ <a href="https://www.myfiitchallenge.com/faq" target={"_blank"}>Ici</a>
              </div>
          </div>
        </AppContainer>
      )
    }
}

export default withCookies(Help)
