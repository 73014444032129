import React, { Component } from 'react'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner)

class Modale extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {}

  render() {
    const { message, noMargin } = this.props
    return (
      <div className={`loader-box ${noMargin ? 'loader-box--no-margin' : ''}`}>
        <div className="loader-box__icon">
          <FontAwesomeIcon icon={"spinner"} pulse />
        </div>
        <div className="loader-box__message">
          { message }
        </div>
      </div>
    )
  }
}

export default Modale
