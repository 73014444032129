const defaultState = {
  paiementSuccess: false,
  paiementError: false,
  paiementLoader: false
}

export default function Purchases(state = defaultState, action) {
  switch (action.type) {
    case 'PURCHASE_MFC': {
      return {
        ...state,
        paiementSuccess: false,
        paiementError: false,
        paiementLoader: true,
      }
    }
    case 'PURCHASE_MFC_SUCCESS': {
      return {
        ...state,
        paiementSuccess: true,
        paiementError: false, //action.data || false,
        paiementLoader: false,
      }
    }
    case 'PURCHASE_MFC_ERROR': {
      console.log('purchase Error', action)
      return {
        ...state,
        paiementSuccess: false,
        paiementError: action && action.data && action.data.data && action.data.data.code,
        paiementLoader: false,
      }
    }
    case 'PURCHASE_MFC_VOUCHER': {
      return {
        ...state,
        paiementSuccess: false,
        paiementError: false,
        paiementLoader: true,
      }
    }
    case 'PURCHASE_MFC_VOUCHER_SUCCESS': {
      return {
        ...state,
        paiementSuccess: action.data,
        paiementError: false,
        paiementLoader: false,
      }
    }
    case 'PURCHASE_MFC_VOUCHER_ERROR': {

      return {
        ...state,
        paiementSuccess: false,
        paiementError: (action.data.response && action.data.response.data && action.data.response.data.message) || true,
        paiementLoader: false,
      }
    }
    default: return state
  }
}
