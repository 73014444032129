const defaultState = {
  isUnlocked: false
}

export default function Facebook(state = defaultState, action) {
  switch (action.type) {
    case 'UNLOCK_VIDEO': {
      return {
        ...state
      }
    }
    case 'UNLOCK_VIDEO_SUCCESS': {
      return {
        ...state,
        isUnlocked: true
      }
    }
    case 'UNLOCK_VIDEO_ERROR': {
      return {
        ...state,
        isUnlocked: false
      }
    }
    case 'DOWNLOAD_PDF': {
      return {
        ...state
      }
    }
    case 'DOWNLOAD_PDF_SUCCESS': {
      return {
        ...state
      }
    }
    case 'DOWNLOAD_PDF_ERROR': {
      return {
        ...state
      }
    }
    case 'RESET_UNLOCK': {
      return {
        ...state,
        isUnlocked: false,
      }
    }

    default: return state
  }
}
