const defaultState = {
  availablePdfs: null
}

export default function AvailablePdfs(state = defaultState, action) {
  switch (action.type) {
    case 'GET_AVAILABLES_PDFS': {
      return {
        ...state
      }
    }
    case 'GET_AVAILABLES_PDFS_SUCCESS': {
      return {
        ...state,
        availablePdfs: action.availablePdfs.data
      }
    }
    case 'GET_AVAILABLES_PDFS_FAIL': {
      return {
        ...state,
        availablePdfs: null
      }
    }

    default: return state
  }
}
