import React, { Component } from 'react'
import './stylesheet.scss'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import * as pack from '../../image/mppack.png'

library.add(faArrowCircleRight)

class Complements extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: null
    }
  }

  componentWillMount() {}

  render() {
      const { name } = this.props
    return (
      <Link to={`/${name}/complements`} className="complements">
        <div className="complements__left">
          <img src={pack} alt="pack" />
        </div>
        <div className="complements__center">
          Compléments alimentaires
        </div>
        <div className="complements__right">
          <FontAwesomeIcon icon="arrow-right" className="" />
        </div>
      </Link>
    )
  }
}

export default Complements
