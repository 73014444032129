import React, { Component } from 'react'
import '../complements/stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'

library.add(faArrowCircleRight)

class FiitFightForever extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/" goBackNav goBackLabel="Acceuil" isBackground isRemove />
        {/* <Breadcrumb title="Compléments alimentaires" /> */ }
        <div className="mfc mfc--no-padding mfc--live mfc--presentation mfc--presentation--small">
          <div className="mfc__left">
            <div className="mfc__left__content">
              <div style={{ marginTop: 15 }}>
                <a className="complements-part__head__back" href="/">Retour</a>
              </div>
              <div className="mfc__left__content__global" style={{padding: 30}}>
                <h1>Politique de confidentialité FIITFIGHTFOREVER</h1><br/><br/>
                <b>
                  <div style={{fontSize: 24}}>
                    🇫🇷Suppression de compte et données personnelles / 🇺🇸Delete account and data
                  </div>
                  <br/>
                  <br/>
                  🇫🇷: L'utilisateur a la possibilité de supprimer son compte à tout moment via le bouton "supprimer mon
                  compte" en haut de la page d'accueil de son espace personnel. La suppression de compte entraine la
                  suppression de toutes les données personnelle et est définitive.
                  Il est également possible de solliciter la suppression auprès de support@myfiitchallenge.com (cf
                  article 6)
                  <br/><br/>
                  🇺🇸: Users can permanently and completely delete their account and data via the dedicated button
                  "supprimer mon compte" at the top of their homepage.
                  Alternatively they can ask our support team at support@myfiitchallenge.com (article 6)
                </b>
                <br/>Charte de protection des données :
                <br/><br/>Article 1 : Préambule
                <br/><br/>Les utilisateurs de l’application, personnes physiques, sont invités à lire attentivement la
                présente Charte avant de communiquer toute donnée à caractère personnel à la société JUSTGAL.
                <br/><br/>Article 2 : Généralités
                <br/><br/>La présente charte a pour objet d’informer les utilisateurs des modalités de collecte, de
                traitement, et d’utilisation de leurs données personnelles et de leurs droits en matière de protection
                des données à caractère personnel au regard des dispositions applicables en la matière, notamment la loi
                78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés dite « Informatique et
                libertés »et au règlement européen sur la protection des données personnelles 2016/679.
                <br/>En recourant aux services proposés par l’application, les utilisateurs peuvent être amenés à
                communiquer des données à caractère personnel telles que leurs noms, prénoms, adresses électroniques,
                adresses postales, numéros de téléphone, âge, sexe, date de naissance, mensurations physiques notamment.
                <br/>En transmettant ces données, les utilisateurs autorisent expressément les sociétés à collecter,
                traiter et sauvegarder celles-ci conformément aux stipulations de la présente Charte.
                <br/><br/>Article 3 : Le responsable du traitement des données personnelles
                <br/><br/>Les données personnelles des utilisateurs sont collectées, sauvegardées et traitées par la
                société JUSGTAL, dont le siège social est situé 33 QUAI ARLOING 69009 LYON
                <br/>Le délégué à la protection des données est Mme Justine GALLICE
                <br/>L’utilisateur a la faculté de s’adresser à elle par écrit à cette adresse mail :
                support@fiitfightforever.com
                <br/>(L'hébergement du site est assuré par la société OVH, immatriculée au Registre du Commerce et des
                Sociétés de Roubaix – Tourcoing sous le numéro 424 761 419, ayant son siège social sis 2 rue Kellermann
                59100 Roubaix - France).
                <br/><br/>Article 4 : Finalité des données personnelles recueillies par JUSTGAL
                <br/><br/>Les données à caractère personnel communiquées à JUSTGAL sont collectées, stockées et traitées
                aux fins de :
                <br/>-identifier les utilisateurs du site, via la création de leur compte,
                <br/>-communiquer la newsletter aux utilisateurs inscrits,
                <br/>-communiquer des alertes personnalisées aux utilisateurs inscrits qui en ont fait la demande,
                <br/>-permettre la gestion et le suivi des relations avec les titulaires de comptes,
                <br/>JUSTGAL peut également utiliser ces données pour améliorer la qualité de ses services, pour
                réaliser des études de marché, ou à des fins statistiques notamment en conservant les données des
                annonces postées pour créer son index de référence.
                <br/><br/>Les données sont traitées par les services internes de la société uniquement, soit les
                services clients, webmarketing, comptable, et les éventuels sous-traitants à ces fins.
                <br/>Par ailleurs, les données pourront être communiquées à toute autorité légalement habilitée à en
                connaître, en particulier en cas de réquisition judiciaire des autorités judiciaires, policières ou
                administratives.
                <br/>JUSTGAL s’engage à collecter uniquement les informations à caractère personnel nécessaires à la
                réalisation des finalités susvisées, de façon proportionnée et limitée.
                <br/>En aucun cas ces données sont stockées et utilisées à des fins commerciales, et communiquées à des
                tiers.
                <br/><br/>Article 5 : Fondement juridique du traitement des données
                <br/><br/>Le traitement des données personnelles est effectué sur les bases suivantes :
                <br/> Le consentement exprès (par l’acceptation expresse de cette présente charte lors de l’abonnement)
                <br/>L’exécution du contrat entre les sociétés et l’utilisateur.
                <br/><br/>Article 6 : Droits des utilisateurs du site fiitfightforever.com
                <br/><br/>Tout utilisateur dont les données à caractère personnel ont été collectées par JUSTGAL,
                dispose des droits suivants :
                <br/>Droit à l’information : l’utilisateur est informé par JUSTGAL dès que le traitement de ses données
                à caractère personnel est modifié (stockage, finalités, etc.).
                <br/>Droit d'accès, de rectification et de suppression de ses données : Tout utilisateur a la faculté de
                demander l’accès à l’ensemble de ses données collectés (contenu et finalités notamment), d'en demander
                la rectification (via son compte notamment depuis la rubrique profil), ou la suppression intégrale
                (étant précisé que toute demande de suppression définitive de l’ensembles des informations le concernant
                entraîne la suppression de son compte et corrélativement la cessation des services d’alertes et de
                newsletters JUSTGAL).
                <br/>Droit de s'opposer au traitement de ses données : En souscrivant aux services de publication
                d’annonces, d’alertes, d’annuaire et de newsletters JUSTGAL, l’utilisateur accepte que ses données
                soient stockées et traitées conformément aux finalités de l’article 4 des présentes. L'utilisateur est
                libre de retirer son consentement à tout moment et de solliciter la suppression définitive de ces
                données via le formulaire en bas de chaque e-mail.
                <br/>Droit à la portabilité des ses données : Les données à caractère personnel fournies par
                l’utilisateur sont conservées dans un format structuré, couramment utilisé et lisible par toute machine
                pour permettre à l’utilisateur d’obtenir que ses données à caractère personnel soient transmises
                directement du responsable du traitement JUSTGAL, à un autre responsable tiers, lorsque cela est
                techniquement possible, à sa demande écrite.
                <br/>Temps de conservation de ses données : Les données personnelles sont conservées pendant la durée de
                l’utilisation des services par l’utilisateur, jusqu’à la suppression de son compte par exemple, ou à sa
                désinscription à la newsletter et aux alertes. La durée de conservation des données par JUSTGAL n’excède
                en aucun cas la durée nécessaire aux finalités visées à l’article 4 des présentes.
                <br/>Toute demande devra être adressée par e-mail au responsable du traitement visé à l’article 3 des
                présentes, à l'adresse support@fiitfightforever.com
                <br/><br/>Article 7 : Utilisation des données à caractère personnel par des Services tiers (Facebook,
                Twitter…)
                <br/><br/>L'utilisateur est libre d'établir une connexion entre son compte JUSTGAL et des services tiers
                (tel que Facebook, Twitter ou Instagram, etc.) en vue de faciliter le partage d'informations et
                d'identifier et communiquer avec d'autres personnes utilisant le site et les services JUSTGAL. JUSTGAL
                n'est pas responsable du traitement des données qui peut être effectué par les services tiers.
                <br/><br/>Article 8 : Carte de crédit
                <br/><br/>Lorsqu'un utilisateur communique le numéro de sa carte de crédit pour payer des services sur
                JUSTGAL, JUSTGAL utilise le système de paiement en ligne sécurisé STRIPE avec cryptage, qui répond à des
                standards de sécurité contre tout accès, utilisation ou divulgation non autorisée. Les numéros de cartes
                de crédit sont utilisés uniquement dans le cadre des paiements en ligne et ne sont en aucun cas
                conservés à d'autres fins, ni au-delà. JUSTGAL n’est en toute hypothèse pas responsable du traitement
                des données effectué par STRIPE.
                <br/><br/>Article 9 : L'utilisation des Cookies
                <br/><br/>Les cookies sont des éléments d'information qu'un site web donné installe sur l'ordinateur de
                l’utilisateur de celui-ci, dans l'intention de conserver cette information lors d'une nouvelle
                consultation du visiteur sur ce même site.
                <br/>Les cookies temporaires ne sont stockés dans le fichier cookies du navigateur que jusqu'à la fin de
                la visite sur le site Internet. Ce type de cookies est incontournable pour certaines applications et
                fonctions sur le site Internet, qui ne peuvent fonctionner sans eux.
                <br/>Les cookies permanents sont utilisés pour personnaliser la visite sur le site (par exemple en
                stockant le code postal qui a été entré). Ces cookies restent stockés dans le fichier cookie du
                navigateur, en fonction des paramètres de l'utilisateur au-delà de la visite du site par l’utilisateur.
                Les cookies peuvent être supprimés par l'utilisateur en adaptant les paramètres de son navigateur. Pour
                toute information complémentaire, l'utilisateur peut s’adresser au responsable du traitement des données
                visé à l’article 3.
                <br/><br/>Article 10 : Sécurité des données
                <br/><br/>JUSTGAL prend toutes les précautions utiles qu’elles soient physiques, administratives ou
                organisationnelles, au regard de la nature des données qu’elle traite et des risques présentés par les
                différents traitements, pour préserver la sécurité des données et, empêcher qu’elles soient déformées,
                endommagées ou que des tiers non autorisés y aient accès. En cas de sous-traitance d’une partie ou de la
                totalité d’un traitement de données à caractère personnel, JUSTGAL impose contractuellement à ses
                sous-traitants des garanties de sécurité et de confidentialité des données à caractère personnel par le
                biais de mesures techniques de protection de ces données et les moyens humains appropriés.
                <br/><br/>Article 11 : suppression de compte
                <br/><br/>L'utilisateur a la possibilité de supprimer son compte à tout moment via le bouton prévu à cet
                effet en haut de la page d'accueil de son espace personnel. La suppression de compte est définitive.
                <br/><br/>Article 12 : Mise à jour de la politique de protection des données
                <br/><br/>La politique de confidentialité est susceptible d’être modifiée ou aménagée à tout moment.
                <br/>Vous êtes invités à la consulter régulièrement.
                <br/>Dernière modification : le 30/09/2021
                <br/>Droit applicable :
                <br/>Le présent site et ses mentions légales sont soumis au droit français.
                <br/>SOUS TOUTES RÉSERVES
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    )
  }
}

export default withRouter(FiitFightForever)
