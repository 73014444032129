import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  List, Button, Input, Select, Col
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSync, faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import LayoutContentWrapper from '../../../../components/utility/layoutWrapper'
import './stylesheet.scss'
import {
  findPromocode, generateCode, getMfcs, switchCodeStatus
} from '../../../../redux/admin/action'

const code = {
  _id: '5ca388e1dfd8567553169a56',
  type: 'duo',
  boughtBy: 'Aymeric Stark',
  code: 'QAUWRGSP',
  mfc: '5c81847b1d87ff629cc6ec95',
  creationDate: '2019-04-02T16:08:01.190Z',
  __v: 0,
  consumedAt: '2019-04-02T16:25:44.651Z',
  consumedBy: '5c869c70a84450a4e47bc6fd'
}

const Option = Select.Option
library.add(faSync, faCheckCircle, faTimesCircle)

class Promocodes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      inputcode: '',
      loading: false,
      selectedMfc: null,
    }
  }

  componentDidMount() {
    const { getMfcs } = this.props
    getMfcs()
  }

  findCode = (code) => {
    const { findPromocode } = this.props
    this.setState({ loading: true, isSearch: true })
    findPromocode(code)
  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.promocodes) {
      this.setState({ isSearch: false, loading: false })
    }
    this.setState({ loading: false })
  }

  setCode = (e) => {
    this.setState({ inputcode: e.target.value })
  }

  generateCodeDuo = () => {
    const { selectedMfc } = this.state
    const { generateCode } = this.props
    if (!selectedMfc) {
      alert('Veuillez selectionner un Challenge')
    } else {
      generateCode(selectedMfc._id)
    }
  }


  handleChangeMfc = (mfc) => {
    this.setState({ selectedMfc: mfc })
  }

  switchActivation = (code, status) => {
    const { switchCodeStatus } = this.props
    switchCodeStatus(code, JSON.stringify(status))
  }

  render() {
    const {
      promocodes, generatedCode, mfcs, editedCode
    } = this.props
    const {
      isSearch, inputcode, loading, selectedMfc
    } = this.state
    // QAUWRGSP

    return (
      <LayoutContentWrapper>
        <div className="promocode">
          <h1>Vérification de code</h1>
          <div className="promocode__left">
            <div className="promocode__left__input redify">
              <Input type="text" value={inputcode} onChange={e => this.setCode(e)} placeholder="Code à vérifier" />
            </div>
            <div className="promocode__left__button">
              <Button type="primary" onClick={() => this.findCode(inputcode)}>{ loading && <FontAwesomeIcon style={{ marginRight: 15 }} icon={faSync} />} Chercher code</Button>
            </div>
          </div>
          {
            promocodes ? (
              <List
                className="promocode__container"
                size="medium"
                header={<div className=""> <b>{ promocodes.code }</b> {(promocodes.consumedAt || promocodes.invalidated) ? <span className="redify"><b>{ promocodes.invalidated ? '[SUPPRIMÉ PAR ADMIN]' : '[UTILISÉ]'}</b></span> : <span className="greenify"><b>[LIBRE]</b></span>}</div>}
                bordered
              >
                <List.Item><b>Acheté par:</b> { promocodes.boughtBy }</List.Item>
                <List.Item><b>Type:</b> { promocodes.type }</List.Item>
                <List.Item><b>Code:</b> { promocodes.code }</List.Item>
                {
                  promocodes.consumedBy
                    ? (
                      <React.Fragment>
                        <List.Item><b>Crée le:</b> { moment(promocodes.creationDate).lang('fr').format('LLLL') }</List.Item>
                        <List.Item><b>Utilisé le:</b> { moment(promocodes.consumedAt).lang('fr').format('LLLL') } par <b /><b>{promocodes.consumedBy}</b></List.Item>
                      </React.Fragment>
                    )
                    : (
                      <List.Item><b>{ promocodes.invalidated ? 'Code partenaire invalidé par admin' : 'Code partenaire non utilisé'}</b></List.Item>
                    )
                }
                <List.Item>
                  <Button className={!promocodes.consumedBy && !promocodes.invalidated ? 'button--red' : 'button--green'}
                          onClick={() => this.switchActivation(promocodes.code, (!promocodes.consumedBy && !promocodes.invalidated))}
                  >
                    { <FontAwesomeIcon style={{ marginRight: 15 }} icon={(!promocodes.consumedBy && !promocodes.invalidated) ? faTimesCircle : faCheckCircle} />}
                    {(!promocodes.consumedBy && !promocodes.invalidated) ? 'Supprimer' : 'Reactiver'}</Button>
                </List.Item>
              </List>
            )
              : (
                <div>
                  { isSearch && 'Aucun résultat'}
                </div>
              )
          }

          <h1>Génération de code</h1>
          <div className="promocode__generate">
            <Select defaultValue="Tous challenges" value={(selectedMfc && selectedMfc.name) || 'Challenge'} style={{ marginRight: 15 }}>
              {
                mfcs && mfcs.map((k, i) => (
                  <Option key={`mfcs-${i}`} value={k._id} onClick={() => this.handleChangeMfc(k)}>{k.name}</Option>
                ))
              }
            </Select>
            <Button type="primary" onClick={() => this.generateCodeDuo()}>{ generatedCode && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 5 }} />} { generatedCode ? 'Code partenaire généré' : 'Générer un code partenaire'} </Button>
            {generatedCode && (
              <div className="promocode__generate__result">
                {generatedCode.code}
              </div>
            )
            }
          </div>
        </div>
      </LayoutContentWrapper>
    )
  }
}

const mapStateToProps = state => ({
  promocodes: state.Admin.promocodes,
  generatedCode: state.Admin.generatedCode,
  editedCode: state.Admin.editedCode,
})


export default connect(mapStateToProps, {
  findPromocode, generateCode, getMfcs, switchCodeStatus
})(Promocodes)
