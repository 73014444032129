import axios from 'axios'
import moment from 'moment'
import { uri } from '../../uri'
import { dispatchRequest } from '../helpers'
import { getToken } from '../token'

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export const findUser = (user) => {
  return (dispatch) => {
    const params = {}

    if (user.dates && user.dates.startDate) {
      const startDate = moment(user.dates.startDate)
        .utc()
        .add(user.dates.startDate.utcOffset(), 'm')

      params.startDate = startDate.toISOString()
    }

    if (user.dates && user.dates.endDate) {
      const endDate = moment(user.dates.endDate)
        .utc()
        .add(user.dates.endDate.utcOffset(), 'm')

      params.endDate = endDate.toISOString()
    }

    params.name = user.name || null
    params.page = user.page ? user.page - 1 : '0'
    params.mfc = user.mfc || null
    if (!user.isExport) {
      params.limit = 30
    }

    let query = ''
    Object.entries(params).map(([key, value]) => {
      if (value) {
        query += `${query === '' ? '?' : '&'}${key}=${value}`
      }
    })

    query += `&token=${getToken('mfc-token')}`

    if (user.isExport) {
      query += '&exportsCSV=true'
      const filePath = `${process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:5151'}${uri.uri.admin.users}${query}`

      const link = document.createElement('a')
      link.href = filePath
      link.setAttribute('target', '_blank')

      const date = new Date()
      const dateBackup = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(-2)}-${date.getFullYear()}`

      if (link.download !== undefined) {
        // Set HTML5 download attribute. This will prevent file from opening if supported.
        const fileName = `export-mfc-${dateBackup}.csv`
        link.download = fileName
      }

      // Dispatching click event.
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents')
        e.initEvent('click', true, true)
        link.dispatchEvent(e)
        return true
      }
    } else {
      dispatch({
        type: 'FIND_USERS',
        queryParam: user
      })

      axios.get(`${uri.uri.admin.users}${query}`, getConfig()).then((response) => {
        dispatch({
          type: 'FIND_USERS_SUCCESS',
          queryParam: user,
          data: response
        })
      }).catch((error) => {
        dispatch({
          type: 'FIND_USERS_ERROR',
          data: null
        })
      })
    }
  }
}

export const editMail = (id, email) => dispatch => dispatchRequest('EDIT_USER', axios.post(`${uri.uri.admin.editUser}/${id}`, { email }, getConfig()), dispatch, 'successUserUpdate')
export const editName = (id, firstName, lastName) => dispatch => dispatchRequest('EDIT_USER', axios.post(`${uri.uri.admin.editUser}/${id}`, { firstName, lastName }, getConfig()), dispatch, 'successUserUpdate')
export const deleteAccount = (id) => dispatch => dispatchRequest('EDIT_USER', axios.post(`${uri.uri.admin.deleteAccount}`, { id }, getConfig()), dispatch, 'successUserUpdate')
export const giveAccess = (id, mfc) => dispatch => dispatchRequest('GIVE_ACCESS', axios.post(`${uri.uri.admin.giveAccess}/${id}`, { mfcId: mfc._id }, getConfig()), dispatch, 'successUserUpdate')
export const editUser = (id, status) => dispatch => dispatchRequest('EDIT_USER', axios.post(`${uri.uri.admin.editUser}/${id}`, { disabled: status }, getConfig()), dispatch, 'successUserUpdate')

export const findPromocode = code => dispatch => dispatchRequest('FIND_POMOCODE', axios.get(`${uri.uri.admin.promocode}/${code}`, getConfig()), dispatch)
export const generateCode = mfc => dispatch => dispatchRequest('CREATE_POMOCODE', axios.put(`${uri.uri.admin.promocode}`, { mfc }, getConfig()), dispatch)
export const getUser = id => dispatch => dispatchRequest('GET_USER', axios.get(`${uri.uri.admin.user}/${id}`, getConfig()), dispatch)
// export const getUsers = page => dispatch => dispatchRequest('GET_USERS', axios.get(`${uri.uri.admin.users}/${query}`, getConfig()), dispatch)
export const getPdfs = () => dispatch => dispatchRequest('GET_PDFS', axios.get(`${uri.uri.admin.pdf}`, getConfig()), dispatch)
export const getPdfsById = id => dispatch => dispatchRequest('GET_MFC_PDFS', axios.post(`${uri.uri.pdf.getList}`, { id }, getConfig()), dispatch)
export const getMfcs = () => dispatch => dispatchRequest('GET_MFCS', axios.get(`${uri.uri.admin.mfcs}`, getConfig()), dispatch)
export const getMfc = mfc => dispatch => dispatchRequest('GET_MFC', axios.get(`${uri.uri.admin.mfcs}/${mfc}`, getConfig()), dispatch)
export const getUserInfo = user => dispatch => dispatchRequest('GET_USER_INFO', axios.get(`${uri.uri.admin.user}/${user}`, getConfig()), dispatch)

export const switchCodeStatus = (code, status) => dispatch => dispatchRequest('SWITCH_CODE', axios({
  url: `${uri.uri.admin.promocode}/${code}`,
  method: status === 'true' ? 'DELETE' : 'POST',
  ...getConfig()
}), dispatch, 'editedCode')


export const getMfcByName = mfc => dispatch => dispatchRequest('GET_MFC', axios.get(`${uri.uri.admin.mfcByName}/${mfc}`, getConfig()), dispatch)
export const createPrice = params => dispatch => dispatchRequest('CREATE_PRICE', axios.put(`${uri.uri.admin.mfcs}`, params, getConfig()), dispatch)
export const deletePrice = params => dispatch => dispatchRequest('DELETE_PRICE', axios.delete(`${uri.uri.admin.mfcs}`, { data: { _id: params._id } }, getConfig()), dispatch, 'deleted')
export const editPrice = params => dispatch => dispatchRequest('EDIT_PRICE', axios.post(`${uri.uri.admin.mfcs}/${params._id}`, { data: params }, getConfig()), dispatch, 'edited')
export const deletePdf = pdf => dispatch => dispatchRequest('DELETE_PDF', axios.delete(`${uri.uri.admin.pdf}/${pdf}`, getConfig()), dispatch)
export const saveMfc = (id, mfc) => dispatch => dispatchRequest('SAVE_MFC', axios.post(`${uri.uri.admin.mfcs}/${id}`, { ...mfc }, getConfig()), dispatch, 'edited')
export const saveMfcYoutube = (id, youtubeData) => dispatch => dispatchRequest('SAVE_MFC_YOUTUBE', axios.post(`${uri.uri.admin.mfcsYoutube}/${id}`, { youtubeData }, getConfig()), dispatch, 'edited')

export const sendPdf = (id, params) => (dispatch) => {
  const data = new FormData()

  data.append('mfcId', id)
  data.append('type', params.type)
  data.append('publicationDate', params.publicationDate)
  data.append('session', params.session)
  data.append('week', params.week)
  data.append('mfcName', params.mfcName)
  data.append('file', new Blob([params.file], { type: 'multipart/form-data' }))

  dispatch({
    type: 'SEND_PDF'
  })

  axios.post(`${uri.uri.admin.pdf}`, data, getConfig()).then((response) => {
    dispatch({
      type: 'SEND_PDF_SUCCESS',
      data: response
    })
  }).catch((error) => {
    dispatch({
      type: 'SEND_PDF_ERROR',
      data: null
    })
  })
}

export const sendYoutube = (id, params) => (dispatch) => {
  const data = new FormData()

  data.append('mfcId', id)
  data.append('type', params.type)
  data.append('publicationDate', params.publicationDate)
  data.append('session', params.session)
  data.append('week', params.week)
  data.append('mfcName', params.mfcName)

  dispatch({
    type: 'SEND_YOUTUBE'
  })

  axios.post(`${uri.uri.admin.youtube}`, data, getConfig()).then((response) => {
    dispatch({
      type: 'SEND_YOUTUBE_SUCCESS',
      data: response
    })
  }).catch((error) => {
    dispatch({
      type: 'SEND_YOUTUBE_ERROR',
      data: null
    })
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: 'RESET'
  })
}

export const setUserPage = page => (dispatch) => {
  dispatch({
    type: 'SET_USER_PAGE',
    page: page - 1
  })
}
