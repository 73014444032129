import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Layout, LocaleProvider } from 'antd'
import { Debounce } from 'react-throttle'
import WindowResizeListener from 'react-window-size-listener'
import { saveMfc } from '../../../redux/admin/action'
import appActions from '../../../redux/app/actions'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'
import AppRouter from './AppRouter'
import AppHolder from './commonStyle'
import './global.css'

const { Content, Footer } = Layout
const { toggleAll } = appActions

export class App extends Component {
  render() {
    const { url } = this.props.match
    const { saveMfc } = this.props

    return (
      <AppHolder>
        <Layout style={{ height: '100vh' }}>
          <Debounce time="1000" handler="onResize">
            <WindowResizeListener
              onResize={windowSize => this.props.toggleAll(
                windowSize.windowWidth,
                windowSize.windowHeight
              )}
            />
          </Debounce>
          <Topbar url={url} />
          <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
            <Sidebar url={url} />
            <Layout
              className="isoContentMainLayout"
              style={{
                height: '100vh',
              }}
            >
              <Content
                style={{
                  padding: '70px 0 0',
                  flexShrink: '0',
                  background: '#f1f3f6',
                }}
              >
                <AppRouter url={url} saveMfc={saveMfc} />
              </Content>
              <Footer
                style={{
                  background: '#ffffff',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderTop: '1px solid #ededed',
                }}
              >
                MFC Amazing and Wonderfull Admin @2021
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    )
  }
}

export default connect(
  state => ({}),
  { toggleAll, saveMfc }
)(withRouter(App))
