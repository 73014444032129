import React, { Component } from 'react'
import './stylesheet.scss'
import '../my-fiit-challenge-selection/stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from '../../components/stripe/checkoutForm'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    const { getMfc, refreshToken, account } = this.props
    const { id } = this.props.match.params
    getMfc(id)
    if (!account) {
      refreshToken()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      purchaseMfc, paiementLoader, paiementSuccess, paiementError, match, account
    } = this.props
    const { mfc } = this.props
    const isDuo = match.params.duo && match.params.duo === 'double'

    return (
      <AppContainer>
        {
          mfc
          && (
          <React.Fragment>
            <Header icon="power-off" navigationRight="/my-fiit-challenges" isBackground account={account} isBack />
            { /* <Breadcrumb title={`ACHAT ${mfc.name} ${!mfc.isReplay ? (isDuo ? 'EN DUO' : 'EN SOLO') : ''}`} /> */ }
            <div className="mfc mfc--presentation mfc--no-padding">
              <div className="mfc__left">
                <div className="mfc__left__content">
                  <div className="payment">
                    <StripeProvider
                      apiKey={(window.location.hostname === 'localhost' || window.location.hostname === 'johnspartan.myfiitchallenge.com') ? 'pk_test_9CqIHFYwHXpg1FT7b5CCONfK' : 'pk_live_uIgig3TWvEPa2NHIExwWc3G3'}
                      stripe={this.props.stripe}
                    >
                      <Elements>
                        <CheckoutForm
                          purchaseMfc={purchaseMfc}
                          paiementLoader={paiementLoader}
                          paiementSuccess={paiementSuccess}
                          paiementError={paiementError}
                          isDuo={isDuo}
                          name={mfc.name}
                          mfc={mfc}
                          price={isDuo ? mfc.duoPrice : mfc.price}
                          isReplay={mfc.isReplay}
                          hasUpsell={mfc.hasUpsell}
                        />
                      </Elements>
                    </StripeProvider>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          )
        }
      </AppContainer>
    )
  }
}

export default withRouter(Payment)
