import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import appActions from '../../../redux/app/actions'
import TopbarWrapper from './topbar.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'
const { Header } = Layout
const { toggleCollapsed } = appActions

library.add(faHome)
class Topbar extends Component {
  render() {
    const {
      toggleCollapsed
    } = this.props
    const collapsed = this.props.collapsed && !this.props.openDrawer
    const styling = {
      position: 'fixed',
      width: '100%',
      height: 70,
    }
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <div onClick={toggleCollapsed} style={{ color: '#ed5d4f', fontSize: 20 }}>
              <FontAwesomeIcon icon={faHome} />
            </div>
          </div>

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    )
  }
}

export default connect(
  state => ({
    ...state.App.toJS(),
  }),
  { toggleCollapsed }
)(Topbar)
