import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { CardElement, Elements, injectStripe } from 'react-stripe-elements'
import axios from 'axios'
import {
  Form, Icon, Input, Button, Modal
} from 'antd'
import '../../pages/purchase/stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlusCircle, faCheck, faInfoCircle, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import ReactPixel from 'react-facebook-pixel'
import Loader from '../loader'
import Modale from '../modale'

import offer2 from '../../image/offer-2.png'
import offer4 from '../../image/offer-4.jpg'
import offer7 from '../../image/offer-12.png'
import offer9 from '../../image/offer-9.png'
import { uri } from '../../uri'
import { getToken } from '../../redux/token'
import { renderPrice } from '../../helpers/utility'

const options = {
  autoConfig: true,
  debug: false
}
ReactPixel.init('1836825046422705', {}, options)
ReactPixel.pageView()
library.add(faPlusCircle, faCheck, faInfoCircle)

const errorMessages = {
  incorrect_number: 'Le numero de carte est incorrect.',
  invalid_number: "Le numero de carte n'est pas valide.",
  invalid_expiry_month: "Le mois d'expiration de la carte n'est pas valide.",
  invalid_expiry_year: "L'année d'expiration de la carte n'est pas valide.",
  invalid_cvc: "Le code de sécurité de la carte n'est pas valide.",
  expired_card: 'La carte est expirée.',
  incorrect_cvc: 'Le code de sécurité de la carte est invalide.',
  incorrect_zip: 'Le code postal est incorrect',
  card_declined: 'Le paiement a été refusé.',
  missing: 'Un problème lié à cette carte est survenu.',
  processing_error: 'Un problème est survenu et a empeché le paiement.',
  rate_limit: "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
  payment_intent_authentication_failure: "L'authentification 3DS Secure a échoué"
}

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      complete: false,
      isLoading: false,
      cardError: false,
      isPack: false,
      paiementSuccess: false,
      visibleModale: false,
      showConfirm: false,
      confirmRefuse: false
    }
    this.submit = this.submit.bind(this)
    this.handleChangeCard = this.handleChangeCard.bind(this)
    this.handleServerResponse = this.handleServerResponse.bind(this)
    this.handleStripeJsResult = this.handleStripeJsResult.bind(this)
  }

  handleServerResponse = (response) => {
    if (response && (response.error || response.data && response.data.error)) {
      console.log('errror')
      this.setState({ paiementError: response.error || response.data && response.data.error, isLoading: false })
      // Show error from server on payment form
    } else if (response && response.data && response.data.requires_action) {
      // Use Stripe.js to handle required card action
      this.props.stripe.handleCardAction(
        response.data.payment_intent_client_secret
      ).then(this.handleStripeJsResult)
    } else {
      if (response === false || response === 'false') {
        ReactPixel.track('Paiement échoué', response.error || response.data && response.data.error)
        console.log('error', response)
      } else {
        ReactPixel.track('Paiement réussi', true)
        console.log('success', response)
        this.props.form.resetFields()
        this.setState({ isLoading: false, cardError: false, paiementSuccess: true })
      }
      // Show success message
    }
  }

  handleStripeJsResult = (result) => {
    const {
      isDuo, form
    } = this.props
    const { isPack } = this.state
    const { id } = this.props.match.params
    const data = {
      token: '0',
      offerType: isDuo ? 'duo' : 'solo',
      isPack,
      id,
      lastName: form.getFieldValue('nom'),
      firstName: form.getFieldValue('prenom'),
      email: form.getFieldValue('email')
    }

    if (result.error) {
      this.setState({ paiementError: result.error.code, isLoading: false })
      // Show error in payment form
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server

      axios.post(uri.uri.user.pay, { mfc: data, payment_intent_id: result.paymentIntent.id }, getConfig()).then((response) => {
        if (response && response.data && response.data.error) {
          this.setState({ paiementError: response.data.error, isLoading: false })
          return false
        }
      }).then((newresp) => {
        // if( newresp ) {
        this.handleServerResponse(newresp)
        // }
      })
    }
  }


  async submit(ev) {
    const {
      stripe, isDuo, form
    } = this.props
    const { isPack } = this.state
    const { id } = this.props.match.params
    this.setState({ isLoading: true })

    const data = {
      token: '0',
      offerType: isDuo ? 'duo' : 'solo',
      isPack,
      id,
      lastName: form.getFieldValue('nom'),
      firstName: form.getFieldValue('prenom'),
      email: form.getFieldValue('email')
    }

    // Step 1

    stripe.createPaymentMethod({
      type: 'card',
      card: this.props.elements.getElement('card'),
      billing_details: {
        name: `${form.getFieldValue('nom')} ${form.getFieldValue('prenom')}`
      }
    }).then((result) => {
      if (result.error) {
        console.log('error', result.error)
        this.setState({ paiementError: (result.error && result.error.code) || 'erreur', isLoading: false })
        // Show error in payment form
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 3)
        axios.post(uri.uri.user.pay, { mfc: data, payment_method_id: result.paymentMethod.id }, getConfig()).then((response) => {
          this.handleServerResponse(response)
        })
      }
    })
  }


   warning = () => {
     const { isPack } = this.state
     return (

       <Modal
         visible
         className="warning__modal"
         closable={false}
       >
         <div className="warning__modal">
           <div className="warning__modal__first">
             Attention, vous n'avez pas sélectionné le pack surprise, il sera impossible de le rajouter par la suite.
           </div>
           <div className="warning__modal__second">
            Comment souhaitez-vous valider votre commande?
           </div>
           <div className="warning__modal__button__container">
             <div
               className="warning__modal__button__container__button"
               onClick={() => {
                 this.setState({
                   showConfirm: false,
                   confirmRefuse: true,
                   isPack: false
                 })
               }}
             >
               <div className="warning__modal__button__container__button__label">SANS</div>
               <div className="warning__modal__button__container__button__text">
                  Le pack surprise
               </div>
             </div>
             <div
               className="warning__modal__button__container__button"
               onClick={() => {
                 this.setState({
                   showConfirm: false,
                   confirmRefuse: true,
                   isPack: true
                 })
               }}
             >
               <div className="warning__modal__button__container__button__label">AVEC</div>
               <div className="warning__modal__button__container__button__text">
                  Le pack surprise
               </div>
             </div>
           </div>
         </div>
       </Modal>
     )
   }

   info = () => {
     const { isPack } = this.state
     return (

       <Modal
         visible
         title="PACK SURPRISE D’ACHAT exclusivement proposé pour la sortie du MFC Summer Shredding 2."
         closable={false}
         className="checkout__modal__root"
         onCancel={() => this.setState({ visibleModale: false })}
         footer={[
           <Button key="submit" type="primary" onClick={() => this.setState({ visibleModale: false })}>
             fermer
           </Button>,
         ]}
       >
         <div className="checkout__modal">

           <div className="checkout__modal__half">

             <div className="checkout__modal__item">
               <div className="checkout__modal__item__photo">
                 <img alt="offert" src={offer7} />
               </div>
               <div className="checkout__modal__item__text">
                 1 PDF DES RECETTES DU MYFIIT 5 !
                 <div className="checkout__modal__item__text__desc">
                   Un accès privilégié aux 10 planches alimentaires du MyFIIT 5, te donnant 40 nouvelles recettes équilibrées pour t’aider à mieux manger!
                 </div>
               </div>
             </div>
             <div className="checkout__modal__item">
               <div className="checkout__modal__item__photo">
                 <img alt="offert" src={offer4} />
               </div>
               <div className="checkout__modal__item__text">
                 CODE DE REDUCTION MYPROTEIN
                 <div className="checkout__modal__item__text__desc">
                   Ton coupon avec un code exceptionnel secret te donnant droit à -45% DE RÉDUCTION pour toute commande de vêtements ou compléments chez Myprotein.
                 </div>
               </div>
             </div>

           </div>
           <div className="checkout__modal__half">
             <div className="checkout__modal__half__plus"><FontAwesomeIcon icon="plus-circle" /></div>
             <div className="checkout__modal__item">
               <div className="checkout__modal__item__photo">
                 <img alt="offert" src={offer2} />
               </div>
               <div className="checkout__modal__item__text">
                 1 EBOOK DE 5 RECETTES BY THIBAULT GEOFFRAY
                 <div className="checkout__modal__item__text__desc">
                   5 recettes gourmandes exclusives par Thibault et jamais vues sur ses réseaux!
                 </div>
               </div>
             </div>
             <div className="checkout__modal__half__plus"><FontAwesomeIcon icon="plus-circle" /></div>
             <div className="checkout__modal__item">
               <div className="checkout__modal__item__photo">
                 <img alt="offert" src={offer9} />
               </div>
               <div className="checkout__modal__item__text">
                 1 PDF DES RECETTES DU MFC SUMMER SHREDDING!
                 <div className="checkout__modal__item__text__desc">
                   Un accès privilégié aux 6 menus du MFC SUMMER SHREDDING, te donnant 24 nouvelles recettes équilibrées pour t’aider à mieux manger!
                 </div>
               </div>
             </div>
           </div>
           <div className="checkout__modal__info">
             Ce pack n'est pas divisible (articles séparés non disponibles à la vente)
           </div>
           <div
             className={`checkout__modal__button ${isPack ? 'checkout__modal__button--is-pack' : ''}`}
             onClick={() => this.setState({ isPack: !isPack })}
           >
             {isPack && <FontAwesomeIcon icon={faCheck} style={{ marginRight: 5 }} />}
             {!isPack ? "Oui ça m'interesse !" : 'Pack ajouté au panier'}
           </div>
         </div>
       </Modal>
     )
   }

  handleSubmit = (e) => {
    const { hasUpsell } = this.props
    window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)

    if ((document.getElementById('card-num-container-input').className.indexOf('--complete') === -1)) {
      this.setState({ cardError: true })
    }

    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err && (document.getElementById('card-num-container-input').className.indexOf('--complete') !== -1)) {
        if (hasUpsell && !this.state.isPack && !this.state.confirmRefuse) {
          this.setState({ showConfirm: true })
        } else {
          this.setState({ isLoading: true, cardError: false })
          this.submit()
        }
      }
    })
  }

  handleChangeCard = (data) => {
    this.setState({ cardError: false })
  }

  compareToFirstEmail = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('email')) {
      callback('Les adresses mail sont différentes')
    } else {
      callback()
    }
  }

  validateToNextEmail = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.paiementSuccess || nextProps.paiementError !== false) {
      this.props.form.resetFields()
      window.scrollTo(0, 0)
      this.setState({ isLoading: false })
    }
    if (nextProps.paiementSuccess) {
      this.props.form.resetFields()
    }
  }

  componentWillUnmount() {
    this.props.form.resetFields()
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const {
      isLoading, cardError, isPack, visibleModale, showConfirm
    } = this.state
    const {
      paiementSuccess,
      paiementError,
      name,
      price,
      isDuo,
      isReplay,
      hasUpsell,
      mfc
    } = this.props

    // let isLoading = true
    return (
      <div className="payment-container">
        { visibleModale && this.info() }
        { showConfirm && this.warning() }
        { isLoading
          && (!paiementSuccess && !this.state.paiementSuccess && !paiementError)
          && <Loader message="Paiement en cours..." />
          }
        { (paiementSuccess || this.state.paiementSuccess)
          && <Modale buttonLabel={`Acceder à mon ${name}`} noShadow type="success" message="Ton paiement a bien été effectué, tu vas recevoir les instructions par mail (consulte aussi les emails indésirables)" backUrl={`/my-fiit-challenge/${name}`} />
          }
        { (paiementError !== false || this.state.paiementError)
          && !isLoading
          && <Modale reload noShadow type="error" message={errorMessages[paiementError || this.state.paiementError] || 'Une erreur est survenue, Vous n\'avez pas été débité, veuillez re-essayer'} backUrl={`/purchase/${name}`} />
          }
        { (!paiementError && !this.state.paiementError && !paiementSuccess && !this.state.paiementSuccess)
           && (
           <div className="payment-container__form" style={{ opacity: (isLoading || paiementSuccess) ? 0 : 1 }}>
             <div className="mfc__left__content__instructions">
                     Pour finaliser votre inscription, veuillez remplir le formulaire suivant:
             </div>
             <div className={`payment-container__form--mask ${isLoading ? 'payment-container__form--mask--visible' : ''}`} />
             <Form onSubmit={this.handleSubmit} className="login-form" style={{ opacity: isLoading ? 0 : 1 }}>
               <Form.Item>
                 {getFieldDecorator('nom', {
                   rules: [{ required: true, message: 'Veuillez renseigner votre nom' }],
                 })(
                   <Input className="custom-ant-input" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Nom" />
                 )}
               </Form.Item>
               <Form.Item>
                 {getFieldDecorator('prenom', {
                   rules: [{ required: true, message: 'Veuillez renseigner votre prénom' }],
                 })(
                   <Input className="custom-ant-input" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Prenom" />
                 )}
               </Form.Item>
               <Form.Item>
                 {getFieldDecorator('email', {
                   rules: [{
                     required: true, message: 'Vous devez saisir votre adresse mail',
                   }, {
                     validator: this.validateToNextEmail,
                   }],
                 })(
                   <Input className="custom-ant-input" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Email de votre choix" />
                 )}
               </Form.Item>
               <Form.Item>
                 {getFieldDecorator('confirmEmail', {
                   rules: [{
                     required: true, message: 'Vous devez confirmer votre adresse mail',
                   }, {
                     validator: this.compareToFirstEmail,
                   }],
                 })(
                   <Input className="custom-ant-input" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Confirmation email" onBlur={this.handleConfirmBlur} />
                 )}
               </Form.Item>
               <Form.Item>
                 <div className="card-num-container" style={{ border: cardError ? 'solid 1px #f5222d' : '' }}>
                   <CardElement
                     id="card-num-container-input"
                     onChange={this.handleChangeCard}
                     hidePostalCode
                   />
                 </div>
                 {cardError
                      && (
                      <div className="ant-form-item-control has-error" style={{ color: '#f5222d' }}>
                        Vous devez saisir un numéro de carte bancaire correct
                      </div>
                      )
                    }
               </Form.Item>

               {hasUpsell && mfc.upsellPrice !== 0 && (
                 <div className="mfc__right__offer__container">
                   { !isPack && <div className="mfc__right__offer__container__advertising">Recommandé : Les acheteurs du Myfiit ont fréquemment ajouté dans leur panier le PACK SURPRISE MFC+ à seulement {renderPrice(mfc.upsellPrice)}€ !</div> }
                   <div className="mfc__right__offer">
                     <div className="mfc__right__offer__img"><img alt="offert" src={offer2} /></div>
                     <div className="mfc__right__offer__img"><img alt="offert" src={offer9} /></div>
                     <div className="mfc__right__offer__img"><img alt="offert" src={offer4} /></div>
                     <div className="mfc__right__offer__img"><img alt="offert" src={offer7} /></div>
                   </div>

                   <div className="mfc__right__offer__container__buttons">
                     <div className="mfc__right__offer__container__button mfc__right__offer__container__button--info" type="primary" onClick={() => this.setState({ visibleModale: true })}>
                       <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} /> En savoir plus
                     </div>
                     <div className="mfc__right__offer__container__button" type="primary" onClick={() => this.setState({ isPack: !isPack })}>
                       {!isPack ? "J'en profite!" : "Retirer l'offre" }
                     </div>
                   </div>
                 </div>
               )}

               <div className="payment-container__form__details">
                 <div className="payment-container__form__details__title">Détails de la commande</div>
                 <div className="payment-container__form__details__container">
                   <div className="payment-container__form__details__container__article">MyFIITChallenge : {name} {isDuo && '(2 places)'}</div>
                   <div className="payment-container__form__details__container__price">{price / 100}€</div>
                 </div>
                 { isPack && (
                   <div className="payment-container__form__details__container">
                     <div className="payment-container__form__details__container__article">Pack PDF + Cadeau</div>
                     <div className="payment-container__form__details__container__price">{renderPrice(mfc.upsellPrice)} €</div>
                   </div>
                 )}
                 <div className="payment-container__form__details__total">Total {!isPack ? renderPrice(price) : renderPrice(price + mfc.upsellPrice)}€</div>
               </div>


               <div className="payment__security-label" style={{ marginTop: 20, margin: 15 }}>
                 Paiement 100% sécurisé
               </div>

               <div className="cgu-info">En cliquant sur le bouton de paiement vous certifiez avoir lu <a href="https://www.myfiitchallenge.com/mentions-legales" rel="noopener noreferrer" target="_blank">les conditions générales d'utilisation </a>et ne présenter aucune contre-indication médicale à la pratique du
                 fitness
               </div>

               <Form.Item style={{ height: 80 }}>
                 {!isLoading
                 && (
                   <div className="mfc__right__pdfs">
                     <button onClick={() => document.getElementsByClassName('mfc__left')[0].scrollTo(0, 0)} className="mfc__right__pdfs__link mfc__right__pdfs__link--mobile">
                       Payer { isPack ? renderPrice(price + mfc.upsellPrice) : renderPrice(price) }€
                     </button>
                   </div>
                 )
                 }
               </Form.Item>
               <div className="mfc__right__help mfc__right__help__sub">
                 * Veuillez patienter 1 minute en cas de page blanche lors du paiement<br /><br />
               </div>
               <div className="mfc__right__help">
                 <Link to="/help">
                   Le paiement n'aboutit pas <FontAwesomeIcon icon={faQuestionCircle} />
                 </Link>
               </div>
             </Form>
           </div>
           )
          }
      </div>
    )
  }
}

export default withRouter(Form.create({ name: 'stripe_purchase' })(injectStripe(CheckoutForm)))
