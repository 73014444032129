import React, { Component } from 'react'
import { withRouter, BrowserRouter as Router } from 'react-router-dom'
import {
  Upload,
  Icon,
  message,
  Table,
  Divider,
  Tag,
  DatePicker,
  Form,
  Button,
  Radio,
  notification,
  Rate,
  Select,
  Input,
  Row, Col
} from 'antd'

import moment from 'moment'
import { connect } from 'react-redux'
import LayoutContentWrapper from '../../../../../components/utility/layoutWrapper'
import TableDemoStyle from '../demo.style'
import {
  deletePdf,
  getMfc,
  getMfcs,
  getPdfs,
  getPdfsById,
  reset, saveMfc,
  sendPdf,
  sendYoutube
} from '../../../../../redux/admin/action'

moment.locale('fr', {
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact: true,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal(number) {
    return number + (number === 1 ? 'er' : 'e')
  },
  meridiemParse: /PD|MD/,
  isPM(input) {
    return input.charAt(0) === 'M'
  },
  meridiem(hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD'
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // Used to determine first week of the year.
  }
})

const Dragger = Upload.Dragger
const env = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:5151' : 'http://prod:5151'
const RadioButton = Radio.Button
const Option = Select.Option


class Pdfs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      publicationDate: null,
      type: null,
      pdf: null,
      env: null,
      week: null,
      hiit: null,
      mfcName: null,
      mfcPdfs: [],
      blockEdit: false
    }

    this.checkPdf = this.checkPdf.bind(this)
    this.handleChangeWeek = this.handleChangeWeek.bind(this)
    this.selectMfc = this.selectMfc.bind(this)
  }

  componentWillMount() {
    const {
      getPdfs, getMfcs, getMfc, match
    } = this.props
    if (match.params && match.params.mfcId) {
      getMfc(match.params.mfcId)
    }
    getMfcs()
  }

  componentDidMount() {
    // const { getPdfsById, mfc } = this.props
    // console.log('get pdf')
    // getPdfs()
    // getPdfsById(mfc._id)
  }

  componentWillReceiveProps(nextProps) {
    const {
      sendSuccess, getPdfs, reset, mfc, getPdfsById, edited, match
    } = nextProps
    const currentSendSuccess = this.props.sendSuccess

    const { mfcPdfs } = nextProps
    this.setState({ mfcPdfs })

    if (sendSuccess === 'error') {
      notification.error({
        message: 'Erreur d\'upload',
        description: 'Une erreur est survenue pendant l\'upload du fichier pdf',
      })
    }

    if (mfc !== this.props.mfc) {
      getPdfsById(mfc._id)
    }

    if (edited && !this.state.blockEdit) {
      this.setState({ blockEdit: true })
      notification.success({
        message: 'Le lien youtube a bien été ajouté',
        description: 'Tout s\'est bien passé.',
      })
      getPdfsById(mfc._id)
      setTimeout(() => {
        this.setState({ blockEdit: false })
      }, 1000)
    }

    if (sendSuccess === 'success' && currentSendSuccess !== 'success') {
      this.setState({
        publicationDate: null,
        type: '',
        pdf: null,
        week: 1,
        hiit: 1
      })

      notification.success({
        message: 'Le pdf a bien été uploadé',
        description: 'Tout s\'est bien passé.',
      })
      if (match.params && match.params.mfcId) {
        getMfc(match.params.mfcId)
      }
      getMfcs()
      getPdfsById(mfc._id)

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  }

  sendPdf = () => {
    const { sendPdf, sendYoutube, mfc } = this.props
    const {
      publicationDate, pdf, type, week, hiit
    } = this.state

    if (type === 'YOUTUBE') {
      if (publicationDate && week) {
        const params = {
          publicationDate,
          type,
          week,
          session: hiit || 0,
          mfcName: (mfc.name || 'MFC').replace(' ', '-').replace('/', '-').toUpperCase()
        }
        sendYoutube(mfc._id, params)
      } else {
        notification.error({
          message: 'Champs manquants',
          description: 'Vous devez renseigner tous les champs',
        })
      }
    } else {
      if (publicationDate && pdf && ((type === 'HIIT' && hiit) || (type === 'NUTRI')) && week) {
        const params = {
          publicationDate,
          file: pdf,
          type,
          week,
          session: hiit || 0,
          mfcName: (mfc.name || 'MFC').replace(' ', '-').replace('/', '-').toUpperCase()
        }
        sendPdf(mfc._id, params)
      } else {
        notification.error({
          message: 'Champs manquants',
          description: 'Vous devez renseigner tous les champs',
        })
      }
    }
  }

  sendYoutubeAction = () => {
    const { sendPdf, sendYoutube, mfc } = this.props
    const {
      publicationDate, pdf, type, week, hiit
    } = this.state

    if (type === 'YOUTUBE') {
      if (publicationDate && week) {
        const params = {
          publicationDate,
          type,
          week,
          session: hiit || 0,
          mfcName: (mfc.name || 'MFC').replace(' ', '-').replace('/', '-').toUpperCase()
        }
        sendYoutube(mfc._id, params)
      } else {
        notification.error({
          message: 'Champs manquants',
          description: 'Vous devez renseigner tous les champs',
        })
      }
    } else {
      if (publicationDate && pdf && ((type === 'HIIT' && hiit) || (type === 'NUTRI')) && week) {
        const params = {
          publicationDate,
          file: pdf,
          type,
          week,
          session: hiit || 0,
          mfcName: (mfc.name || 'MFC').replace(' ', '-').replace('/', '-').toUpperCase()
        }
        sendPdf(mfc._id, params)
      } else {
        notification.error({
          message: 'Champs manquants',
          description: 'Vous devez renseigner tous les champs',
        })
      }
    }
  }

  onChange = (date) => {
    this.setState({ publicationDate: date })
  }

  checkPdf = (info) => {
    if (info.type === 'application/pdf') {
      this.setState({
        pdf: info
      })
    }
    return false
  }

  deletePdf = (pdf) => {
    const { deletePdf } = this.props
    deletePdf(pdf)
  }

  updateYoutubeId = (e, value, index) => {
    const { mfcPdfs } = this.state
    const tmpMfcPdfs = [...mfcPdfs]
    const idx = tmpMfcPdfs.findIndex(t => String(t.filename) === value.name)
    tmpMfcPdfs[idx].youtubeID = e
    this.setState({ mfcPdfs: tmpMfcPdfs })
  }

  getYoutubeId = (value, index) => {
    const { mfcPdfs } = this.state
    const tmpMfcPdfs = [...mfcPdfs]
    const idx = tmpMfcPdfs.findIndex(t => String(t.filename) === value.name)
    // return tmpMfcPdfs[idx].youtubeID
  }

  saveYoutubeLink = (value, index) => {
    const { mfcPdfs } = this.state
    const tmpMfcPdfs = [...mfcPdfs]
    const idx = tmpMfcPdfs.findIndex(t => String(t.filename) === value.name)
    const youtubeData = {
      week: index,
      day: mfcPdfs[idx].session,
      youtubeId: tmpMfcPdfs[idx].youtubeID
    }
    this.props.saveMfcYoutube(this.props.match.params.mfcId, youtubeData)
  }

  checkIdFormat = (value, b, c) => {
    const { mfcPdfs } = this.state
    const tmpMfcPdfs = [...mfcPdfs]
    const idx = tmpMfcPdfs.findIndex(t => String(t.filename) === value.name)
    const id = tmpMfcPdfs[idx].youtubeID
    return !(id && String(id).length === 11)
  }

  renderColumns = (file, value, index) => (
    [{
      title: 'Nom du fichier',
      dataIndex: 'name',
      key: 'name',
      render: text => <a href="javascript:;">{text}</a>,
    }, {
      title: 'Date d\'apparition',
      dataIndex: 'apparition',
      key: 'apparition',
    }, {
      title: 'Type de pdf',
      key: 'type',
      dataIndex: 'type',
      render: type => (
        <span>
          {type.map(tag => <Tag color={tag === 'NUTRI' ? 'pink' : 'blue'} key={type}>{type}</Tag>)}
        </span>
      ),
    },
    {
      title: 'Youtube video ID',
      key: 'youtubeId',
      render: text => (
        text && text.type && (text.type[0] === 'HIIT' || text.type[0] === 'YOUTUBE')
        && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input type="text" style={{ width: '100%%', maxWidth: 120 }} defaultValue={text.youtubeID} onChange={e => this.updateYoutubeId(e.target.value, text, index)} />
          <Button style={{ width: '100%', maxWidth: 80 }} type={this.checkIdFormat(text, index, file) ? 'secondary' : 'primary'} disabled={this.checkIdFormat(text, index)} onClick={() => this.saveYoutubeLink(text, index)}>Valider</Button>
          { text.youtubeID && <div style={{ width: '100%', maxWidth: 120, paddingLeft: 20 }}><img src={`https://img.youtube.com/vi/${text.youtubeID}/0.jpg`} style={{ width: '100%', borderRadius: 5 }} /></div> }
        </div>
        )
      ),
    }, {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <a onClick={() => this.deletePdf(text.name)}>Supprimer</a>
          <Divider type="vertical" />
        </span>
      ),
    }]
  )

  handleChangeWeek = (a) => {
    this.setState({ week: a })
  };

  handleChangeHiit = (a) => {
    this.setState({ hiit: a })
  };

  selectMfc = (mfc) => {
    const { getMfc, history } = this.props
    history.push(`/dashboard/pdfs/${mfc}`)
    getMfc(mfc)
  }

  render() {
    const {
      sendSuccess, mfcs, mfc, match
    } = this.props
    const { mfcPdfs } = this.state
    const { publicationDate, type } = this.state


    const data = []
    mfcPdfs && mfcPdfs.map((p, idx) => data.push({
      key: idx,
      name: p.filename,
      apparition: moment(p.publicationDate).lang('fr').format('LLLL'),
      type: [p.type],
      youtubeID: p.youtubeID,
    }))


    const weeks = {}
    if (mfcPdfs) {
      const sortWeeks = [...new Set(mfcPdfs.map(item => item.week))].sort()

      sortWeeks.map((w) => {
        const weekCount = `Semaine ${w}`
        const tempWeek = []
        const dataLoop = mfcPdfs.filter(d => d.week === w).sort((a, b) => a.session - b.session)

        dataLoop.map((d, i) => {
          tempWeek.push({
            key: `${d.filename}-${i}`,
            name: d.filename,
            apparition: moment(d.publicationDate).lang('fr').format('LLLL'),
            type: [d.type],
            //youtubeID: mfc.liveYoutubeVideos ? ((mfc.liveYoutubeVideos.find(m => m.week === (w - 1) && m.day === i)) && (mfc.liveYoutubeVideos.find(m => m.week === (w - 1) && m.day === i).youtubeId)) || '' : ''
            youtubeID: mfc.liveYoutubeVideos ? ((mfc.liveYoutubeVideos.find(m => m.week === (w - 1) && m.day === (i + 1))) && (mfc.liveYoutubeVideos.find(m => m.week === (w - 1) && m.day === (i + 1)).youtubeId)) || '' : ''
          })
        })

        weeks[weekCount] = tempWeek
      })
    }

    return (
      <LayoutContentWrapper>
        <TableDemoStyle className="isoLayoutContent">

          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <div style={{
                padding: 15,
                background: '#f1f3f6',
                marginBottom: 20
              }}
              >
                Gestion des Pdf's
              </div>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col className="gutter-row" span={24}>
              <Form layout="inline" style={{ marginTop: 20, padding: 15, border: 'dashed 1px #d9d9d9' }}>
                <Form.Item label="Selectionnez un MyFiit">
                  <Select onChange={this.selectMfc} defaultValue={match.params.mfcId || 'Challenge'}>
                    {
                      mfcs && mfcs.filter(m => !m.isReplay).map((mfc, i) => (
                        <Option key={`mfc-${i}`} value={mfc._id} onClick={() => this.setState({ mfcName: mfc.name })}>{mfc.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          {
            mfc && (
              <React.Fragment>

                {
                  this.state.type !== 'YOUTUBE' && (
                    <Row gutter={16} style={{ marginTop: 20 }}>
                      <Col className="gutter-row" span={24}>
                        <Dragger
                          name="file"
                          multiple={false}
                          beforeUpload={info => this.checkPdf(info)}
                          accept="pdf"
                          withCredentials
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Cliquez ou faites glisser un fichier PDF pour l'uploader</p>
                          <p className="ant-upload-hint">Ensuite, sélectionnez la date et l'heure à laquelle vous voulez qu'il soit mis à disposition</p>
                        </Dragger>
                      </Col>
                    </Row>
                  )
                }

                <Row gutter={20}>
                  <Col className="gutter-row" span={24}>
                    <Form layout="inline" style={{ marginTop: 20, padding: 15, border: 'dashed 1px #d9d9d9' }}>
                      <Form.Item label="Date de parution souhaitée">
                        <DatePicker value={publicationDate} onChange={(a, b) => this.onChange(a, b)} showTime format="YYYY-MM-DD HH:mm:ss" />
                      </Form.Item>
                      <div>
                        <Form.Item label="Type: ">
                          <Radio.Group>
                            <RadioButton value="a" onClick={() => this.setState({ type: 'HIIT' })}>HIIT</RadioButton>
                            <RadioButton value="b" onClick={() => this.setState({ type: 'NUTRI' })}>NUTRI</RadioButton>
                            <RadioButton value="c" onClick={() => this.setState({ type: 'YOUTUBE' })}>YOUTUBE</RadioButton>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Semaine n°">
                          <Select onChange={this.handleChangeWeek} defaultValue="Semaine">
                            {
                              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((k, i) => (
                                <Option key={`week-${i}`} value={k}>{k}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                        {
                          type !== 'NUTRI' && (
                            <Form.Item label="Hiit n°">
                              <Select onChange={this.handleChangeHiit} defaultValue="Hiit">
                                {
                                  [1, 2, 3, 4, 5, 6, 7, 8].map((k, i) => (
                                    <Option key={`session-${i}`} value={k}>{k}</Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>
                          )}

                        <Form.Item>
                          {
                            this.state.type === 'YOUTUBE' ? (
                              <Button type="primary" onClick={() => this.sendYoutubeAction()}>Valider video youtube</Button>
                            ) : (
                              <Button type="primary" onClick={() => this.sendPdf()}>Envoyer PDF</Button>
                            )
                          }

                        </Form.Item>
                      </div>
                    </Form>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col className="gutter-row" span={24}>
                    <Divider dashed>Historique</Divider>
                  </Col>
                </Row>
                {
                  weeks && Object.entries(weeks).map(([pdf, value], index) => (
                    <React.Fragment key={`df-row-${pdf}`}>
                      {pdf}
                      <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                          <div style={{ marginTop: 30 }}>
                            <Table columns={this.renderColumns(value[index], value, index)} dataSource={value} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))
                }
                {
                  !Object.keys(weeks).length && (
                    <Row gutter={16}>
                      <Col className="gutter-row" span={24}>
                        <div style={{
                          padding: 15,
                          background: '#f1f3f6',
                          marginBottom: 20
                        }}
                        >
                          Aucun Pdf enregistré pour le moment
                        </div>
                      </Col>
                    </Row>
                  )
                }
              </React.Fragment>
            )
          }
        </TableDemoStyle>
      </LayoutContentWrapper>
    )
  }
}

const mapStateToProps = state => ({
  sendSuccess: state.Admin.sendSuccess,
  mfcPdfs: state.Admin.mfcPdfs,
  mfcs: state.Admin.mfcs,
  mfc: state.Admin.mfc,
})


export default connect(mapStateToProps, {
  sendPdf,
  sendYoutube,
  getPdfs,
  getPdfsById,
  deletePdf,
  reset,
  getMfcs,
  getMfc,
  saveMfc
})(withRouter(Pdfs))
