import React, { Component } from 'react'
import './stylesheet.scss'
import moment from 'moment'
import {
  Pagination, Popconfirm, Switch, Modal, Button, Timeline, notification
} from 'antd'
import axios from 'axios'
import { getToken, setToken } from '../../../../../redux/token'
import empty from '../../../../../image/empty.png'
import { uri } from '../../../../../uri'
import { renderPrice } from '../../../../../helpers/utility'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPaperPlane
} from '@fortawesome/free-solid-svg-icons'

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    this.state = {
      visibleModale: false,
      updateMail: false,
      updateName: false,
      currentMail: '',
      currentLastname: '',
      currentFirstname: '',
    }
  }

  componentWillMount() {
    const { getMfcs } = this.props
    getMfcs()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { findUser } = this.props
    if (nextProps.successUserUpdate
      && nextProps.successUserUpdate
      !== this.props.successUserUpdate) {
      findUser(nextProps.queryParam)
    }
  }

  handleOk = (e) => {
    this.setState({
      visibleModale: false,
      updateMail: false,
      updateName: false,
      currentMail: '',
      currentLastname: '',
      currentFirstname: '',
    })
  }

  handleDelete = (id) => {
    const { deleteAccount } = this.props
    deleteAccount(id)
    this.setState({
      visibleModale: false,
      updateMail: false,
      updateName: false,
      currentMail: '',
      currentLastname: '',
      currentFirstname: '',
    })
  }

  handleCancel = (e) => {
    this.setState({
      updateMail: false,
      updateName: false,
      visibleModale: false,
      currentMail: '',
      currentLastname: '',
      currentFirstname: '',
    })
  }


  onPaginationChange = (e) => {
    const { findUser, queryParam } = this.props
    const newParams = { ...queryParam, page: e }
    findUser(newParams)
  }

  renderPurchases = (purchases, mfcs) => {
    return (
      <ul style={{ width: '100%' }}>
        {
          mfcs && mfcs.map((m, index) => (
            <li key={`mfcs-row-${index}`} className="pastille__container">{ purchases.filter(p => p.mfc._id === m._id).length
              ? (
                <div className="pastille__container__row">
                  <div className="pastille__container__row--ok" /> {m.name}
                </div>
              )
              : (
                <div className="pastille__container__row">
                  <div className="pastille__container__row--ko" /> {m.name}
                </div>
              )
            }
            </li>
          ))
        }
      </ul>
    )
  }


  /* {
    mfc.map((p, index) => (
      <li style={{ display: 'flex', justifyContent: 'space-between' }} key={`purchase-${index}`}>
        <div>{ p.mfc.name }</div>
        <div style={{
          display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', paddingLeft: 10, fontSize: 12, alignItems: 'center'
        }}
        >({moment(p.mfc.purchaseDate).format('DD/MM/YYYY')})
        </div>
      </li>
    ))
  } */

  onSwicthUser = (id, checked) => {
    const { editUser } = this.props
    editUser(id, !checked)
  }

  isInclude = (user, mfc) => {
    if (user.purchases) {
      return user.purchases.find(u => u.mfc.name === mfc.name && u.mfc.isReplay === mfc.isReplay)
    }
    return false
  }

  isCodeInsertion = (user, mfc) => {
    if (user.purchases) {
      return user.purchases.find(u => (u.mfc.name === mfc.name) && u.stripeId === 'setByPromoCode')
    }
    return false
  }

  onGiveAccess = (id, mfc) => {
    const { giveAccess } = this.props
    giveAccess(id, mfc)
  }

  editMail = (mail) => {
    this.setState({ currentMail: mail, updateMail: true })
  }

  editName = (user) => {
    this.setState({ currentLastname: user.lastName, currentFirstname: user.firstName, updateName: true })
  }

  saveMail = (id) => {
    const { editMail } = this.props
    
    if (this.validateEmail(this.state.currentMail)) {
      editMail(id, this.state.currentMail)
      this.setState({ currentMail: '', updateMail: false })
      notification.success({
        message: 'Email modifié!',
        description: 'Tout s\'est bien passé.',
      })
    } else {
      notification.error({
        message: 'Email invalide',
        description: 'E R R E U R',
      })
    }
  }

  saveName = (id) => {
    const { editName } = this.props
    const { currentFirstname, currentLastname } = this.state

    if (currentFirstname && currentLastname) {
      editName(id, currentFirstname, currentLastname)
      this.setState({ currentFirstname: '', currentLastname: '', updateName: false})
      notification.success({
        message: 'Nom / Prénom modifié(s)!',
        description: 'Tout s\'est bien passé.',
      })
    } else {
      notification.error({
        message: 'Les champs ne doivent pas être vides',
        description: 'E R R E U R',
      })
    }
  }

  sendBonus = (user, mfc) => {
    axios.post(`${uri.uri.admin.sendBonus}`, {
      id: user._id, email: user.email, firstName: user.firstName, challenge: mfc.edition
    }, getConfig()).then((response) => {
      if (response.status === 200) {
        notification.success({
          message: 'Le pack bonus a bien été envoyé',
          description: 'Tout s\'est bien passé.',
        })
      } else {
        notification.error({
          message: 'Une erreur s\'est produite, le pack n\'a pas été envoyé',
          description: 'Oups.',
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Une erreur s\'est produite, le pack n\'a pas été envoyé',
        description: 'Oups.',
      })
      console.log(error)
    })
  }

  sendWelcome = (user, mfc) => {
    axios.post(`${uri.uri.admin.sendWelcome}`, {
      id: user._id, email: user.email, firstName: user.firstName, challenge: mfc
    }, getConfig()).then((response) => {
      if (response.status === 200) {
        notification.success({
          message: 'Le mail de bienvenue a bien été envoyé',
          description: 'Tout s\'est bien passé.',
        })
      } else {
        notification.error({
          message: 'Une erreur s\'est produite, le mail de bienvenue n\'a pas été envoyé',
          description: 'Oups.',
        })
      }
    }).catch((error) => {
      notification.error({
        message: 'Une erreur s\'est produite, le mail de bienvenue n\'a pas été envoyé',
        description: 'Oups.',
      })
      console.log(error)
    })
  }

  validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  // handleFocus = event => event.target.select();

  renderModale = (user, mfcs, userInfo) => (
    <Modal
      visible
      width={700}
      title={user.name || 'utilisateur'}
      onOk={this.handleOk}
      onCancel={() => this.setState({ visibleModale: false })}
      closable
      footer={[
        <div onClick={e => e.stopPropagation()}>
          <Popconfirm
            placement="left"
            title={`Etes-vous sur(e) de vouloir supprimer le compte de ${user.name || 'l\\utilisateur'}?`}
            onConfirm={e => this.handleDelete(user._id)}
            okText="OUI, Supprimer"
            cancelText="Annuler"
          >
            <Button key="submit" type="primary">
              Supprimer le compte
            </Button>
          </Popconfirm>
        </div>,
        <Button key="submit" type="success" onClick={this.handleOk}>
          Fermer
        </Button>,
      ]}
    >
      <div>
        <div className="dashboard__modale__mail">
          <input autoFocus={this.state.updateMail} onChange={e => this.setState({ currentMail: e.target.value })} type="email" className={`dashboard__modale__mail__input ${this.state.updateMail ? 'dashboard__modale__mail__input--edit' : ''}`} disabled={!this.state.updateMail} value={this.state.currentMail !== '' ? this.state.currentMail : user.email} />
          <div className="dashboard__modale__mail__label">
            <a onClick={() => (this.state.updateMail ? this.saveMail(user._id) : this.editMail(user.email))}>{ this.state.updateMail ? 'Valider' : 'Modifier email'}</a>
            { this.state.updateMail && (
            <a onClick={() => this.setState({ updateMail: false, currentMail: '' })}> - Annuler</a>
            )
            }
          </div>
        </div>
        <div className="dashboard__modale__name">
          <input onChange={e => this.setState({ currentFirstname: e.target.value })} type="text" className={`dashboard__modale__name__input ${this.state.updateName ? 'dashboard__modale__name__input--edit' : ''}`} disabled={!this.state.updateName} value={this.state.currentFirstname !== '' ? this.state.currentFirstname : user.firstName} />
          <input onChange={e => this.setState({ currentLastname: e.target.value })} type="text" className={`dashboard__modale__name__input ${this.state.updateName ? 'dashboard__modale__name__input--edit' : ''}`} disabled={!this.state.updateName} value={this.state.currentLastname !== '' ? this.state.currentLastname : user.lastName} />
          <div className="dashboard__modale__name__label">
            <a onClick={() => (this.state.updateName ? this.saveName(user._id, this.state.currentFirstname, this.state.currentLastname) : this.editName(user))}>{ this.state.updateName ? 'Valider' : 'Modifier nom / prénom'}</a>
            { this.state.updateName && (
            <a onClick={() => this.setState({ updateName: false, currentLastname: '', currentFirstname: '' })}> - Annuler</a>
            )
            }
          </div>
        </div>
        <div className="dashboard__modale__connexion">{ `Dernière connexion: ${moment(user.lastConnectionDate).format('LLLL')}` }</div>
        <div className="dashboard__modale__mfc">
          {
            mfcs && mfcs.map((mfc, index) => (
              <div className="dashboard__modale dashboard__modale__row" key={`map-mfc-${index}`}>
                <div className="dashboard__modale__left">
                  { mfc.name }  { (this.isInclude(user, mfc) && this.isInclude(user, mfc).price) && (
                  <span>
                    ({ this.isInclude(user, mfc).stripeId !== 'setByAdmin' ? (
                    this.isCodeInsertion(user, mfc) ? 'Code partenaire utilisé'
                      : `payé ${renderPrice(this.isInclude(user, mfc).price)} €`
                  ) : 'Set by admin' })
                  </span>
                  )}
                </div>
                <div className="dashboard__modale__right">
                  {
                    mfc.hasUpsell && (
                    <div className="dashboard__modale__mfc__bonus">
                      <Popconfirm
                        title={`Etes-vous sur(e) de vouloir envoyer le pack bonus à ${user.name}?`}
                        okText="Envoyer pack bonus"
                        cancelText="Annuler"
                        onConfirm={() => this.sendBonus(user, mfc)}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} /> bonus
                      </Popconfirm>
                    </div>
                    )
                  }
                  <div className="dashboard__modale__mfc__bonus">
                    <Popconfirm
                      title={`Etes-vous sur(e) de vouloir envoyer le mail de bienvenue à ${user.name}?`}
                      okText="Envoyer mail de bienvenue"
                      cancelText="Annuler"
                      onConfirm={() => this.sendWelcome(user, mfc)}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} /> bienvenue
                    </Popconfirm>
                  </div>
                  <Popconfirm
                    title={`Etes-vous sur(e) de vouloir ${this.isInclude(user, mfc) ? 'supprimer l\'accès' : 'accorder l\'accès'} au ${mfc.name} à ${user.name}?`}
                    okText={this.isInclude(user, mfc) ? 'Desactiver acces' : 'Donner acces'}
                    cancelText="Annuler"
                    onConfirm={() => this.onGiveAccess(user._id, mfc)}
                  >
                    <Switch checked={!!this.isInclude(user, mfc)} style={{ borderRadius: 10 }} />
                  </Popconfirm>
                </div>
              </div>
            ))
          }
          <div style={{ padding: 20 }}>
            <Timeline>
              <React.Fragment>
                {
                    userInfo && userInfo.purchases.map((p, index) => (
                      p.promoCodes && !!p.promoCodes.length && p.promoCodes.map((promo, idx) => (
                        <Timeline.Item color="green" key={`promo-${index}-${idx}`}>
                          {
                            promo.type === 'duo' && (
                            <React.Fragment>
                              <p>Code: {promo.code}</p>
                              <p>Acheté par: {promo.boughtBy} le {moment(promo.creationDate).format('LLLL')} </p>
                              { promo.invalidated ? <p><span style={{ fontWeight: 'bold', color: 'red' }}>[ANNULÉ PAR ADMIN]</span></p> : (promo.consumedBy ? <p><span style={{ fontWeight: 'bold', color: 'red' }}>[Consommé]</span> par: {promo.consumedBy} le: {moment(promo.consumedAt).format('LLLL')}</p> : <p style={{ fontWeight: 'bold', color: '#52c41a' }}>[Non Consommé]</p>) }
                            </React.Fragment>
                            )}
                        </Timeline.Item>
                      ))
                    ))
                  }
              </React.Fragment>
            </Timeline>
          </div>
        </div>
      </div>
    </Modal>
  )

  manageUser = (key, id) => {
    const { getUserInfo } = this.props
    getUserInfo(id)
    this.setState({ visibleModale: key })
  }

  render() {
    const {
      dataList, tableInfo, userPage, mfcs, queryParam, userInfo
    } = this.props

    const { visibleModale } = this.state
    return (
      <React.Fragment>
        <div className="dashboard__page-info admin-row-flex--padding">
          { `Page ${queryParam ? (queryParam.page || 1) : 1} / ${Math.ceil(dataList.count / 30)}` }
          { this.props.dataList && this.props.dataList.count && (
            <span>
              resultat de recherche:<b>{this.props.dataList.count}</b>
            </span>
          )}
        </div>
        <div className="dashboard__table">
          <div className="dashboard__table__header">
            {
            tableInfo.columns.map((col, index) => (
              <div key={`index-${index}`} className="dashboard__table__header__item">{ col.title }</div>
            ))
          }
          </div>
          <div className="dashboard__table__body">

            {
            dataList && dataList.items && dataList.items.map((user, index) => (
              // tableInfo.columns.map((col, index) => (
              <div key={`index-2-${index}`} className="dashboard__table__body__row" style={{ borderLeft: `solid 10px ${user.purchases.length ? '#3cd83c' : '#fe5146'}` }}>
                <div className="dashboard__table__body__row__col dashboard__table__body__row__col--bold">
                  <div className="dashboard__table__body__row__col__avatar">
                    <img src={user.avatar || empty} alt="mfc-avatar" />
                  </div>
                  { user.name } { (user.firstName || user.lastName) && <span>({user.firstName} {user.lastName})</span>}
                </div>
                <div className="dashboard__table__body__row__col">{ user.email } </div>
                <div className="dashboard__table__body__row__col">{ this.renderPurchases(user.purchases, mfcs) } </div>
                <div className="dashboard__table__body__row__col hide-mobile">{ moment(user.lastConnectionDate).format('LLLL') } </div>
                <div className="dashboard__table__body__row__col">
                  <Button
                    style={{
                      background: '#4a5369',
                      border: 'solid 1px #495268',
                      color: '#FFFFFF'
                    }}
                    onClick={() => this.manageUser(`modale-${index}`, user._id)}
                  >Gérer
                  </Button>
                  { visibleModale === `modale-${index}` && this.renderModale(user, mfcs, userInfo)}
                </div>
              </div>
              // ))
            ))
            }
            {
              (!dataList || !dataList.items || !dataList.items.length)
              && (
              <div className="dashboard__table__body__empty">
                Aucun utilisateur ne correspond à votre recherche.
              </div>
              )
            }
          </div>
        </div>
        <div className="dashboard__pagination">
          <Pagination
            defaultCurrent={queryParam ? (queryParam.page - 1 || 0) : 0}
            total={dataList ? (Math.ceil(dataList.count || 0)) : 0}
            pageSize={30}
            hideOnSinglePage
            onChange={this.onPaginationChange}
          />
        </div>
      </React.Fragment>
    )
  }
}
