import React from 'react'
import clone from 'clone'
import IntlMessages from '../../../../components/utility/intlMessages'
import {
  DateCell, ImageCell, LinkCell, TextCell, StarkDate, humanDate, BoolBowl
} from './helperCells'

const renderCell = (object, type, key) => {
  const value = object[key]
  switch (type) {
    case 'ImageCell':
      return ImageCell(value)
    case 'DateCell':
      return DateCell(value)
    case 'StarkDate':
      return StarkDate(value)
    case 'humanDate':
      return humanDate(value)
    case 'LinkCell':
      return LinkCell(value)
    case 'BoolBowl':
      return BoolBowl(value)
    default:
      return TextCell(value)
  }
}

const columns = [
  {
    title: <IntlMessages id="antTable.title.image" />,
    key: 'avatar',
    width: '1%',
    className: 'isoImageCell',
    render: object => renderCell(object, 'ImageCell', 'avatar')
  },
  {
    title: 'Nom Facebook',
    key: 'name',
    width: 80,
    render: object => renderCell(object, 'TextCell', 'name')
  },
  {
    title: "Date d'inscription",
    key: 'creationDate',
    width: 100,
    render: object => renderCell(object, 'StarkDate', 'creationDate')
  },
  {
    title: 'Email',
    key: 'email',
    width: 100,
    render: object => renderCell(object, 'TextCell', 'email')
  },
  {
    title: 'Challenges acheté(s)',
    key: 'challenges',
    width: 100,
    render: object => renderCell(object, 'TextCell', 'purchases')
  },
  /*
  {
    title: 'Vidéos',
    key: 'positionUnlocked',
    width: 40,
    render: object => renderCell(object, 'TextCell', 'positionUnlocked')
  },
  {
    title: "Date d'achat",
    key: 'purchaseDate',
    width: 100,
    render: object => renderCell(object, 'StarkDate', 'purchaseDate')
  },
  */
  {
    title: 'Derniere Connex.',
    key: 'lastConnectionDate',
    width: 100,
    render: object => renderCell(object, 'humanDate', 'lastConnectionDate')
  },
  {
    title: 'Actions',
    key: 'lastConnectionDate',
    width: 100,
    render: ''
  }
]
const sortColumns = [
  { ...columns[1] },
  { ...columns[3] },
  { ...columns[4] },
  { ...columns[5] },
  { ...columns[6] },
]
const tableinfos = [
  {
    title: 'Tableau de bord My Fiit Challenge',
    value: 'simple',
    columns: clone(sortColumns)
  }
]
export { columns, tableinfos }
