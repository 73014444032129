import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts'

import {
  Col, Row, Select
} from 'antd'

import { getToken } from '../../../../redux/token'
import { uri } from '../../../../uri'
import './stylesheet.scss'

const colors = [
  'rgba(33, 255, 96, 0.7)',
  'rgba(33, 117, 255, 0.7)',
  'rgba(174, 33, 255, 0.7)',
  'rgba(62, 33, 255, 0.7)',
  'rgba(33, 255, 75, 0.7)',
  'rgba(255, 181, 33, 0.7)',
  'rgba(20, 218, 255, 0.7)'
]

const Option = Select.Option

const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100
  },
]


class Stats extends Component {
  constructor(props) {
    super(props)

    this.state = {
      view: 'today',
      contentWidth: 0,
      startDate: new Date(),
      endDate: null,
      displayStartDate: null,
      displayEndDate: null,
      endOpen: false,
      visible: true,
      selectedMfcs: [],
      dataChart: [],
      purchases: [],
    }
  }

  componentWillMount() {
    const { getMfcs } = this.props
    getMfcs()
  }

  componentDidMount() {
    this.setState({ contentWidth: document.getElementsByClassName('isoContentMainLayout')[0].offsetWidth - 60 })
    this.updateStats()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mfcs) {
      const uniqMfcs = []

      nextProps.mfcs.forEach(mfc => uniqMfcs.push(mfc.name))
      this.setState({ dataChart: this.formatData(nextProps.mfcs, uniqMfcs) })
    }
  }

  updateStats = async () => {
    const { mfcs } = this.props
    const {
      view,
      selectedMfcs
    } = this.state
    const query = [`view=${view}`]

    /*
    if (startDate) {
      query.push(`startDate=${startDate.toISOString()}`)
    }
    */

    if (selectedMfcs.length) {
      const mfc = mfcs.find(m => m.name === selectedMfcs[0])

      if (mfc) {
        query.push(`mfcId=${mfc._id}`)
      }
    }

    const response = await axios.get(`${uri.uri.admin.purchases}?${query.join('&')}`, {
      headers: {
        Authorization: `Bearer ${getToken('mfc-token')}`
      }
    })

    this.setState({
      dataChart: response.data.map((item) => {
        let name = moment(item.date)

        switch (view) {
          case 'week': name = name.format('ddd DD')
            break
          case 'month': name = name.format('ddd DD')
            break
          case 'year': name = name.format('MMM')
            break
          default: name = name.format('HH[h]mm')
        }

        return {
          name,
          ...item
        }
      })
    })
  }

  formatData = (props, mfcs) => {
    const newData = []
    new Array('1','2','3','4','5','6').map((month, index) => {
      newData.push(
        { name: `Mois ${index + 1}` }
      )
    })

    this.setState({ selectedMfcs: mfcs })

    props.forEach((mfc) => {
      newData.forEach((d) => {
        d[mfc.name] = Math.floor(Math.random(348) * 345)
      })
    })

    return newData
  }

  handleChangeMfc = async (mfc) => {
    const { mfcs } = this.props
    this.setState({ dataChart: this.formatData(mfcs, [mfc.name]) })
  }


  handleChangeDates= (period) => {
    this.setState({
      startDate: new Date(period.toISOString())
    })
  }

  handleViewChange = view => () => this.setState({
    view
  }, this.updateStats)


  render() {
    const {
      view,
      contentWidth,
      selectedMfcs,
      dataChart
    } = this.state
    const { mfcs } = this.props

    return (
      <div className="mfc-stats">
        <div className="mfc-stats__menu">
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Select name="view" value={view} style={{ minWidth: 200, marginRight: 120 }}>
                {/*
                <Option value="today" onClick={() => this.handleChangeDates(moment().startOf('day'))}>Aujourd'hui</Option>
                <Option value="this_week" onClick={() => this.handleChangeDates(moment().startOf('week'))}>Cette semaine</Option>
                <Option value="last_week" onClick={() => this.handleChangeDates(moment().subtract(1, 'weeks').startOf('month'))}>La semaine dernière</Option>
                <Option value="current_month" onClick={() => this.handleChangeDates(moment().startOf('month'))}>Ce mois ci</Option>
                <Option value="last_three_months" onClick={() => this.handleChangeDates(moment().subtract(3, 'months').startOf('month'))}>Les 3 derniers mois</Option>
                <Option value="last_six_months" onClick={() => this.handleChangeDates(moment().subtract(6, 'months').startOf('month'))}>Les 6 derniers mois</Option>
                <Option value="all" onClick={() => this.handleChangeDates( moment().subtract(4, 'years').startOf('month'))}>Depuis le début</Option>
                */}
                <Option value="today" onClick={this.handleViewChange('today')}>Aujourd'hui</Option>
                <Option value="week" onClick={this.handleViewChange('week')}>Cette semaine</Option>
                <Option value="month" onClick={this.handleViewChange('month')}>Ce mois-ci</Option>
                <Option value="year" onClick={this.handleViewChange('year')}>Cette année</Option>
              </Select>
            </Col>
            <Col className="gutter-row" span={12}>
              <Select defaultValue="Tous challenges">
                {
                  mfcs && mfcs.map((k, i) => (
                    <Option key={`mfcs-${i}`} value={k._id} onClick={() => this.handleChangeMfc(k)}>{k.name}</Option>
                  ))
                }
              </Select>
            </Col>
          </Row>
        </div>

        <AreaChart
          width={contentWidth}
          height={400}
          data={dataChart}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0
          }}
        >

          <CartesianGrid strokeDasharray="3 3" />
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(33, 255, 96, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(33, 255, 96, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(33, 117, 255, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(33, 117, 255, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(174, 33, 255, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(174, 33, 255, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(62, 33, 255, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(62, 33, 255, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(33, 255, 75, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(33, 255, 75, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color6" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(255, 181, 33, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(255, 181, 33, 0.7)" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color7" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgba(20, 218, 255, 0.7)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="rgba(20, 218, 255, 0.7)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {
            selectedMfcs && selectedMfcs.map((mfc, index) => <Area key={mfc} type="monotone" dataKey={mfc} stroke={selectedMfcs.length === 1 ? colors[Math.floor(Math.random() * 7) + 1] : colors[index]} fill={selectedMfcs.length === 1 ? `url(#color${[Math.floor(Math.random() * 7) + 1]})` : `url(#color${index})`} />)
          }
        </AreaChart>
        <div>
          <pre>
            { /* JSON.stringify(dataChart) */ }
          </pre>
        </div>
      </div>
    )
  }
}

export default Stats
