import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './stylesheet.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBroadcastTower, faArrowRight, faRedoAlt, faPowerOff
} from '@fortawesome/free-solid-svg-icons'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import {
  Button, Form, Icon, Input, Modal
} from 'antd'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import { getMfcs } from '../../redux/admin/action'
import Loader from '../../components/loader'
import Modale from '../../components/modale'

library.add(faBroadcastTower, faArrowRight, faRedoAlt, faPowerOff)

class Voucher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      success: false,
      error: false,
      message: null,
      visibleModale: false
    }
  }

  componentWillMount() {
    const { refreshToken, account } = this.props
    !account && refreshToken()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.paiementSuccess) {
      this.setState({
        isLoading: false, error: false, success: true, message: nextProps.paiementSuccess.data
      })
    }
    if (nextProps.paiementError) {
      console.log('nextProps.paiementError', nextProps.paiementError)
      if (nextProps.paiementError === 'mfc_already_purchased') {
        this.setState({
          isLoading: false, error: true, success: false, visibleModale: true
        })
      } else {
        this.setState({ isLoading: false, error: true, success: false })
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  checkVoucher = () => {
    const { form } = this.props
    this.setState({ isLoading: true, error: false, success: false })
    const { purchaseVoucher } = this.props
    const voucherData = {
      code: form.getFieldValue('voucher'),
      firstName: form.getFieldValue('prenom'),
      lastName: form.getFieldValue('nom'),
      email: form.getFieldValue('email'),
    }
    setTimeout(() => {
      purchaseVoucher(voucherData.code, voucherData)
    }, 3000)
  }

  renderMessage = (by, challenge) => {
    return (
      <div>
        Ton code est validé, tu peux désormais rejoindre <span style={{ color: '#ff4e42', fontWeight: 'bold' }}>{by}</span> et acceder au <span style={{ color: '#ff4e42', fontWeight: 'bold' }}>{challenge || 'challenge'}</span>!
        <br />
        <br />
        tu vas recevoir un mail, (pense à consulter tes indésirables)
      </div>
    )
  }

  handleSubmit = (e) => {
    // this.setState({ visibleModale : false, error: false, success: false})
    window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)

    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        this.checkVoucher()
      }
    })
  }

  info = () => {
    return (

      <Modal
        visible
        title="Attention, à lire attentivement:"
        closable
        onCancel={() => this.setState({ visibleModale: false })}
        footer={[
          <Button key="submit" type="primary" onClick={() => this.setState({ visibleModale: false })}>
            fermer
          </Button>,
        ]}
      >
        <div className="checkout__modal">
          Tu as essayé de rentrer le code partenaire que tu as reçu par mail pour ton inscription.<br /><br />

          Celui-ci doit être partagé à ton partenaire. Ce code doit être utilisé uniquement par ton partenaire afin qu’il puisse rejoindre l’espace depuis son propre smartphone/ordinateur.
        </div>
      </Modal>
    )
  }


  render() {
    const { account } = this.props
    const { getFieldDecorator } = this.props.form
    const {
      isLoading, error, success, message, visibleModale
    } = this.state

    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/my-fiit-challenges" account={account} isBackground/>
        <div className="mfc">
          <div className="mfc__left">
            <div className="mfc__left__content mfc__left__content--join">
              { isLoading
              && (!success && !error)
              && <Loader noMargin message="Validation du code en cours..." />
              }
              { success
              && <Modale noMargin type="success" message={message && this.renderMessage(message.boughtBy, message.mfc.name)} goTo="/my-fiit-challenges" backUrl />
              }
              { error
              && !isLoading
              && (
              <Modale
                noMargin
                type="error"
                backUrl="/join-team"
                message={visibleModale ? 'Tu as essayé de rentrer le code partenaire que tu as reçu par mail pour ton inscription.\n'
                + '                    Celui-ci doit être partagé à ton partenaire. Ce code doit être utilisé uniquement par ton partenaire afin qu’il puisse rejoindre l’espace depuis son propre smartphone/ordinateur.' : 'Code invalide'}
                reload
              />
              )
              }
              {!isLoading && !success
              && (
                <React.Fragment>
                  <div className="mfc__left__content__user">
                    { account && (account.user.firstName || account.user.name) }
                  </div>
                  <div className="mfc__left__content__welcome-text">
                    Pour rejoindre ton partenaire, saisis le code qu'il a reçu par email et accède maintenant à ton challenge !
                  </div>
                </React.Fragment>
              )
              }
              {
                (!error && !success)
                && (
                <Form onSubmit={this.handleSubmit} className="login-form" style={{ opacity: isLoading ? 0 : 1 }}>
                  <Form.Item>
                    {getFieldDecorator('nom', {
                      rules: [{ required: true, message: 'Veuillez renseigner votre nom' }],
                    })(
                      <Input style={{ height: 50 }} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Nom" />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('prenom', {
                      rules: [{ required: true, message: 'Veuillez renseigner votre prénom' }],
                    })(
                      <Input style={{ height: 50 }} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Prenom" />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [{
                        required: true, message: 'Vous devez saisir votre adresse mail',
                      }, {
                        validator: this.validateToNextEmail,
                      }],
                    })(
                      <Input style={{ height: 50 }} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Email de votre choix" />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('confirmEmail', {
                      rules: [{
                        required: true, message: 'Vous devez confirmer votre adresse mail',
                      }, {
                        validator: this.compareToFirstEmail,
                      }],
                    })(
                      <Input style={{ height: 50 }} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Confirmation email" onBlur={this.handleConfirmBlur} />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('voucher', {
                      rules: [{ required: true, message: 'Vous devez renseigner le code reçu' }],
                    })(
                      <Input style={{ height: 50 }} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} disabled={isLoading} />} placeholder="Code partenaire" />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {!isLoading
                  && (
                    <div className="mfc__right__pdfs">
                      <button className="mfc__right__pdfs__link mfc__right__pdfs__link--mobile">
                        Valider code
                      </button>
                    </div>
                  )
                  }
                  </Form.Item>
                </Form>
                )
              }

            </div>
          </div>
        </div>
      </AppContainer>
    )
  }
}

export default connect(
  (state) => {
    return ({
      account: state.Facebook.account ? (state.Facebook.account.data || false) : false,
      mfcs: state.Admin.mfcs,
    })
  },
  {
    getMfcs
  }
)(withCookies(withRouter(Form.create({ name: 'voucher_purchase' })(Voucher))))
