import React, { Component } from 'react'
import '../breadcrumb/stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

library.add(faHome)

class BreadcrumbDesktop extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.goTo = this.goTo.bind(this)
  }

  componentWillMount() {}

  goTo = (link) => {
    link && this.props.history.push(link)
  }

  render() {
    const { items } = this.props
    return (
      <React.Fragment>
        <div className="breadcrumb__desktop">
          {
            items && items.map((i, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a style={{ cursor: i.link ? 'pointer' : 'default' }} onClick={() => this.goTo(i.link)} className="breadcrumb__desktop__item" key={i.label}>
                <div className={`breadcrumb__desktop__item__container ${index === items.length -1 ? 'breadcrumb__desktop__item__container--last' : ''}`}>
                  { i.icon && <div className="breadcrumb__desktop__item__container__icon"><FontAwesomeIcon icon={faHome} /></div> }
                  <div className="breadcrumb__desktop__item__container__label">{ i.label }</div>
                </div>
              </a>
            ))
          }
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(BreadcrumbDesktop)
