import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './stylesheet.scss'
import {
  Input,
  Button,
  Form,
  Tag
} from 'antd'

import LayoutContentWrapper from '../../../../../components/utility/layoutWrapper'

const editableField = [
  'description',
  'facebookUrl',
  'label',
  'title',
  'bigDescription'
]

class Update extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mfc: null,
      pdfList: [],
      planning: [],
      tmpSessionsNumber: ''
    }

    this.getDay = this.getDay.bind(this)
    this.getWeek = this.getWeek.bind(this)
    this.cleanObj = this.cleanObj.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mfc !== this.props.mfc) {
      this.setState({ mfc: nextProps.mfc })
      // const tmpPdfs = []
      // nextProps.mfc.pdfs.map(p => tmpPdfs.push({ ...p, available: true }))
      // this.setState({ pdfList: tmpPdfs })
    }
  }

  // alias majmyfiit='cd /home/stark/www/myfittchallenges && git pull origin master && yarn && yarn build && service myfiit restart && service nginx restart'
  /*
   alias majmyfiit='mv /etc/nginx/sites-available/maintenance.conf /etc/nginx/sites-enabled/ && mv /etc/nginx/sites-enabled/mfcnode.conf /etc/nginx/sites-available/ && service nginx restart && cd /home/stark/www/myfittchallenges && git pull origin master && yarn && yarn build && mv /etc/nginx/sites-enabled/maintenance.conf /etc/nginx/sites-available/ && mv /etc/nginx/sites-available/mfcnode.conf /etc/nginx/sites-enabled/ && service myfiit restart && service nginx restart'
   alias setmaintenance='mv /etc/nginx/sites-available/maintenance.conf /etc/nginx/sites-enabled/ && mv /etc/nginx/sites-enabled/mfcnode.conf /etc/nginx/sites-available/ && service nginx restart'
   alias unsetmaintenance='mv /etc/nginx/sites-enabled/maintenance.conf /etc/nginx/sites-available/ && mv /etc/nginx/sites-available/mfcnode.conf /etc/nginx/sites-enabled/  && service nginx restart'
   */

  componentWillMount() {
    const { getMfc, getMfcs } = this.props
    const { myfiit } = this.props.match.params
    getMfcs()
    getMfc(myfiit)
  }

  getWeek = (sessions) => {
    return sessions.length > 0 ? sessions[sessions.length - 1].day + 1 : 1
  }

  getDay = (sessions) => {
    const tmp = sessions.filter(s => s.week === this.getWeek(sessions)).length
    return tmp
  }


  formatName = (name, week, session, type) => {
    return (`${name}-S${(`0${week}`).slice(-2)}E${(`0${session}`).slice(-2)}-${type}.pdf`)
  }

  UpdateSessionsWithPdfs = () => {
    const { mfc } = this.props
    const tmpPdfs = []
    mfc.sessions.forEach((m) => {
      const pdfsArr = []
      if (m.day === 1) {
        pdfsArr.push(this.formatName(mfc.name, m.week, 0, 'NUTRI'))
        pdfsArr.push(this.formatName(mfc.name, m.week, m.day, 'HIIT'))
      } else {
        pdfsArr.push(this.formatName(mfc.name, m.week, m.day, 'HIIT'))
      }
      tmpPdfs.push(pdfsArr)
    })


    // this.setState({ mfc })
  }

  generateSessions = (courses) => {
    const { saveMfc, mfc } = this.props
    const currentMfc = { ...mfc }
    currentMfc.sessions = courses
    saveMfc(mfc._id, currentMfc)
  }

  updateField = (e, index, field, isSession) => {
    const { mfc } = this.state
    const tmpVal = mfc
    if (isSession) {
      tmpVal.sessions[index][field] = e.target.value
    } else {
      tmpVal[field] = e.target.value
    }
    this.setState({ mfc: tmpVal })
  }

  saveHiit = () => {
    const { mfc } = this.state
    const { saveMfc } = this.props
    saveMfc(mfc._id, this.cleanObj(mfc))
  }

  deleteSession = () => {
    const { mfc } = this.state
    const { saveMfc } = this.props
    saveMfc(mfc._id, this.cleanObj(mfc, 'sessions'))
  }

  cleanObj = (obj, other) => {
    const clean = obj
    delete clean.name
    delete clean.data
    delete clean.pdfs
    delete clean.__v
    delete clean.lastEditionDate
    if (other) {
      clean[other] = []
    }
    return clean
  }

  addWeek = () => {
    const { planning } = this.state
    const currentPlanning = [...planning]
    if (this.state.tmpSessionsNumber) {
      currentPlanning.push(this.state.tmpSessionsNumber)
      this.setState({ tmpSessionsNumber: '', planning: currentPlanning })
    }
  }

  generatePdfsFromScratch = () => {
    const { mfc, saveMfc } = this.props
    const newSessions = []
    const currentMfc = {...mfc}
    mfc && mfc.sessions && mfc.sessions.forEach((s) => {
      const pdfs = []
      if (s.day === 1) {
        pdfs.push(`MFC-${mfc.name.substring(4)}-S${(`0${s.week +1}`).slice(-2)}E${(`0${s.day}`).slice(-2)}-NUTRI.pdf`)
      }
      pdfs.push(`MFC-${mfc.name.substring(4)}-S${(`0${s.week +1}`).slice(-2)}E${(`0${s.day}`).slice(-2)}-HIIT.pdf`)
      newSessions.push({...s, pdfUrls: pdfs })
    })

    currentMfc.sessions = newSessions
    saveMfc(mfc._id, currentMfc)
  }

  generatePdfs = () => {
    const { mfc, saveMfc } = this.props
    const currentMfc = { ...mfc }
    const pdfs = []
    mfc && mfc.sessions && !!mfc.sessions.length && mfc.sessions.forEach((m) => {
      if (m.day === 1) {
        pdfs.push(
          {
            type: 'NUTRI',
            filename: m.pdfUrls[0],
            publicationDate: new Date(),
            week: m.week,
            session: 0,
            mfcId: mfc._id,
          }
        )
        pdfs.push(
          {
            type: 'HIIT',
            filename: m.pdfUrls[1],
            publicationDate: new Date(),
            week: m.week,
            session: 0,
            mfcId: mfc._id,
          }
        )
      } else {
        pdfs.push(
          {
            type: 'HIIT',
            filename: m.pdfUrls[0],
            publicationDate: new Date(),
            week: m.week,
            session: 0,
            mfcId: mfc._id,
          }
        )
      }
    })

    currentMfc.pdfs = pdfs
    saveMfc(mfc._id, currentMfc)
  }

  copyFromMfc = (source) => {
    const { mfc, saveMfc } = this.props
    const currentMfc = { ...mfc }
    const newSessions = []
    source && source.liveYoutubeVideos && source.liveYoutubeVideos.forEach((live) => {
      newSessions.push(
        {
          day: live.day,
          mail: '',
          pdfUrls: source && source.pdfs && source.pdfs.filter(pdf => pdf.session === live.day && pdf.week === live.week).map(m => m.filename) || ['non'],
          sessionsNumber: source.pdfs.find(pdf => pdf.session === live.day && pdf.week === live.week) ? source.pdfs.find(pdf => pdf.session === live.day && pdf.week === live.week).session : null,
          title: `HIIT ${live.day} - Semaine ${live.week + 1}`,
          videoUrl: live.youtubeId,
          week: live.week,
          sort: `${live.week}${live.day}`
        }
      )
    })
    newSessions.sort((a, b) => {
      return a.sort > b.sort ? 1 : -1
    })

    currentMfc.sessions = newSessions
    currentMfc.pdfs = source.pdfs
    saveMfc(mfc._id, currentMfc)
  }

  render() {
    const { mfc, planning } = this.state
    const { mfcs } = this.props

    const courses = []
    planning.forEach((p, week) => {
      [...Array(parseInt(p))].forEach((a, day) => {
        const pdfs = []
        if (day === 0) {
          pdfs.push(`MFC${mfc.edition} Replay-S${(`0${week + 1}`).slice(-2)}E${(`0${day + 1}`).slice(-2)}-NUTRI.pdf`)
        }
        pdfs.push(`MFC${mfc.edition} Replay-S${(`0${week + 1}`).slice(-2)}E${(`0${day + 1}`).slice(-2)}-HIIT.pdf`)


        courses.push({
          title: `HIIT ${day + 1} - Semaine ${week + 1}`,
          week: week + 1,
          day: day + 1,
          pdfUrls: pdfs,
          sessionsNumber: planning.length,
          videoUrl: 'test'
        })
      })
    })

    return (
      <LayoutContentWrapper>
        {mfc
        && (
          <div className="edit">
            <div className="edit__title">{mfc.name}</div>
            {
              Object.entries(mfc).map(([key, value], index) => (
                key !== 'sessions'
                && key !== 'data'
                && key !== 'pdfs'
                && (
                <div className="edit__row" key={index}>
                  <div className="edit__row__left">{key}</div>
                  <div className="edit__row__right">
                    { editableField.includes(key) ? (
                      <textarea style={{ width: '100%', height: '80px' }} value={mfc[key]} onChange={e => this.updateField(e, index, key)} />
                    )
                      : (value === true ? 'yes' : (value || 'no'))}
                  </div>
                </div>
                )
              ))
            }
            <div className="edit__row">
              <div className="edit__row__left">Vidéos</div>
              <div className="edit__row__right">
                <div className="edit__row__right__container">
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                      <div>Semaine 1: <input type="number" value={planning[0] || this.state.tmpSessionsNumber} onChange={e => this.setState({ tmpSessionsNumber: e.target.value })} /></div>
                      {
                        planning && !!planning.length && planning.map((p, index) => (
                          <div>Semaine {index + 2}: <input type="number" disabled={index !== planning.length - 1} value={(index === (planning.length - 1)) ? this.state.tmpSessionsNumber : planning[index + 1]} onChange={e => this.setState({ tmpSessionsNumber: e.target.value })} /></div>
                        ))
                      }
                    </div>
                    <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                      <Button onClick={() => this.addWeek()}>Add week</Button>
                      <Button type="primary" onClick={() => this.generateSessions(courses)}>Generate</Button>
                    </div>
                  </div>

                  { /* <Input style={{ width: 100, marginRight: 15 }} placeholder="nb" type="number" onChange={e => this.setState({ sessionsNumber: e.target.value })} /> */ }


                  {
                    mfc && mfc.sessions.map((k, index) => (
                      <div className="edit__row__right__container__rows" key={k + index}>
                        <div>{`#${index + 1}`}</div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Titre</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.title} value={k.title} onChange={e => this.updateField(e, index, 'title', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Jour</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.day} value={k.day} onChange={e => this.updateField(e, index, 'day', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Semaine</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.week} value={k.week} onChange={e => this.updateField(e, index, 'week', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Email</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.mail} value={k.mail} onChange={e => this.updateField(e, index, 'mail', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Titre mail</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.mailTitle} value={k.mailTitle} onChange={e => this.updateField(e, index, 'mailTitle', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Url video</div>
                              <div className="edit__row__right__container__rows__row__align__right"><Input placeholder={k.videoUrl} value={k.videoUrl} onChange={e => this.updateField(e, index, 'videoUrl', true)} /></div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="edit__row__right__container__rows__row">
                          <Form.Item style={{ width: '100%' }}>
                            <div className="edit__row__right__container__rows__row__align">
                              <div className="edit__row__right__container__rows__row__align__left">Pdfs</div>
                              <div className="edit__row__right__container__rows__row__align__right">
                                { k.pdfUrls && k.pdfUrls.map((k, idx) => (
                                  <Tag
                                    key={`date-${idx}`}
                                  >
                                    {k}
                                  </Tag>
                                ))}
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    ))
                }
                  <div>
                    <div>
                      <div style={{ marginBottom: 5 }}><Button type="success" onClick={() => this.saveHiit()}>Save challenge</Button></div>
                      { /* <Button type="warning" onClick={() => this.UpdateSessionsWithPdfs()}>Generate pdf</Button> */}
                      <div style={{ marginBottom: 5 }}><Button type="danger" onClick={() => this.deleteSession()}>Supprimer sessions</Button></div>
                      <div style={{ marginBottom: 5 }}><Button type="danger" onClick={() => this.generatePdfs()}>Generate Pdfs</Button></div>
                      <div style={{ marginBottom: 5 }}><Button type="danger" onClick={() => this.generatePdfsFromScratch()}>Generate Pdfs From scratch</Button></div>
                    </div>
                    <div>
                      {
                        mfcs && mfcs.map(mfc => (
                          <div style={{ marginBottom: 5 }}>
                            <Button type="danger" onClick={() => this.copyFromMfc(mfc)}>Copy sessions from {mfc.name} { mfc.isReplay ? 'replay' : 'live'}</Button>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </LayoutContentWrapper>
    )
  }
}


export default withRouter(Update)
