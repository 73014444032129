import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'

library.add(faCheckCircle, faTimesCircle)

class Modale extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {}

  render() {
    const {
      type, message, backUrl, reload, goTo, noShadow, buttonLabel, noMargin
    } = this.props

    return (
      <div className={`modale-box ${noShadow ? 'modale-box--no-shadow' : ''} ${noMargin ? 'modale-box--no-margin' : ''}`}>
        <div className={`modale-box__icon ${type === 'error' ? 'modale-box__icon--error' : ''}`}>
          <FontAwesomeIcon icon={type === 'error' ? faTimesCircle : faCheckCircle} />
        </div>
        <div className="modale-box__message">
          { message }
        </div>
        {(type !== 'error' || reload || goTo)
        && (
        <div className="modale-box__back">
          {
            reload
              ? <Button type="primary" onClick={() => window.location.reload()}>Recommencer</Button>
              : goTo ? <Button type="primary" onClick={() => window.location.href = goTo}>Ok</Button> : (
                <Link to={backUrl}>
                  <Button type="primary">{ buttonLabel }</Button>
                </Link>
              )
          }
        </div>
        )
        }
      </div>
    )
  }
}

export default Modale
