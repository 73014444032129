import App from './app/reducer'
import Admin from './admin/reducer'
import Facebook from './auth/reducer'
import AvailablePdfs from './pdf/reducer'
import Purchases from './purchases/reducer'
import Hiit from './hiit/reducer'

export default {
  App,
  Admin,
  Facebook,
  AvailablePdfs,
  Purchases,
  Hiit,
}
