import React, { Component } from 'react'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons'
import * as silhouette from '../../../view/image/silhouette.png'
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import {Link} from "react-router-dom";

library.add(faBroadcastTower)

class Breadcrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    const { icon, title, italic, account } = this.props
    return (
      <React.Fragment>
        <div className="breadcrumb">
            <div className="breadcrumb__avatar">
                <img src={(account && account.user && account.user.avatar) || silhouette} />
            </div>
            <div className="breadcrumb__user">
                { account && account.user && account.user.name }
            </div>
            <div className="breadcrumb__help">
                Se connecter à un autre compte
            </div>
            <Link to="/help" className="breadcrumb__icon">
                <FontAwesomeIcon icon={faQuestionCircle} />
            </Link>
        </div>
      </React.Fragment>
    )
  }
}

export default Breadcrumb
