import React, { Component } from 'react'
import '../complements/stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleRight,
    faArrowCircleLeft
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'
import step from '../../image/step.png'

library.add(faArrowCircleRight, faArrowCircleLeft)

class Accessories extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  componentWillMount() {
    const { refreshToken, account } = this.props
    if(!account) {
      refreshToken()
    }
  }

  render() {
    const items = [
      {
        icon: 'fa-home',
        label: 'Mes programmes',
        link: '/my-fiit-challenges'
      },
      {
        icon: false,
        label: this.props.match.params.mfc,
        link: `/my-fiit-challenge/${this.props.match.params.mfc}`
      },
      {
        icon: false,
        label: 'Matériel conseillé',
        link: false
      }
    ]

    const { account } = this.props

    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/my-fiit-challenges" goBackNav goBackLabel={"Mes programmes"} isBackground account={account} />
        { /* <Breadcrumb title='Matériel conseillé' /> */ }
        <div className="mfc mfc--no-padding mfc--live mfc--presentation">
          <div className="mfc__left">
            <div className="mfc__left__content">
              <BreadcrumbDesktop items={items} />
              <div style={{marginTop:15}}>
                <a className="complements-part__head__back" href={`/my-fiit-challenge/${this.props.match.params.mfc}`}>Retour</a>
              </div>
              <div className="mfc__left__content__global">
                <div className="complements-part">
                  <div className="complements-part__head">Matériel conseillé</div>
                  <div className="complements-part__title">Nos conseils pour bien acheter les haltères (dont vous avez besoin à partir de la semaine 3 du challenge) :</div>

                  <div>
                    <FontAwesomeIcon icon={faArrowCircleRight} className="red-halteres" />
                    Haltères Femme léger : 3kg, Femme moyen : 5kg, Femme lourd : 7,5kg
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faArrowCircleRight} className="red-halteres" />
                    Haltères Homme léger : 5kg, Homme moyen: 7,5kg, Homme lourd : 10kg
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faArrowCircleRight} className="red-halteres" />
                    Kit haltères 2 à 20kg pour Femme ou Homme
                  </div>
                  <a className="complements-part__row pointer" rel="noopener noreferrer" href="http://tidd.ly/4361015c" target="_blank" style={{marginTop: 50}}>
                    
                    <span className="complements-part__row__link complements-part__row__link--black">Step:</span>
                    <a href className="complements-part__row__img">
                      <img alt="mfc-step" src={step} />
                    </a>
                    <span className="complements-part__row__link center">Cliquez ici</span>
                    
                  </a>
                  <div className="complements-part__row complements-part__row__sign">Justine et Thibault.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    )
  }
}

export default withRouter(Accessories)
