const defaultState = {
  account: null,
  redirect: false,
  expiredSession: false
}

export default function Facebook(state = defaultState, action) {
  switch (action.type) {
    case 'CONNECT': {
      return {
        ...state,
        account: action.data.status === 'connected' ? action.data.user : false,
        expiredSession: false
      }
    }
    case 'GET_ACCOUNT': {
      return {
        ...state,
        expiredSession: false
      }
    }
    case 'GET_ACCOUNT_SUCCESS': {
      return {
        ...state,
        account: action.data,
        redirect: action.redirect,
        expiredSession: false
      }
    }
    case 'GET_ACCOUNT_FAIL': {
      return {
        ...state,
        account: null,
        expiredSession: false,
      }
    }
    case 'EXPIRED_SESSION': {
      return {
        ...state,
        account: null,
        expiredSession: true
      }
    }

    default: return state
  }
}
