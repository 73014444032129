import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { instanceOf, PropTypes } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

class RestrictedRoute extends React.Component {
  constructor(props) {
    super(props)
    this.checkAuth = this.checkAuth.bind(this)
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
    view: PropTypes.element.isRequired
  };

  checkAuth = () => {
    const { cookies, account } = this.props
    if (account) {
      return true
    }
    if (cookies.get('mfc-token')) {
      return true
    }
    return false
  }

  render() {
    const {
      view,
      ...rest
    } = this.props

    return (
      <Route
        {...rest}
        render={props => (this.checkAuth()
          ? view
          : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        )}
      />
    )
  }
}

export default withCookies(RestrictedRoute)
