import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import Tabs, { TabPane } from '../../../../components/uielements/tabs'
import LayoutContentWrapper from '../../../../components/utility/layoutWrapper'
import TableDemoStyle from './demo.style'
import fakeData from '../fakeData'
import { tableinfos } from './configs'
import Users from './users/users'
import SearchBox from '../../../../components/searchbox'

import {
  setUserPage,
  getMfcs,
  editUser,
  giveAccess,
  editMail,
  editName,
  deleteAccount,
  findUser,
  getUserInfo
} from '../../../../redux/admin/action'

class AntTable extends Component {
  componentDidMount() {
    const { findUser, queryParam } = this.props
    findUser({...queryParam})
  }

  renderTable(tableInfo, users) {
    const { setUserPage, userPage, findUser, editUser, successUserUpdate, getMfcs, mfcs, giveAccess, deleteAccount, queryParam, getUserInfo, userInfo, editMail, editName } = this.props
    return (
      <Users
        tableInfo={tableInfo}
        queryParam={queryParam}
        dataList={users}
        userInfo={userInfo}
        getUserInfo={getUserInfo}
        editUser={editUser}
        setUserPage={setUserPage}
        userPage={userPage}
        findUser={findUser}
        mfcs={mfcs}
        getMfcs={getMfcs}
        giveAccess={giveAccess}
        editMail={editMail}
        editName={editName}
        deleteAccount={deleteAccount}
        successUserUpdate={successUserUpdate}
      />
    )
  }

  render() {
    const { findUser, users, userPage, getMfcs, mfcs, queryParam } = this.props
    return (
      <LayoutContentWrapper>
        <TableDemoStyle className="isoLayoutContent">
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <SearchBox onSearch={findUser} userPage={userPage} getMfcs={getMfcs} mfcs={mfcs} queryParam={queryParam} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <Tabs className="isoTableDisplayTab">
                {tableinfos.map(tableInfo => (
                  <TabPane tab={tableInfo.title} key={tableInfo.value}>
                    {this.renderTable(tableInfo, users)}
                  </TabPane>
                ))}
              </Tabs>
            </Col>
          </Row>
        </TableDemoStyle>
      </LayoutContentWrapper>
    )
  }
}

export { tableinfos }

const mapStateToProps = state => ({
  users: state.Admin.users,
  queryParam: state.Admin.queryParam,
  mfcs: state.Admin.mfcs,
  userInfo: state.Admin.userInfo,
  successUserUpdate: state.Admin.successUserUpdate,
  userPage: state.Admin.userPage
})


export default connect(mapStateToProps, {
  findUser,
  getMfcs,
  editUser,
  giveAccess,
  editMail,
  editName,
  deleteAccount,
  getUserInfo,
  setUserPage
})(AntTable)
