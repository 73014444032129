import React, { Component } from 'react'
import './stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'

library.add(faArrowCircleRight)

class Complements extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentWillMount() {
        const { refreshToken, account } = this.props
        if(!account) {
            refreshToken()
        }
    }

    render() {
        const items = [
            {
                icon: 'fa-home',
                label: 'Accueil',
                link: '/my-fiit-challenges'
            },
            {
                icon: false,
                label: this.props.match.params.mfc,
                link: `/my-fiit-challenge/${this.props.match.params.mfc}`
            },
            {
                icon: false,
                label: 'Compléments alimentaires',
                link: false
            }
        ]

        const { account } = this.props
        return (
            <AppContainer>
                <Header icon="power-off" navigationRight="/my-fiit-challenges" goBackNav goBackLabel="Mes programmes" isBackground account={account} />
                {/* <Breadcrumb title="Compléments alimentaires" /> */ }
                <div className="mfc mfc--no-padding mfc--live mfc--presentation mfc--presentation--small">
                    <div className="mfc__left">
                        <div className="mfc__left__content">
                            <BreadcrumbDesktop items={items} />
                            <div style={{ marginTop: 15 }}>
                                <a className="complements-part__head__back" href={`/my-fiit-challenge/${this.props.match.params.mfc}`}>Retour</a>
                            </div>
                            <div className="mfc__left__content__global">
                                <div className="complements-part">
                                    <div className="complements-part__head">Myprotein</div>
                                    <div>-20% MINIMUM CODE: <b>JUSTINEG</b></div>
                                    <div className="complements-part__disclamer">« Pense à télécharger ton pack de démarrage avec fiches détaillées et toutes les explications pour chaque complément. »</div>
                                    <div className="complements-part__title">Nos conseils personnalisés pour brûler du gras de manière encore plus efficace:</div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>CLEAR WHEY ISOLATE PÊCHE</span>
                                        </div>
                                        La clear whey isolate est une whey de haute qualité extrêmement bien filtrée ! Elle se prend dans un shaker avec de l'eau entre les repas pour compléter vos apports en protéines. Elle est très digeste, ne fait de grumeaux et a un gout délicieux. Idéal donc pour une consommation quotidienne. Nous vous recommandons le gout pêche mais de nombreuses saveurs sont disponibles sur le site.
                                        <a href="http://tidd.ly/1d97fed1" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>250g de BCAA GRENADINE</span>
                                        </div>
                                        En version 2.1.1 ou 4.1.1, sous forme de poudre (nous vous recommandons le goût grenadine de la version 2.1.1) : C’est la boisson à consommer pendant votre entrainement. Mélangez environ 5 à 10g avec de l'eau dans votre shaker et consommez les tout au long de votre training (vous avez un doseur à l'intérieur de votre paquet pour vous aider dans le dosage).
                                        <a href="http://tidd.ly/3890f0be" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>250 gélules d'Omégas 3</span>
                                        </div>
                                        L'oméga-3 est un acide gras essentiel. Sa répartition est déséquilibré dans le corps, se complémenter permet de retrouver un équilibre optimal, d'améliorer sa performance et le dessin de la silhouette, de favoriser une bonne récupération et d'améliorer sa santé. Prenez simplement environ 2 gélules tous les jours au cours des repas.
                                        <a href="http://tidd.ly/40034b81" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>Beurre de cacahuète</span>
                                        </div>
                                        Idéal pour les recettes, protéiné naturellement et parfait pour vos petit déjeuné ! On tenait à vous proposer aussi la gamme alimentation présente chez Myprotein dont le beurre de cacahuète (d’autre beurre d’oléagineux sont disponibles sur le site) A consommer avec modération toute fois.
                                        <a href="http://tidd.ly/1814e5d2" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>1 Shaker</span>
                                        </div>
                                        Te servira pendant ton entrainement pour t'hydrater et pour tes collations.
                                        <a href="http://tidd.ly/226f9e1d" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="inline-flex">
                                        <div className="complements-part__row">
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                            <span>1 serviette</span>
                                        </div>
                                        Te servira pendant ton entrainement pour absorber la transpiration.
                                        <a href="http://tidd.ly/7348c92e" rel="noopener noreferrer" target="_blank">Clique ici</a>
                                    </div>
                                    <div className="complements-part__row complements-part__row__sign">Justine & Thibault.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppContainer>
        )
    }
}

export default withRouter(Complements)
