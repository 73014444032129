import axios from 'axios'
import { uri } from '../../uri'
import { dispatchRequest } from '../helpers'
import { getToken } from '../token'

axios.defaults.withCredentials = true
const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export const purchaseMfc = params => (dispatch) => {
  axios.post(`${uri.uri.user.purchase}`, { ...params }, getConfig()).then((response) => {
    dispatch({
      type: 'PURCHASE_MFC',
    })

    if (response.data.statusCode) {
      dispatch({
        type: 'PURCHASE_MFC_ERROR',
        data: response
      })
    } else {
      dispatch({
        type: 'PURCHASE_MFC_SUCCESS',
        data: response
      })
    }
  }).catch((error) => {
    dispatch({
      type: 'PURCHASE_MFC_ERROR',
      data: error
    })
  })
}
export const purchaseVoucher = (code, data) => (dispatch) => {
  axios.post(`${uri.uri.user.voucher}/${code}`, { ...data }, getConfig()).then((response) => {
    dispatch({
      type: 'PURCHASE_MFC_VOUCHER',
    })

    dispatch({
      type: 'PURCHASE_MFC_VOUCHER_SUCCESS',
      data: response
    })
  }).catch((error) => {
    dispatch({
      type: 'PURCHASE_MFC_VOUCHER_ERROR',
      data: error
    })
  })
}
