import React, { Component } from 'react'
import './stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCheckCircle,
  faInfoCircle, faPlusCircle,
  faStopwatch
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Button, Modal } from 'antd'
import Header from '../../components/header'
import Breadcrumb from '../../components/breadcrumb'
import AppContainer from '../../containers/AppContainer'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'
import { renderPrice } from '../../helpers/utility'
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";

library.add(faStopwatch)

class Presentation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleModale: false
    }
  }

  componentWillMount() {
    const { getMfcByName, refreshToken, account } = this.props
    const { edition } = this.props.match.params
    getMfcByName(edition)
    !account && refreshToken()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  info = () => {
    // const { isPack } = this.state
    return (

      <Modal
        visible
        title="Dans quel cas opter pour 2 places?"
        closable
        onCancel={() => this.setState({ visibleModale: false })}
        footer={[
          <Button key="submit" type="primary" onClick={() => this.setState({ visibleModale: false })}>
            fermer
          </Button>,
        ]}
      >
        <div className="checkout__modal">
          Uniquement si vous souhaitez suivre le programme séparément sur 2 écrans/ 2 comptes facebook différents.<br /><br />
          Le contenu du programme étant identique pour chaque participant, 2 places ne sont pas nécessaires si vous effectuez toutes les séances ensemble sur le même écran au sein d'un même foyer.<br /><br />
          Si vous souhaitez suivre le challenge chacun de votre côté alors les 2 places permettent :<br /><br />
          <div className="checkout__modal__bold">
            - d’obtenir 2 accès au groupe Facebook pour commenter chacun avec son propre compte.<br />
            - d’effectuer les séances à distance ensemble au même moment pour plus de motivation.<br />
            - de réduire le prix d’accès avec un tarif encore plus avantageux.
          </div>
        </div>
      </Modal>
    )
  }

  renderPromoPrice = (price) => {
    const roundedPrice = `${String(Math.floor(price)).substr(0, 2)}0 %`
    return (
      <div className="calculated-price" title={`${price.toFixed(2)} %`}>
        { roundedPrice }
      </div>
    )
  }

  setMessageModal = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ visibleModale: true })
  }

  render() {
    const { mfc, account } = this.props
    const { visibleModale } = this.state
    const items = [
      {
        icon: 'fa-home',
        label: 'Accueil',
        link: '/my-fiit-challenges'
      },
      {
        icon: false,
        label: this.props.match.params.edition,
        link: false
      }
    ]


    return (
      <AppContainer>
        <Header icon="power-off" navigationRight="/my-fiit-challenges" isBackground account={account} />
        { visibleModale && this.info() }
        {
          mfc && (
            <div className="mfc mfc--no-padding mfc--live mfc--presentation">
              <div className="mfc__left">
                <div className="mfc__left__content">
                  <div className="mfc__left__content__img">
                    { /*  <img src={justhibaultsquare} /> */ }
                  </div>
                  <BreadcrumbDesktop items={items} />
                  {/* <div className="mfc__left__content__title">
                    { mfc.title }
                  </div>
                  <div className="mfc__left__content__text">
                    { mfc.bigDescription }
                  </div> */}
                  {/* !mfc.isReplay && (
                  <div>
                    <div className="mfc__row__container__join" onClick={() => this.props.history.push('join-team')} onClick={() => this.props.history.push('join-team')}>
                        <div className="mfc__row__container__join__left">
                          <img src={doublefkex} />
                        </div>
                        <div className="mfc__row__container__join__middle">
                          <div className="mfc__row__container__join__middle__top">
                            Ton Partenaire t'a envoyé un CODE pour une inscription en DUO?
                          </div>
                          <div className="mfc__row__container__join__middle__bottom">
                            à rentrer ici uniquement par la personne qui n'a pas effectué l'achat
                          </div>
                        </div>
                        <div className="mfc__row__container__join__right">
                          <img src={edit} />
                        </div>
                      </div>
                  </div>) */
                  }
                  <div className="mfc__left__content__global">

                    {
                      /* mfc.isPromo && (
                          <div className="mfc__left__content__global__promo">
                            <div className="mfc__left__content__global__promo__title">
                              Offre de lancement
                            </div>
                            <div className="mfc__left__content__global__promo__row">
                              <FontAwesomeIcon icon={faCheck} /> Contenant tous les bonus
                            </div>
                          </div>
                      ) */
                    }

                    {/* <div className={`mfc__left__content__global__double ${!mfc.isDuo ? 'mfc__left__content__global__double--alone' : ''}`}>
                      {
                        !mfc.isDuo ? (
                            <div style={{ cursor: 'pointer' }} className="mfc__left__content__global__double__left" onClick={() => this.props.history.push(`/purchase/${mfc.name}/${mfc._id}`)}>
                              <div className="mfc__left__content__global__double__left__top">{mfc.name}</div>
                              <div className="mfc__left__content__global__double__left__middle">
                                {(mfc.oldPrice && mfc.oldPrice !== mfc.price) && (<div className="mfc__left__content__global__double__left__middle__old"><div className="mfc__left__content__global__double__left__middle--dash" /> {renderPrice(mfc.oldPrice)} €</div>)}
                                <div className={`mfc__left__content__global__double__left__middle__new ${(mfc.oldPrice && mfc.oldPrice === mfc.price) ? 'mfc__left__content__global__double__left__middle__new--center' : ''}`}>{renderPrice(mfc.price)} €</div>
                              </div>
                              <div className="mfc__left__content__global__double__left__bottom">
                                S'inscrire <FontAwesomeIcon icon="arrow-right" className="" />
                              </div>
                            </div>
                        ) : (
                            <>
                              <div style={{ cursor: 'pointer' }} className="mfc__left__content__global__double__left" onClick={() => this.props.history.push(`/purchase/${mfc.name}/${mfc._id}`)}>
                                <div className="mfc__left__content__global__double__left__top">EN SOLO</div>
                                <div className="mfc__left__content__global__double__left__content">
                                  <div className="mfc__left__content__global__double__left__content__label">
                                    Je prends 1 place!
                                  </div>
                                  <div className="mfc__left__content__global__double__left__content__price"> {renderPrice(mfc.price)} €</div>
                                  {
                                    mfc.price !== mfc.oldPrice && (
                                      <div className="mfc__left__content__global__double__left__content__text">
                                        Au lieu de <span>{ renderPrice(mfc.oldPrice) } €<div className="mfc__left__content__global__double__left__content__text__del" /></span>
                                        <div className="mfc__left__content__global__double__left__content__text__del" />
                                      </div>
                                    )}
                                </div>
                                {
                                  mfc.price !== mfc.oldPrice ? (
                                    <div className="mfc__left__content__global__double__left__bottom">
                                      {this.renderPromoPrice(((renderPrice(mfc.price) / renderPrice(mfc.oldPrice)) * 100) - 100)}
                                    </div>) : (
                                  <div className="mfc__left__content__global__double__left__bottom">
                                  Acheter
                                  </div>
                                  )
                                }
                              </div>

                              <div style={{ cursor: 'pointer' }} className="mfc__left__content__global__double__right" onClick={() => this.props.history.push(`/purchase/${mfc.name}/${mfc._id}/duo`)}>
                                <div className="mfc__left__content__global__double__left__top">
                                  EN DUO
                                  <div className="mfc__left__content__global__double__left__content__info" onClick={(e) => this.setMessageModal(e)}>
                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} /> En savoir plus
                                  </div>
                                </div>
                                <div className="mfc__left__content__global__double__left__content">
                                  <div className="mfc__left__content__global__double__left__content__label">
                                    Je prends 2 places et partage mon challenge avec 1 ami!
                                  </div>
                                  <div className="mfc__left__content__global__double__left__content__price"> {renderPrice(mfc.duoPrice)} €</div>
                                  {
                                    mfc.duoPrice !== mfc.oldDuoPrice && (
                                      <div className="mfc__left__content__global__double__left__content__text">
                                        Au lieu de <span>{ renderPrice(mfc.oldDuoPrice) } €<div className="mfc__left__content__global__double__left__content__text__del" /></span>
                                      </div>
                                    )
                                  }
                                </div>
                                {
                                  mfc.duoPrice !== mfc.oldDuoPrice ? (
                                    <div className="mfc__left__content__global__double__left__bottom">
                                      { this.renderPromoPrice(((renderPrice(mfc.duoPrice) / renderPrice(mfc.oldDuoPrice)) * 100) - 100) }
                                    </div>
                                  ) : (
                                    <div className="mfc__left__content__global__double__left__bottom">
                                     Acheter
                                    </div>
                                  )
                                }
                              </div>
                          </>
                        )

                    </div> */}
                    <div className="mfc__left__content__global__presentation">
                      <div className="mfc__left__content__global__presentation__title">
                        { mfc.name }
                      </div>
                      {
                        (mfc && mfc.edition !== 55) && (
                          <div className="mfc__left__content__global__presentation__edition">
                            version {!mfc.isReplay ? 'LIVE' : ' REPLAY'}
                          </div>
                        )
                      }
                      <div className="mfc__left__content__global__presentation__versions">
                        <div className="mfc__left__content__global__presentation__versions__version">
                          <div className="mfc__left__content__global__presentation__versions__version__place">
                            <FontAwesomeIcon icon={faUser} /> 1 place
                          </div>
                          <div className="mfc__left__content__global__presentation__versions__version__prices">
                            { mfc.oldPrice !== mfc.price && <div className="mfc__left__content__global__presentation__versions__version__prices__old">{mfc.oldPrice / 100}€</div>}
                            <div className="mfc__left__content__global__presentation__versions__version__prices__new">{mfc.price / 100}€</div>
                          </div>
                          <div className="mfc__left__content__global__presentation__versions__version__descriptions">
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Tarif tout compris pour 6 semaines
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Comprenant tous les bonus
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Accès à vie / sans abonnement
                            </div>

                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__button" onClick={() => this.props.history.push(`/purchase/${mfc.name}/${mfc._id}`)}>
                              ACHETER
                            </div>
                            { mfc.oldPrice !== mfc.price && <div className="mfc__left__content__global__presentation__versions__version__descriptions__limited">
                              Offre limitée !
                            </div> }
                          </div>
                        </div>
                        <div className="mfc__left__content__global__presentation__versions__version">
                          <div className="mfc__left__content__global__presentation__versions__version__place">
                            <FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /> 2 places <FontAwesomeIcon className="mfc__left__content__global__presentation__versions__version__place__icon" icon={faQuestionCircle} onClick={(e) => this.setMessageModal(e)} />
                          </div>
                          <div className="mfc__left__content__global__presentation__versions__version__prices">
                            { mfc.oldDuoPrice !== mfc.duoPrice && <div className="mfc__left__content__global__presentation__versions__version__prices__old">{mfc.oldDuoPrice / 100}€</div>}
                            <div className="mfc__left__content__global__presentation__versions__version__prices__new">{mfc.duoPrice / 100}€</div>
                          </div>
                          <div className="mfc__left__content__global__presentation__versions__version__descriptions">
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Soit { ((mfc.duoPrice / 100) / 2).toFixed(2).replace('.00','') }€ par personne
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Tarif tout compris pour 6 semaines
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Comprenant tous les bonus
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__point">
                              - Accès à vie / sans abonnement
                            </div>
                            <div className="mfc__left__content__global__presentation__versions__version__descriptions__button" onClick={() => this.props.history.push(`/purchase/${mfc.name}/${mfc._id}/double`)}>
                              ACHETER
                            </div>
                            { mfc.oldDuoPrice !== mfc.duoPrice && <div className="mfc__left__content__global__presentation__versions__version__descriptions__limited">
                              Offre limitée !
                            </div> }
                          </div>
                        </div>
                      </div>
                      <div className="mfc__left__content__global__presentation__help" onClick={(e) => this.setMessageModal(e)}>
                        <div className="mfc__left__content__global__presentation__help__left">
                          Dans quel cas opter pour 2 places?
                        </div>
                        <div className="mfc__left__content__global__presentation__help__right">
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </AppContainer>
    )
  }
}

export default withRouter(Presentation)
