import React, { Component } from 'react'
import {
  List,
  Card,
  Input,
  Button,
  Icon,
  Form,
  notification,
  Checkbox,
  Select
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import LayoutContentWrapper from '../../../../../components/utility/layoutWrapper'
import TableDemoStyle from '../../dashboard/demo.style'
import './stylesheet.scss'

const Option = Select.Option

class Pricing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unlock: -1,
      lock: false,
      mfc: null,
      mfcsObj: null,
      rules: []
    }


    this.cleanObj = this.cleanObj.bind(this)
  }

  componentWillMount() {
    const { getMfcs } = this.props
    getMfcs()
  }

  handleSubmit = (e) => {
    const { createPrice } = this.props
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        const params = { ...values }
        createPrice(params)
      }
    })
  }

  cleanObj = (obj, other, other2) => {
    const clean = obj
    delete clean.name
    delete clean.data
    delete clean.__v
    delete clean.tmpOldPrice
    delete clean.tmpOldDuoPrice
    delete clean.tmpDuoPrice
    delete clean.addOldDuo
    delete clean.editOldDuo
    delete clean.editDuoOld
    delete clean.editDuo
    delete clean.tmpPrice
    delete clean.edit
    delete clean.lastEditionDate
    delete clean.duoprice
    delete clean.Duoprice
    delete clean.oldprice
    delete clean.Oldprice
    delete clean.Oldduoprice

    clean.bigDescription = obj.bigDescription || ''
    clean.title = obj.title || ''
    clean.label = obj.label || ''
    if (!clean.sessions.length) {
      clean.sessions = [{}]
    }

    if (other) {
      clean[other] = []
    }
    if (other2) {
      clean[other2] = []
    }
    return clean
  }

  componentWillReceiveProps(nextProps) {
    const { getMfcs } = nextProps


    if (nextProps.mfcs) {
      this.setState({ mfcsObj: nextProps.mfcs })
    }
    if (nextProps.createSuccess && (nextProps.createSuccess !== this.props.createSuccess)) {
      notification.success({
        message: 'Le My Fiit a bien été ajouté',
        description: 'Tout s\'est bien passé.',
      })
      getMfcs()
    }

    if (nextProps.deleted && (nextProps.deleted !== this.props.deleted)) {
      notification.success({
        message: 'Le My Fiit a bien été supprimé',
        description: 'Tout s\'est bien passé.',
      })
      getMfcs()
    }
    if (nextProps.edited === undefined) {
      this.setState({ lock: true })
      if (!this.state.lock) {
        notification.success({
          message: 'Le My Fiit a bien été modifié',
          description: 'Tout s\'est bien passé.',
        })
      }
      setTimeout(() => {
        this.setState({ lock: false, mfc: null, unlock: -1 })
        getMfcs()
      }, 1000)
    }
  }

  updateMfcField = (mfc, field, e, isCheckbox, isString) => {
    const currentMfc = { ...this.state.mfcsObj }
    currentMfc[mfc][field] = isCheckbox ? e.target.checked : (isString ? e.target.value : parseInt(e.target.value))
    this.setState({ mfcsObj: currentMfc })
  }

  updateRules = (mfc, index, e) => {
    const currentMfc = { ...this.state.mfcsObj }
    currentMfc[mfc].rules[index] = e.target.value
    this.setState({ mfcsObj: currentMfc })
  }

  savePrice = (index) => {
    const { mfcsObj } = this.state
    if (mfcsObj[index]) {
      const currentMfc = { ...mfcsObj[index] }
      this.props.saveMfc(currentMfc._id, currentMfc)
    }
  }

  renderColumnNumber = () => {
    if (window.innerWidth > 550) {
      return 2
    }
    return 1
  }

  render() {
    const data = []

    const { unlock, mfc, mfcsObj } = this.state
    const { mfcs } = this.props
    const { getFieldDecorator } = this.props.form

    mfcsObj && Object.entries(mfcsObj).map(([key, value], index) => data.push({ ...value, title: key, index }))

    return (
      <LayoutContentWrapper>
        <TableDemoStyle className="isoLayoutContent">

          <div className="admin-row">
            <div className="admin-col">
              <div
                style={{
                  padding: 15,
                  background: '#f1f3f6',
                  marginBottom: 20
                }}
              >
                Gestion des Tarifs
              </div>
            </div>
          </div>

          <div className="admin-row">
            <div className="admin-col">
              <List
                grid={{ gutter: 16, column: this.renderColumnNumber() }}
                dataSource={data}
                renderItem={item => (
                  <List.Item>
                    <Card
                      title={`MFC ${item.edition} ${item.isReplay ? 'Replay' : 'Live'} ${item.disabled ? 'inactif' : 'actif'}`}
                      type="inner"
                      style={{ border: unlock === item.index ? 'dashed 1px #00ca44' : 'solid 1px rgba(0, 0, 0, 0.25)' }}
                    >
                      <Form>
                        <div className="admin-row">
                          <a href={`/dashboard/update-myfiit/${item._id}`}>Details</a>
                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                Price <Input placeholder="Nom du challenge" value={((unlock === item.index) && mfc && mfc.price) ? mfc.price : item.price} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'price', e)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                Old price <Input placeholder="Nom du challenge" value={((unlock === item.index) && mfc && mfc.oldPrice) ? mfc.oldPrice : item.oldPrice} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'oldPrice', e)} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                Duo price <Input placeholder="Nom du challenge" value={((unlock === item.index) && mfc && mfc.duoPrice) ? mfc.duoPrice : item.duoPrice} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'duoPrice', e)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                Old duo price <Input placeholder="Nom du challenge" value={((unlock === item.index) && mfc && mfc.oldDuoPrice) ? mfc.oldDuoPrice : item.oldDuoPrice} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'oldDuoPrice', e)} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                Position <Input type="number" placeholder="Nom du challenge" value={((unlock === item.index) && mfc && mfc.position) ? mfc.position : item.position} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'position', e)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                Edition <Input type="number" placeholder="Edition du challenge" value={((unlock === item.index) && mfc && mfc.edition) ? mfc.edition : item.edition} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'edition', e)} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                Youtube <Input type="text" placeholder="youtube code" value={((unlock === item.index) && mfc && mfc.youtubeCode) ? mfc.youtubeCode : item.youtubeCode} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'youtubeCode', e, false, true)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                Starter pack Pdf File <Input type="text" placeholder="starterPackPdfLink" value={((unlock === item.index) && mfc && mfc.starterPackPdfLink) ? mfc.starterPackPdfLink : item.starterPackPdfLink} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'starterPackPdfLink', e, false, true)} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                Upsell Price <Input type="number" placeholder="upsell price" value={((unlock === item.index) && mfc && mfc.upsellPrice) ? mfc.upsellPrice : item.upsellPrice} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'upsellPrice', e, false, true)} />
                              </Form.Item>
                            </div>
                          </div>

                          <div className="admin-col">
                            <div className="admin-col">
                              <Form.Item>
                                From <Input type="text" placeholder="from" value={((unlock === item.index) && mfc && mfc.from) ? mfc.from : item.from} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'from', e, false, true)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                To <Input type="text" placeholder="to" value={((unlock === item.index) && mfc && mfc.to) ? mfc.to : item.to} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'to', e, false, true)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                From mois <Input type="text" placeholder="from mois" value={((unlock === item.index) && mfc && mfc.fromMonth) ? mfc.fromMonth : item.fromMonth} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'fromMonth', e, false, true)} />
                              </Form.Item>
                            </div>
                            <div className="admin-col">
                              <Form.Item>
                                To mois <Input type="text" placeholder="to mois" value={((unlock === item.index) && mfc && mfc.toMonth) ? mfc.toMonth : item.toMonth} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'toMonth', e, false, true)} />
                              </Form.Item>
                            </div>
                          </div>

                          <div className="admin-row">
                            <Form.Item>
                              Promo Sentence <Input type="text" placeholder="promo sentence" value={((unlock === item.index) && mfc && mfc.promoSentence) ? mfc.promoSentence : item.promoSentence} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'promoSentence', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Facebook Url <Input type="text" placeholder="starterPackPdfLink" value={((unlock === item.index) && mfc && mfc.facebookUrl) ? mfc.facebookUrl : item.facebookUrl} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'facebookUrl', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Big description <TextArea type="text" placeholder="Big description" value={((unlock === item.index) && mfc && mfc.bigDescription) ? mfc.bigDescription : item.bigDescription} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'bigDescription', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Description <TextArea type="text" placeholder="description" value={((unlock === item.index) && mfc && mfc.description) ? mfc.description : item.description} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'description', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Label <TextArea type="text" placeholder="label" value={((unlock === item.index) && mfc && mfc.label) ? mfc.label : item.label} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'label', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Name <TextArea type="text" placeholder="name" value={((unlock === item.index) && mfc && mfc.name) ? mfc.name : item.name} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'name', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Home sentence <TextArea type="text" placeholder="name" value={((unlock === item.index) && mfc && mfc.homeSentence) ? mfc.name : item.homeSentence} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'homeSentence', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              future Replay Message <TextArea type="text" placeholder="name" value={((unlock === item.index) && mfc && mfc.futureReplay) ? mfc.futureReplay : item.futureReplay} disabled={unlock !== item.index} onChange={e => this.updateMfcField(item.index, 'futureReplay', e, false, true)} />
                            </Form.Item>
                            <Form.Item>
                              Rules
                              {
                                item && item.rules && item.rules.map((rule, index) => (
                                    <Form.Item key={`rules-${index}`}>
                                      Rule #{index + 1}
                                      <TextArea type="text" placeholder="description" value={((unlock === item.index) && mfc) ? mfc.rules[index] : item.rules[index]} disabled={unlock !== item.index} onChange={e => this.updateRules(item.index, index, e)} />
                                    </Form.Item>
                                ))
                              }
                              <Button onClick={() => {
                                const currentMfc = { ...this.state.mfcsObj }
                                if (currentMfc[item.index].rules) {
                                  currentMfc[item.index].rules.push('')
                                } else {
                                  currentMfc[item.index].rules = ['']
                                }
                                this.setState({ mfcsObj: currentMfc })
                              }}
                              >Add rule
                              </Button>
                              <Button onClick={() => {
                                const currentMfc = { ...this.state.mfcsObj }
                                currentMfc[item.index].rules = currentMfc[item.index].rules.filter(r => r !== '')
                                this.setState({ mfcsObj: currentMfc })
                              }}
                              >Clean rules
                              </Button>
                            </Form.Item>
                          </div>
                          <div className="admin-row">
                            <div className="admin-col">
                              <div className="admin-row">
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.isReplay} onChange={e => this.updateMfcField(item.index, 'isReplay', e, true)} /> Replay
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.highlighted} onChange={e => this.updateMfcField(item.index, 'highlighted', e, true)} /> Highlighted
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.disabled} onChange={e => this.updateMfcField(item.index, 'disabled', e, true)} /> Disabled
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.hidden} onChange={e => this.updateMfcField(item.index, 'hidden', e, true)} /> Hidden
                                </Form.Item>
                              </div>
                              <div className="admin-row">
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.blocked} onChange={e => this.updateMfcField(item.index, 'blocked', e, true)} /> Block achat
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.isDuo} onChange={e => this.updateMfcField(item.index, 'isDuo', e, true)} /> Mode duo
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.hasUpsell} onChange={e => this.updateMfcField(item.index, 'hasUpsell', e, true)} /> Upsell
                                </Form.Item>
                                <Form.Item>
                                  <Checkbox disabled={unlock !== item.index} checked={item && item.isPromo} onChange={e => this.updateMfcField(item.index, 'isPromo', e, true)} /> Promo
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="admin-row">
                          {
                            unlock === item.index ? (
                              <div className="admin-row">
                                <Button size="large" type="primary" onClick={() => this.savePrice(item.index)}>Save prices</Button>
                              </div>
                            ) : (
                              <div className="admin-row">
                                <Button size="large" type="secondary" onClick={() => this.setState({ unlock: item.index })}>Update prices {item.name}</Button>
                              </div>
                            )
                          }
                        </div>
                      </Form>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
          </div>

          <div className="admin-row">
            <div className="admin-col">
              <div
                style={{
                  padding: 15,
                  background: '#f1f3f6',
                  marginBottom: 20
                }}
              >
                Créer un tarif
              </div>
            </div>
          </div>
          <div className="admin-row">
            <div className="admin-col">
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Veuillez indiquer le nom du Challenge ex: MFC4!' }],
                  })(
                    <Input prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nom du challenge" />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('oldPrice', {
                    rules: [{ required: true, message: 'Veuillez indiquer un prix barré!' }],
                  })(
                    <Input prefix={<Icon type="euro" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="prix barre" />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('price', {
                    rules: [{ required: true, message: 'Veuillez indiquer un prix!' }],
                  })(
                    <Input prefix={<Icon type="euro" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="prix" />
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('oldDuoPrice', {
                    rules: [{ required: true, message: 'Veuillez indiquer un prix barré DUO!' }],
                  })(
                    <Input prefix={<Icon type="euro" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="prix barre" />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('duoPrice', {
                    rules: [{ required: true, message: 'Veuillez indiquer un prix Duo!' }],
                  })(
                    <Input prefix={<Icon type="euro" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="prix" />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Create
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

        </TableDemoStyle>
      </LayoutContentWrapper>
    )
  }
}


export default Form.create()(Pricing)
