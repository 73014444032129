export const setToken = (name, token, old) => {

  const d = new Date()
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000))
  const expires = `expires=${old || d.toUTCString()}`
  document.cookie = `${name}=${token};${expires};path=/`
}

export const getToken = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const removeToken = (name) => {
  setToken(name, '', 'Thu, 01 Jan 1970 00:00:01 GMT;')
}
