import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { Timeline } from 'antd'

import {
  getUser,
  setUserPage
} from '../../../../redux/admin/action'


class EditUser extends Component {
  componentWillMount() {
    const { getUser, match } = this.props
    getUser(match.params.userId)
  }

  render() {
    const { user } = this.props
    return (
      <div>
        { user
        && user.logs
        && Object.entries(user.logs).map(([key, value], index) => (
          <Timeline>
            <Timeline.Item>{`${key} : ${value}`}</Timeline.Item>
          </Timeline>
        ))
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.Admin.user,
  userPage: state.Admin.userPage
})


export default connect(mapStateToProps, {
  getUser,
  setUserPage
})(withRouter(EditUser))
