import React, { Component } from 'react'
import './stylesheet.scss'

class Title extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {}

  render() {
    const { children } = this.props

    return (
      <div className="mfc-title">
        { children }
      </div>
    )
  }
}

export default Title
