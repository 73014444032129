import React, { Component } from 'react'
import './stylesheet.scss'
import {
  Input, Icon, DatePicker, Tag, Button, Select
} from 'antd'
import moment from 'moment'

const Option = Select.Option
const colors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
]

export default class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      startDate: null,
      endDate: null,
      displayStartDate: null,
      displayEndDate: null,
      endOpen: false,
      visible: true,
      cleaned: false
    }
  }

  componentWillMount() {
    const { getMfcs } = this.props
    getMfcs()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.nameInput.state.value === '' && prevState.name !== '') {
      const { onSearch, queryParam } = this.props
      const { startDate, endDate } = this.state
      onSearch({
        ...queryParam, dates: { startDate, endDate }, name: '', page: 0
      })
      this.setState({ name: '' })
    }
  }

  disabledStartDate = (startDate) => {
    const endDate = this.state.endDate
    if (!startDate || !endDate) {
      return false
    }
    return startDate.valueOf() > endDate.valueOf()
  }

  disabledEndDate = (endDate) => {
    const startDate = this.state.startDate
    if (!endDate || !startDate) {
      return false
    }
    return endDate.valueOf() <= startDate.valueOf()
  }

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true })
    }
  }

  handleChangeMfc = (mfc) => {
    const { onSearch, queryParam } = this.props
    this.setState({ selectedMfc : mfc.name || 'aucun' })
    this.onChange('challenge', mfc.name || 'aucun')
    onSearch({ ...queryParam, mfc: mfc._id || 'false', page: 0 })
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open })
  }


  emitEmpty = () => {
    this.nameInput.focus()
    this.setState({ name: '' })
  }

  onChangename = (e) => {
    const { onSearch, queryParam } = this.props
    const { startDate, endDate } = this.state
    onSearch({
      ...queryParam, dates: { startDate, endDate }, name: e.target.value, page: 0
    })
    this.setState({ name: e.target.value })
  }

  onStartChange = (value) => {
    const { endDate } = this.state
    const { onSearch, queryParam } = this.props
    this.onChange('startDate', value)
    this.onChange('displayStartDate', moment(value).format('DD/MM/YYYY'))
    onSearch({ ...queryParam, dates: { displayStartDate: moment(value).format('DD/MM/YYYY'), startDate: value, endDate }, page: 0 })
  }

  onEndChange = (value) => {
    const { onSearch, queryParam } = this.props
    const { startDate } = this.state
    this.onChange('endDate', value)
    this.onChange('displayEndDate', moment(value).format('DD/MM/YYYY'))
    onSearch({ ...queryParam, dates: { displayEndDate: moment(value).format('DD/MM/YYYY'), startDate, endDate: value }, page: 0 })
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value
    })
  }

  removeTags = (tags) => {
    const { onSearch, queryParam } = this.props

    const filteredParams = queryParam
    tags.forEach((t) => {
      if (t === 'challenge') {
        delete filteredParams.mfc
      }
      if (t === 'startDate' || t === 'endDate') {
        delete filteredParams.dates[t]
      } else {
        delete filteredParams[t]
      }
    })

    onSearch({ ...filteredParams, page: 0 })

    tags.map(t => this.setState({ [t]: null }))
  }

  goExport = () => {
    const { onSearch, queryParam } = this.props
    onSearch({ ...queryParam, isExport: true })
  }

  resetFilters = () => {
    const { onSearch } = this.props
    this.setState({
      name: '',
      startDate: null,
      challenge: null,
      endDate: null,
      displayStartDate: null,
      displayEndDate: null,
      selectedMfc: 'Tous challenges'
    })
    onSearch({ page: 0 })
  }


  render() {
    const { mfcs } = this.props
    const {
      name, startDate, endDate, endOpen, displayStartDate, displayEndDate, challenge, selectedMfc
    } = this.state
    const suffix = name && <Icon type="close-circle" onClick={this.emitEmpty} />
    const tagsContent = ['name', 'startDate', 'endDate', 'challenge']
    const tags = []

    tagsContent.forEach((t) => {
      if (this.state[t]) {
        t === 'name' && tags.push({ label: 'Nom:', value: name, onClose: () => this.removeTags(['name']) })
        t === 'startDate' && tags.push({ label: 'Date début achat:', value: displayStartDate, onClose: () => this.removeTags(['startDate', 'displayStartDate']) })
        t === 'endDate' && tags.push({ label: 'Date fin achat:', value: displayEndDate, onClose: () => this.removeTags(['endDate', 'displayEndDate']) })
        t === 'challenge' && tags.push({ label: 'Challenge:', value: challenge, onClose: () => this.removeTags(['challenge']) })
      }
    })

    return (
      <div>
        <div className="admin-row-flex">
          <div className="admin-col">
            <div style={{
              padding: 15,
              background: '#f1f3f6',
              marginBottom: 20,
              width: '100%'
            }}
            >
              Gestion des utilisateurs
            </div>
          </div>
        </div>

        <div className="admin-row-flex admin-row-flex--padding">
          <div className="admin-row--small">
            <div className="admin-col">
              <Input
                placeholder="Recherchez un utilisateur"
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                suffix={suffix}
                value={name}
                onChange={this.onChangename}
                style={{minWidth: 200}}
                ref={node => this.nameInput = node}
              />
            </div>
            <div className="admin-col">
              <DatePicker
                disabledDate={this.disabledStartDate}
                showTime
                format="YYYY-MM-DD"
                value={startDate}
                placeholder="Début date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
                className="admin-datepicker"
              />
            </div>
            <div className="admin-col">
              <DatePicker
                disabledDate={this.disabledEndDate}
                showTime
                format="YYYY-MM-DD"
                value={endDate}
                placeholder="Fin date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
                className="admin-datepicker"
              />
            </div>
          </div>
          <div className="admin-row--small">
            <div className="admin-col">
              <Select defaultValue="Tous challenges" value={selectedMfc || 'Tous challenges'}>
                {
                  mfcs && mfcs.map((k, i) => (
                    <Option key={`mfcs-${i}`} value={ k._id } onClick={() => this.handleChangeMfc(k)}>{k.name}</Option>
                  ))

                }
                <Option key='lst' onClick={() => this.handleChangeMfc('false')}>Sans challenge</Option>
              </Select>
            </div>
            <div className="admin-col">
              <Button type="primary" onClick={() => this.goExport()}>Exporter <span style={{ fontWeight: 'bold', paddingLeft: 5, paddingRight: 5 }}> {tags.length ? ' selection' : ' tout'} </span> en csv</Button>
            </div>
            <div className="admin-col">
              <Button onClick={() => this.resetFilters()}>Vider filtres</Button>
            </div>
          </div>
        </div>
    
        <div className="admin-row-flex">
          <div className="admin-col">
            <div style={{ marginTop: 15, display: 'flex', flexFlow: 'wrap' }}>
              { tags.map((k, idx) => (
                <Tag
                  key={`date-${idx}`}
                  closable
                  color={colors[idx]}
                  visible={this.state.visible}
                  onClose={k.onClose}
                >
                  {k.label} {k.value}
                </Tag>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
