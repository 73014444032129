import React from 'react'
import { Provider } from 'react-redux'
import { LocaleProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import PublicRoutes from './router/router'
import { history, store } from './redux/store'
import DashAppHolder from './dashAppStyle'
import { CookiesProvider } from 'react-cookie'
import './pages/stylesheet.scss'

import 'antd/dist/antd.css'


const DashApp = () => (
  <CookiesProvider>
    <DashAppHolder>
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
    </DashAppHolder>
  </CookiesProvider>
)

export default DashApp
