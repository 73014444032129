import React from 'react'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import Instructions from '../instructions'
import Materiel from '../pages/accessories'
import App from '../pages/admin/App/App'
import Complements from '../pages/complements'
import FiitFightForever from "../pages/fff"
import Help from '../pages/help'
import Home from '../pages/home-page'
import Legal from "../pages/legal"
import MfcReplay from '../pages/my-fiit-challenge-replay'
import MfSelect from '../pages/my-fiit-challenge-selection'
import Presentation from '../pages/my-fiit-presentation'
import Payment from '../pages/purchase'
import Removal from "../pages/remove"
import '../pages/stylesheet.scss'
import Vimeo from '../pages/test'
import Voucher from '../pages/voucher'
import { getMfc, getMfcByName } from '../redux/admin/action'
import { fbConnect, getAccount, refreshToken } from '../redux/auth/actions'
import { downloadDoc, unlockVideo } from '../redux/hiit/actions'
import { downloadPdf } from '../redux/pdf/actions'
import { purchaseMfc, purchaseVoucher } from '../redux/purchases/actions'
import RestrictedRoute from './restrictredRoutes'

const restrictedList = ['my-fiit-challenges', 'my-fiit-challenge-replay', 'purchase', 'my-fiit-challenge']

const PublicRoutes = ({
  history,
  account,
  getAccount,
  mfcs,
  getMfcs,
  getMfc,
  mfc,
  fbConnect,
  purchaseMfc,
  cookies,
  refreshToken,
  redirect,
  paiementSuccess,
  paiementError,
  paiementLoader,
  getMfcByName,
  unlockVideo,
  expiredSession,
  purchaseVoucher,
  downloadDoc,
  downloadPdf,
}) => {
  if (expiredSession) {
    if (restrictedList.includes(window.location.pathname.split('/')[1])) {
      // ExpiredModal()
    }
  }
  return (
    <ConnectedRouter history={history}>
      <React.Fragment>
        <Route
          exact
          path="/"
          cookies={cookies}
          render={() => (
            <Home
              fbConnect={fbConnect}
              redirect={redirect}
              refreshToken={refreshToken}
              account={account}
              getAccount={getAccount}
            />
          )
        }
        />
        <RestrictedRoute
          exact
          path="/my-fiit-challenges"
          cookies={cookies}
          account={account}
          view={(
            <MfSelect
              getAccount={getAccount}
            // mfcs={mfcs}
            // getMfcs={getMfcs}
              refreshToken={refreshToken}
              account={account}
            />
        )}
        />
        <RestrictedRoute
          exact
          path="/join-team"
          cookies={cookies}
          account={account}
          view={(
            <Voucher
              getAccount={getAccount}
              refreshToken={refreshToken}
              account={account}
              paiementSuccess={paiementSuccess}
              paiementError={paiementError}
              paiementLoader={paiementLoader}
              purchaseVoucher={purchaseVoucher}
            />
        )}
        />
        <RestrictedRoute
          path="/purchase/:mfc/:id/:duo"
          account={account}
          cookies={cookies}
          view={(
            <Payment
              getAccount={getAccount}
              mfcs={mfcs}
              getMfc={getMfc}
              paiementSuccess={paiementSuccess}
              paiementError={paiementError}
              paiementLoader={paiementLoader}
              purchaseMfc={purchaseMfc}
              account={account}
              mfc={mfc}
              refreshToken={refreshToken}
            />
        )}
        />
        <RestrictedRoute
          path="/purchase/:mfc/:id"
          account={account}
          cookies={cookies}
          exact
          view={(
            <Payment
              getAccount={getAccount}
              mfcs={mfcs}
              getMfc={getMfc}
              paiementSuccess={paiementSuccess}
              paiementError={paiementError}
              paiementLoader={paiementLoader}
              purchaseMfc={purchaseMfc}
              account={account}
              refreshToken={refreshToken}
              mfc={mfc}
            />
        )}
        />
        <RestrictedRoute
          path="/my-fiit-challenge/:edition"
          account={account}
          cookies={cookies}
          mfc={mfc}
          view={(
            <MfcReplay
              refreshToken={refreshToken}
              account={account}
              getMfc={getMfc}
              unlockVideo={unlockVideo}
              downloadDoc={downloadDoc}
              downloadPdf={downloadPdf}
              mfc={mfc}
              getMfcByName={getMfcByName}

              isLive={(mfc && !mfc.isReplay) || false}
            />
        )}
        />
        { /* <RestrictedRoute
          path="/my-fiit-challenge/:edition"
          mfc={mfc}
          cookies={cookies}
          view={(
            <MfcReplay
              refreshToken={refreshToken}
              getMfc={getMfc}
              downloadDoc={downloadDoc}
              downloadPdf={downloadPdf}
              unlockVideo={unlockVideo}
              mfc={mfc}
              getMfcByName={getMfcByName}
              account={account}
              isLive={false}
            />
          )}
        /> */ }
        <Route
          path="/challenge/:edition"
          mfc={mfc}
          cookies={cookies}
          render={() => (
            <Presentation
              getMfc={getMfc}
              mfc={mfc}
              getMfcByName={getMfcByName}
              refreshToken={refreshToken}
              account={account}
            />
          )}
        />
        <Route
          exact
          path="/help"
          cookies={cookies}
          render={() => <Help />}
        />
        <Route
          exact
          path="/lalilulelo/:id"
          cookies={cookies}
          render={() => <Vimeo />}
        />
        <Route
          exact
          path="/instructions"
          cookies={cookies}
          render={() => <Instructions downloadPdf={downloadPdf} version={mfc.edition} />}
        />
        <Route
          exact
          path="/removal"
          cookies={cookies}
          render={() => <Removal />}
        />
        <Route
          exact
          path="/legal"
          cookies={cookies}
          render={() => <Legal />}
        />
        <Route
          exact
          path="/politique-de-confidentialite"
          cookies={cookies}
          render={() => <Legal />}
        />
        <Route
          exact
          path="/conditions-de-service"
          cookies={cookies}
          render={() => <Legal short/>}
        />
        <Route
          exact
          path="/confidentialite"
          cookies={cookies}
          render={() => <FiitFightForever />}
        />


        <RestrictedRoute
          path="/dashboard"
          exact
          cookies={cookies}
          view={(
            <Redirect
              to={{
                pathname: '/dashboard/users'
              }}
            />
          )}
        />
        <RestrictedRoute
          path="/dashboard/pdfs"
          exact
          cookies={cookies}
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/promocodes"
          exact
          cookies={cookies}
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/pdfs/:mfcId"
          cookies={cookies}
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/users"
          cookies={cookies}

          view={(
            <App />
          )}
        />
        <RestrictedRoute
          exact
          path="/dashboard/update-myfiit/:myfiit"
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/user/:userId"
          cookies={cookies}
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/pricing"
          cookies={cookies}
          exact
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/dashboard/statistics"
          cookies={cookies}
          exact
          view={(
            <App />
          )}
        />
        <RestrictedRoute
          path="/:mfc/materiel"
          cookies={cookies}
          refreshToken={refreshToken}
          account={account}
          exact
          view={(
            <Materiel
                refreshToken={refreshToken}
                account={account}
            />
          )}
        />
        <RestrictedRoute
          path="/:mfc/complements"
          exact
          cookies={cookies}
          refreshToken={refreshToken}
          account={account}
          view={(
            <Complements
                refreshToken={refreshToken}
                account={account}
            />
            )}
        />
      </React.Fragment>
    </ConnectedRouter>
  )
}

export default connect(
  (state) => {
    return ({
      account: state.Facebook.account ? (state.Facebook.account.data || false) : false,
      expiredSession: state.Facebook.expiredSession,
      mfc: state.Admin.mfc,
      redirect: state.Facebook.redirect,
      paiementSuccess: state.Purchases.paiementSuccess,
      paiementError: state.Purchases.paiementError,
      paiementLoader: state.Purchases.paiementLoader,
    })
  },
  {
    getAccount, unlockVideo, fbConnect, getMfc, purchaseMfc, refreshToken, getMfcByName, purchaseVoucher, downloadDoc, downloadPdf
  }
)(withCookies(PublicRoutes))
