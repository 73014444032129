import React, { PureComponent } from 'react'
import './stylesheet.scss'

class AppContainer extends PureComponent {
  render() {
    const { children } = this.props

    return (
      <div className='app-container'>
        { children }
      </div>
    )
  }
}

export default AppContainer
