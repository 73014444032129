/* eslint-disable */
import axios from 'axios'
import { uri } from '../../uri'
import { getToken, removeToken, setToken } from '../token'

const getConfig = () => ({
  headers: { Authorization: `Bearer ${getToken('mfc-token')}` }
})

export const fbConnect = (status, data, url) => (dispatch) => {
  dispatch({
    type: 'CONNECT',
    status,
    data
  })

  axios.post(`${uri.uri.user.login}`, { facebookId: data.user.id, fbUser: data.user }).then((response) => {
    setToken('mfc-token', response.data.token)
    dispatch({
      type: 'GET_ACCOUNT_SUCCESS',
      data: response,
      redirect: url || false
    })
  }).catch((error) => {
    dispatch({
      type: 'GET_ACCOUNT_FAIL',
      data: null
    })
  })
}

export const getAccount = facebookId => (dispatch) => {
  dispatch({
    type: 'GET_ACCOUNT'
  })

  axios.post(`${uri.uri.user.login}`, { facebookId }, getConfig()).then((response) => {
    dispatch({
      type: 'GET_ACCOUNT_SUCCESS',
      data: response
    })
  }).catch((error) => {
    console.log('--get account error', error)
    if (error.status !== 401 && error.status !== 400) {
      removeToken('mfc-token')
      window.location.href = '/'
    }
    dispatch({
      type: 'GET_ACCOUNT_FAIL',
      data: null
    })
  })
}


export const refreshToken = () => (dispatch) => {
  dispatch({
    type: 'GET_ACCOUNT'
  })

  axios.post(`${uri.uri.user.refreshToken}`, {}, getConfig()).then((response) => {
    setToken('mfc-token', response.data.token)
    dispatch({
      type: 'GET_ACCOUNT_SUCCESS',
      data: response
    })
  }).catch((error) => {
    // if (error.status !== 401 && error.status !== 400) {
      removeToken('mfc-token')
      // window.location.href = '/'
    // }
    dispatch({
      type: 'EXPIRED_SESSION',
      data: null
    })
  })
}


export const deleteUser = () => (dispatch) => {
  console.log('--delete user called')

  axios.delete(uri.uri.user.delete, getConfig()).then(() => {
    dispatch({
      type: 'EXPIRED_SESSION'
    });
    window.location.href = '/'
    alert('Vos informations personnelles ont bien été supprimées');
  });
}
