import React, { Component } from 'react'
import './stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'

library.add(faInfoCircle, faChevronRight)

class Youtube extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {}

  render() {
    const { video } = this.props

    return (
      <div className="youtube">
        <iframe
          src={`https://www.youtube.com/embed/${video}?rel=0&autoplay=0&autohide=1&amp&showinfo=0&wmode=transparent`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title="video"
          allowFullScreen
        />
      </div>
    )
  }
}

export default Youtube
