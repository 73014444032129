function getEnv() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return ''
  }
  return '/api'
}
export const uri = {
  uri: {
    user: {
      login: `${getEnv()}/user/login`,
      logout: `${getEnv()}/user/logout`,
      unlockVideo: `${getEnv()}/purchase/view-video`,
      downloadPdf: `${getEnv()}/pdf`,
      purchase: `${getEnv()}/purchase`,
      voucher: `${getEnv()}/purchase-code`,
      refreshToken: `${getEnv()}/user/refresh`,
      intent: `${getEnv()}/purchase/intent`,
      pay: `${getEnv()}/purchase/pay`,
      confirm: `${getEnv()}/purchase/confirm`,
      delete: `${getEnv()}/user`,
    },
    pdf: {
      available: `${getEnv()}/pdf/available`,
      getList: `${getEnv()}/pdfs`,
    },
    admin: {
      user: `${getEnv()}/user`,
      promocode: `${getEnv()}/promocode`,
      checkCode: `${getEnv()}/check-code`,
      editUser: `${getEnv()}/user/edit`,
      giveAccess: `${getEnv()}/user/access`,
      deleteAccount: `${getEnv()}/user/delete`,
      users: `${getEnv()}/user`,
      pdf: `${getEnv()}/pdf`,
      youtube: `${getEnv()}/youtube`,
      mfcs: `${getEnv()}/mfc`,
      mfcsYoutube: `${getEnv()}/mfc/youtube`,
      mfcByName: `${getEnv()}/mfc/edition`,
      purchases: `${getEnv()}/purchase/stats`,
      sendBonus: `${getEnv()}/user/sendBonus`,
      sendWelcome: `${getEnv()}/user/sendWelcome`,
    }
  }
}
