import React, { PureComponent } from 'react'
import './stylesheet.scss'
import '../../my-fiit-challenge-selection/stylesheet.scss'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronRight, faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

library.add(faChevronRight, faChevronLeft)

class ReplayNavigation extends PureComponent {
  render() {
    const {
      actionLeft, title, actionRight, flatifySessions, index, purchaseId, desktop, isLive, actionCenter, isMobile
    } = this.props

    return (
      <div className={`replay-navigation ${desktop ? 'replay-navigation--desktop' : ''}`}>
        { !isLive && (
        <div
          className={classNames({
            'replay-navigation__icon': desktop,
            'replay-navigation__left': true,
            'replay-navigation--disabled': index === 0
          })}
          onClick={() => (index === 0 ? console.log('is first') : actionLeft({ ...flatifySessions[index - 1], purchaseId }))}
        ><FontAwesomeIcon
          icon="chevron-left"
        />
        </div>
        ) }
        <div className={classNames({
          'replay-navigation__center': true,
          'replay-navigation__center--full': isLive,
          'replay-navigation__center--mobile': !desktop,
        })}
        >
          <div className="replay-navigation__center__action" onClick={() => actionCenter()}>
            { desktop ? title || 'Accueil' : 'Retour à la liste' }
          </div>
        </div>
        { !isLive && (
        <div
          className={classNames({
            'replay-navigation__icon': desktop,
            'replay-navigation__right': true,
            'replay-navigation--disabled': index + 1 === flatifySessions.length,
          })}
          onClick={() => (index + 1 === flatifySessions.length ? console.log('is last') : actionRight({ ...flatifySessions[index + 1], purchaseId }))}
        >
          <FontAwesomeIcon
            icon="chevron-right"
          />
        </div>
        ) }
      </div>
    )
  }
}

export default withRouter(ReplayNavigation)
