const defaultState = {
  users: [],
  sendSuccess: false,
  mfcs: [],
  pdfs: [],
  createSuccess: false,
  deleted: false,
  edited: false,
  mfc: null,
  mfcPdfs: [],
  userPage: 0,
  successUserUpdate: false,
  queryParam: null,
  promocodes: null,
  generatedCode: null,
  userInfo: null,
  editedCode: false
}

export default function Admin(state = defaultState, action) {
  switch (action.type) {
    case 'FIND_USERS': {
      return {
        ...state,
        queryParam: action.queryParam
      }
    }
    case 'FIND_USERS_SUCCESS': {
      return {
        ...state,
        users: action.data.data
      }
    }
    case 'FIND_USERS_ERROR': {
      return {
        users: null,
        ...state
      }
    }

    case 'GET_USERS': {
      return {
        ...state
      }
    }
    case 'GET_USERS_SUCCESS': {
      return {
        ...state,
        users: action.data
      }
    }
    case 'GET_USERS_ERROR': {
      return {
        users: ['tg'],
        ...state
      }
    }
    case 'EDIT_USER': {
      return {
        ...state,
        successUserUpdate: false
      }
    }
    case 'EDIT_USER_SUCCESS': {
      return {
        ...state,
        successUserUpdate: true
      }
    }
    case 'EDIT_USER_ERROR': {
      return {
        ...state,
        successUserUpdate: false
      }
    }
    case 'GIVE_ACCESS': {
      return {
        ...state,
        successUserUpdate: false
      }
    }
    case 'GIVE_ACCESS_SUCCESS': {
      return {
        ...state,
        successUserUpdate: true
      }
    }
    case 'GIVE_ACCESS_ERROR': {
      return {
        ...state,
        successUserUpdate: false
      }
    }
    case 'GET_USER': {
      return {
        ...state
      }
    }
    case 'GET_USER_SUCCESS': {
      return {
        ...state,
        user: action.data
      }
    }
    case 'GET_USER_ERROR': {
      return {
        user: null,
        ...state
      }
    }
    case 'GET_USER_INFO': {
      return {
        ...state,
        userInfo: null
      }
    }
    case 'GET_USER_INFO_SUCCESS': {
      return {
        ...state,
        userInfo: action.data
      }
    }
    case 'GET_USER_INFO_ERROR': {
      return {
        ...state,
        userInfo: null
      }
    }
    case 'SWITCH_CODE': {
      return {
        ...state,
        editedCode: false
      }
    }
    case 'SWITCH_CODE_SUCCESS': {
      return {
        ...state,
        editedCode: action.editedCode,
        promocodes: action.data
      }
    }
    case 'SWITCH_CODE_ERROR': {
      return {
        ...state,
        editedCode: false
      }
    }

    case 'GET_PDFS': {
      return {
        ...state
      }
    }
    case 'GET_PDFS_SUCCESS': {
      return {
        ...state,
        pdfs: action.data.items
      }
    }
    case 'GET_PDFS_ERROR': {
      return {
        ...state
      }
    }
    case 'GET_MFC_PDFS': {
      return {
        ...state
      }
    }
    case 'GET_MFC_PDFS_SUCCESS': {
      return {
        ...state,
        mfcPdfs: action.data
      }
    }
    case 'GET_MFC_PDFS_ERROR': {
      return {
        ...state
      }
    }

    case 'SEND_PDF': {
      return {
        ...state,
        sendSuccess: 'test',
      }
    }
    case 'SEND_PDF_SUCCESS': {
      return {
        ...state,
        sendSuccess: 'success'
      }
    }
    case 'SEND_PDF_ERROR': {
      return {
        ...state,
        sendSuccess: 'error',
      }
    }

    case 'SEND_YOUTUBE': {
      return {
        ...state,
        sendSuccess: 'test',
      }
    }
    case 'SEND_YOUTUBE_SUCCESS': {
      return {
        ...state,
        sendSuccess: 'success'
      }
    }
    case 'SEND_YOUTUBE_ERROR': {
      return {
        ...state,
        sendSuccess: 'error',
      }
    }

    case 'DELETE_PDF': {
      return {
        ...state,
        sendSuccess: 'test',
      }
    }
    case 'DELETE_PDF_SUCCESS': {
      return {
        ...state,
        sendSuccess: 'success'
      }
    }
    case 'DELETE_PDF_ERROR': {
      return {
        ...state,
        sendSuccess: 'error',
      }
    }

    case 'RESET': {
      return {
        ...state,
        sendSuccess: false,
      }
    }

    case 'GET_MFCS': {
      return {
        ...state
      }
    }

    case 'GET_MFCS_SUCCESS': {
      return {
        ...state,
        mfcs: action.data
      }
    }

    case 'GET_MFCS_ERROR': {
      return {
        ...state
      }
    }

    case 'GET_MFC': {
      return {
        ...state
      }
    }

    case 'GET_MFC_SUCCESS': {
      return {
        ...state,
        mfc: action.data
      }
    }

    case 'GET_MFC_ERROR': {
      return {
        ...state
      }
    }

    case 'CREATE_PRICE': {
      return {
        ...state
      }
    }
    case 'CREATE_PRICE_SUCCESS': {
      return {
        ...state,
        createSuccess: action.data
      }
    }
    case 'CREATE_PRICE_ERROR': {
      return {
        ...state,
        createSuccess: false
      }
    }

    case 'DELETE_PRICE': {
      return {
        ...state
      }
    }
    case 'DELETE_PRICE_SUCCESS': {
      return {
        ...state,
        deleted: action.deleted
      }
    }
    case 'DELETE_PRICE_ERROR': {
      return {
        ...state,
        deleted: action.deleted
      }
    }

    case 'EDIT_PRICE': {
      return {
        ...state
      }
    }
    case 'EDIT_PRICE_SUCCESS': {
      return {
        ...state,
        edited: action.edited
      }
    }
    case 'EDIT_PRICE_ERROR': {
      return {
        ...state,
        edited: action.edited
      }
    }

    case 'SAVE_MFC': {
      return {
        ...state
      }
    }

    case 'SAVE_MFC_SUCCESS': {
      return {
        ...state,
        edited: action.edited,
        mfc: action.data
      }
    }

    case 'SAVE_MFC_ERROR': {
      return {
        ...state
      }
    }
    case 'SAVE_MFC_YOUTUBE': {
      return {
        ...state
      }
    }

    case 'SAVE_MFC_YOUTUBE_SUCCESS': {
      return {
        ...state,
        edited: true,
        mfc: action.data
      }
    }

    case 'SAVE_MFC_YOUTUBE_ERROR': {
      return {
        ...state
      }
    }
    case 'FIND_POMOCODE': {
      return {
        ...state,
        promocodes: null
      }
    }

    case 'FIND_POMOCODE_SUCCESS': {
      return {
        ...state,
        promocodes: action.data
      }
    }

    case 'FIND_POMOCODE_ERROR': {
      return {
        ...state,
        promocodes: null
      }
    }
    case 'CREATE_POMOCODE': {
      return {
        ...state,
        generatedCode: null
      }
    }

    case 'CREATE_POMOCODE_SUCCESS': {
      return {
        ...state,
        generatedCode: action.data
      }
    }

    case 'CREATE_POMOCODE_ERROR': {
      return {
        ...state,
        generatedCode: null
      }
    }
    case 'SET_USER_PAGE': {
      return {
        ...state,
        userPage: action.page
      }
    }
    case 'GET_USER_PAGE': {
      return {
        ...state
      }
    }
    default: return state
  }
}
