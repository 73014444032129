import React, { Component } from 'react'
import './stylesheet.scss'
import '../my-fiit-challenge-selection/stylesheet.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBroadcastTower, faArrowRight, faRedoAlt, faFilePdf, faInfoCircle, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import * as Scroll from 'react-scroll'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import Header from '../../components/header'
import BreadcrumbDesktop from '../../components/breadcrumbDesktop'
import Complements from '../../components/complements'
import InstructionsEmbed from '../../instructions/embed'
import AppContainer from '../../containers/AppContainer'
import nutriText from '../../image/nutriText.png'
import ReplayNavigation from './navigation'

const LinkScroll = Scroll.Link
const Element = Scroll.Element
const Events = Scroll.Events
const scroll = Scroll.animateScroll
const scrollSpy = Scroll.scrollSpy

library.add(faBroadcastTower, faArrowRight, faRedoAlt, faFilePdf, faInfoCircle)

class MfcReplay extends Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.containerSmall = React.createRef()
    this.state = {
      video: props.youtubeCode,
      name: '',
      pdfsArrValues: null,
      view: 'how',
      index: 0,
      busy: false,
      isYoutube: false,
      liveDay: null,
      liveWeek: null,
      livePdf: null
    }

    this.unlockVideo = this.unlockVideo.bind(this)
    this.setVideo = this.setVideo.bind(this)
  }

  componentWillMount() {
    const { getMfcByName, refreshToken } = this.props
    const { edition } = this.props.match.params
    refreshToken()
    getMfcByName(edition)
  }

  componentDidMount() {
    scrollSpy.update()
    this.isMobile()
    scroll.scrollToTop()
    window.addEventListener('resize', this.isMobile.bind(this))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { account, mfc } = nextProps
    const { busy } = this.state
    if (account && mfc) {
      if (!busy) {
        // this.setState({ ...this.getCurrentPosition(account, mfc), busy: true })
      }
    }
  }


  componentWillUnmount() {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
    window.removeEventListener('resize', this.isMobile.bind(this))
  }

  getCurrentPosition = (account, mfc) => {
    const { view } = this.state
    const purchaseData = account ? (account.user.purchases.filter(p => p.mfc === mfc._id)[0] || account.user.purchases.filter(p => p.mfc._id === mfc._id)[0]) : null
    if (purchaseData && mfc) {
      const pId = purchaseData.videoUnlocked - 1 || 0
      return {
        video: mfc.sessions[pId].videoUrl,
        name: mfc.sessions[pId].title,
        view,
        index: pId,
        pdfsArrValues: this.setPdfArray(mfc.sessions[pId], mfc)
      }
    }
    return null
  }

  isNutri = (type) => {
    return (type.split('.')[0].substr(type.split('.')[0].length - 5) === 'NUTRI')
  }

  setPdfArray = (data) => {
    const pdfsArr = []
    const { mfc } = this.props

    data.forEach((d) => {
      pdfsArr.push({
        type: this.isNutri(d) ? 'NUTRI' : 'LIVE',
        link: (this.props.isLive || mfc.edition === 45 || mfc.edition === 55)
          ? `${d.replace('/', '-').toUpperCase().split('.').slice(0, -1)
            .join('.')}.pdf`
          : `${d.replace(' ', '-').replace('/', '-').toUpperCase().split('.')
            .slice(0, -1)
            .join('.')}.pdf`,
        name: `PDF ${this.isNutri(d) ? 'NUTRITION' : 'LIVE'}`
      })
    })

    return pdfsArr
  }

  isMobile = () => {
    this.setState({ isMobile: window.innerWidth < 841 })
  }

  scrollToTop = () => {
    scroll.scrollToTop()
  }

  scrollToBottom = () => {
    scroll.scrollToBottom()
  }

  getIndex = (data, field, target) => {
    let index = null
    data.forEach((item, idx) => {
      if (item[field] === target) {
        index = idx
      }
    })
    return index
  }

  setVideo = (video, data) => {
    const { mfc } = this.props

    this.setState({
      video, name: data.title, index: this.getIndex(mfc.sessions, 'videoUrl', data.videoUrl), pdfsArrValues: this.setPdfArray(data.pdfUrls || []), isYoutube: false
    })
    scroll.scrollToTop()
  }

  setLiveVideo = (video, data) => {
    const { mfc } = this.props
    const name = `LIVE ${data.session} - Semaine ${(Number(data.week) - Number(mfc.edition === 5 ? 0 : 0))}`
    const index = mfc.liveYoutubeVideos.findIndex(i => i.week === (data.week - 1) && i.day === (data.session))
    this.setState({
      video, name, index, pdfsArrValues: this.setPdfArray([data.filename]), liveDay: data.session, liveWeek: data.week
    })
    scroll.scrollToTop()
  }

  unlockVideo = (purchaseId, url, data, index, counter) => {
    const { unlockVideo } = this.props
    this.setState({ view: 'video', index })
    unlockVideo(purchaseId, url)
    this.setVideo(url, data, counter)
  }

  displayVideo = (url, data, index, counter, v) => {
    this.setState({
      view: 'video', index, livePdf: v.filename, isYoutube: true
    })
    this.setLiveVideo(url, data, counter)
  }

  setTabAction = (type) => {
    // window.navigator.vibrate(200)
    scroll.scrollToTop()

    const { account, mfc } = this.props
    if (type === 'how') {
      this.setState({
        video: mfc.youtubeCode,
        name: 'Accueil',
        view: 'how'
      })
    } else if (type === 'list') {
      this.setState({ view: 'list', video: mfc.youtubeCode })
    } else {
      this.setState({ ...this.getCurrentPosition(account, mfc) })
    }
  }

  renderTabs = () => {
    const { view } = this.state
    const { isLive } = this.props
    return (
      <div className="tabs-container">
        <LinkScroll
          to="howSelector"
          spy
          smooth
          duration={1500}
          className={`pointer tabs-container__tab ${view === 'how' ? 'tabs-container__tab--selected' : ''}`}
          onClick={() => this.setTabAction('how')}
        >
          Accueil
        </LinkScroll>
        <button
          className={`pointer tabs-container__tab ${(view === 'video' || view === 'list') ? 'tabs-container__tab--selected' : ''}`}
          onClick={() => this.setTabAction('list')}
        >
          { !isLive && 'LIVE &' } PDF
        </button>
      </div>
    )
  }

  renderIftabs = () => {
    const { isLive, mfc } = this.props
    const { view } = this.state

    if (window.innerWidth < 841) {
      return this.renderTabs()
    }
    return !isLive ? (
      <div onClick={() => this.setTabAction('how')} className="how pointer">
        <div className="how__center">
          Accueil
        </div>
        <div className="how__right">
          <FontAwesomeIcon icon="arrow-right" />
        </div>
      </div>
    ) : (
      view === 'how' && (
      <div className="mfc-title-desktop">
        { /* mfc && isLive ? 'MFC PDF' : */ mfc.name}
      </div>
      )
    )
  }

  renderMaterial = () => {
    const { isLive, mfc } = this.props
    const { view } = this.state

    return (
      view === 'how' && (
      <div className="mfc-title-desktop">
        { /* mfc && isLive ? 'MFC PDF' : */ mfc.name}
      </div>
      )
    )

    // (window.innerWidth > 840) && (
    /* !isLive ? (
        <a href={`/${mfc.name}/materiel`} className="how pointer" style={{ marginLeft: 5 }}>
          <div className="how__center">
                Matériel conseillé
          </div>
          <div className="how__right">
            <FontAwesomeIcon icon="arrow-right" />
          </div>
        </a>
      ) : */
  }

  goActionLeft = (value) => {
    const { mfc } = this.props
    const {
      index
    } = this.state

    /* if (isYoutube) {
      const { liveYoutubeVideos } = mfc
      const prevVideo = liveYoutubeVideos.find(live => live.week === liveWeek - 1 && live.day === liveDay - 1)

      const name = `HIIT ${prevVideo.day} - Semaine ${prevVideo.week + 1}`

      this.setState({
        video: prevVideo.youtubeId,
        name,
        view: 'video',
        index: index - 1,
        pdfsArrValues: [{ type: 'HIIT', name: 'PDF HIIT', link: livePdf }]
      })
    } else { */
    this.setState({
      video: value.videoUrl,
      name: value.title,
      view: 'video',
      index: index - 1,
      pdfsArrValues: this.setPdfArray(value.pdfUrls, mfc)
    })
    // }
  }

  goActionRight = (value) => {
    const { unlockVideo, mfc } = this.props
    const { index } = this.state
    this.setState({
      video: value.videoUrl,
      name: value.title,
      view: 'video',
      index: index + 1,
      pdfsArrValues: this.setPdfArray(value.pdfUrls, mfc)
    })

    unlockVideo(value.purchaseId, value.videoUrl)
  }

  goActionCenter = () => {
    this.setTabAction('list')
  }

  flatifySessions = (sessions) => {
    const flatSessions = []
    sessions.forEach((s) => {
      Object.entries(s).forEach(([k, v]) => {
        flatSessions.push(v)
      })
    })

    return flatSessions
  }

  onClick = () => {
    this.setState({ view: 'list' })
  }

  getDoc = (doc, isYoutube) => {
    // const { mfc } = this.props
    const { downloadDoc, isLive } = this.props
    /* this.setState({
      video: mfc.youtubeCode,
      name: 'Comment suivre mon défi',
      view: 'how'
    }) */

    downloadDoc(isLive ? (isYoutube ? doc : doc.filename) : doc)
  }

  hasYoutubeId = (week, day) => {

    const { mfc } = this.props
    let newDay = day
    //if (this.props.mfc.edition === 55) {
    if (this.props.mfc.edition === 5 || this.props.mfc.edition === 55) {
      newDay = day + 1
    }

    return mfc.liveYoutubeVideos.find(m => m.week === week && m.day === newDay)
  }


  render() {
    const {
      mfc, account, isLive, downloadPdf
    } = this.props
    const {
      video,
      name,
      pdfsArrValues,
      view,
      isMobile,
      index
    } = this.state
    // const { edition } = this.props.match.params
    const sessions = []
    let newWidth = 150

    if (this.containerRef.current && this.containerRef.current.clientWidth) {
      const width = this.containerRef.current.clientWidth

      if (width < 300) {
        newWidth = Math.floor(width) - 21
      } else if (width >= 300 && width < 400) {
        newWidth = Math.floor(width / 2) - 23
      } else if (width >= 400 && width < 840) {
        newWidth = Math.floor(width / 4) - 20
      } else {
        if (this.containerSmall.current && this.containerSmall.current.clientWidth) {
          newWidth = Math.floor(this.containerSmall.current.clientWidth / 2) - 23
        }
      }
    }


    if (mfc) {
      const tmpEdition = mfc

      tmpEdition[isLive ? 'pdfs' : 'sessions'] = Array.isArray(mfc[isLive ? 'pdfs' : 'sessions']) ? mfc[isLive ? 'pdfs' : 'sessions'] : JSON.parse(mfc[isLive ? 'pdfs' : 'sessions'])

      const unique = isLive ? [...new Set(tmpEdition.pdfs.sort((a, b) => a.week - b.week).sort((a, b) => {
        return a.session - b.session
      }).map(item => item.week))] : [...new Set(tmpEdition.sessions.sort((a, b) => {
        return b.session - b.session
      }).map(item => item.week))]

      if (isLive) {
        unique.forEach((k) => {
          sessions.push({ ...mfc.pdfs.filter(m => m.week === k) })
        })
        // sessions.reverse()
      } else {
        unique.forEach((k) => {
          sessions.push({ ...mfc.sessions.filter(m => m.week === k) })
        })
      }


      let purchaseId = null
      let videoUnlocked = 0

      const purchaseData = account ? (account.user.purchases.filter(p => p.mfc._id === mfc._id)[0] || account.user.purchases.filter(p => p.mfc === mfc._id)[0]) : null
      if (purchaseData) {
        purchaseId = purchaseData._id
        videoUnlocked = purchaseData.videoUnlocked || 0
      }

      const items = [
        {
          label: 'Accueil ',
          link: '/my-fiit-challenges'
        },
        {
          icon: false,
          label: mfc.name,
          link: false
        },
        {
          icon: false,
          // label: view === 'how' ? 'Accueil' : (isLive ? 'LIVES' : 'REPLAYS'),
          label: view === 'how' ? 'Accueil' : 'REPLAYS',
          link: false
        }
      ]

      let counter = 0

      const renderSwitch = () => {
        return (
          <div className="switch">
            <LinkScroll
              to="howSelector"
              spy
              smooth
              duration={1500}
              onClick={() => this.setTabAction('how')}
              className={classNames({
                switch__left: true,
                'switch__left--selected': view === 'how'
              })}
            >
                Accueil
            </LinkScroll>

            <div
              className={classNames({
                switch__right: true,
                'switch__right--selected': view !== 'how'
              })}
              onClick={() => {
                this.setTabAction('list')
              }}
            >
              { isLive ? 'REPLAYS' : 'PDFS' }
            </div>
            <div
              className={classNames({
                switch__selected: true,
                'switch__selected--right': (view === 'list' || view === 'video')
              })}
            />
          </div>
        )
      }

      return (
        <AppContainer>
          <Header
            account={account}
            isReplay={!isLive}
            icon={view === 'list' ? 'power-off' : 'arrow-left'}
            navigationLeft={view !== 'list' ? `my-fiit-challenge${isLive ? '' : '-replay'}/${mfc.name}` : '/'}
            navigationRight="/my-fiit-challenges"
            onClick={() => this.onClick()}
            children={renderSwitch()}
            challenge={mfc.name}
            isBack
          />
          { /* <Breadcrumb icon="redo-alt" title={mfc.name || 'mfc'} /> */}
          <div className="mfc" ref={this.containerRef}>
            {
              ((view !== 'how' && view !== 'video') || ((view === 'video' || view === 'list') && !isMobile)) && (
              <div className="mfc__left mfc__left--small" ref={this.containerSmall}>
                <div className="mfc__left__content">
                  <BreadcrumbDesktop items={items} />
                  {
                        mfc.futureReplay && mfc.futureReplay !== 'false' && (
                        <div className="mfc__left__content__message">
                          { mfc.futureReplay }
                        </div>
                        )
                      }
                  {
                        purchaseId && (
                        <React.Fragment>
                          <div className="is-mobile-tabs">
                            {
                                  ((view === 'video' || view === 'list') || !isMobile)
                                  && (
                                  <React.Fragment>
                                    <Element name="videoSelector">
                                      <div className="mfc-replay mfc-replay--replay">

                                        {
                                            (sessions && !!sessions.length) && (
                                            <>
                                              <div className="mfc-replay__title">
                                                { mfc.edition === 55 || mfc.edition === 45 || mfc.edition === 5 ? 'Mes Replays' : 'Mes contenus PDF'}
                                              </div>
                                              { mfc.edition !== 55 && mfc.edition !== 5 && !(mfc.edition === 45 && isLive) && (
                                              <div className="mfc-replay__title__pack-info">
                                                <Link to="/help">
                                                      Des problèmes pour visualiser les PDFS <FontAwesomeIcon icon={faQuestionCircle} />
                                                </Link>
                                              </div>
                                              )
                                              }
                                            </>
                                            )
                                          }


                                        {
                                              (!sessions || sessions.length === 0) && mfc.edition !== 5
                                              && (
                                              <div className="mfc-replay__week">
                                                <div className="mfc-replay__week__title" style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
                                                  <br /><FontAwesomeIcon icon={faInfoCircle} /> Votre challenge commencera le { mfc.from } { mfc.fromMonth } sur le groupe facebook! <br /><br />
                                                </div>
                                              </div>
                                              )
                                            }
                                        {
                                          (mfc.edition === 5 || mfc.edition === 55) && (
                                          <div className="mfc-replay__week">
                                            <div className="mfc-replay__week__title" style={{ fontSize: 16, fontWeight: '400', textAlign: 'left' }}>
                                              <br /><FontAwesomeIcon icon={faInfoCircle} /><b> Les lives ont lieu sur Facebook.</b> <br /><br />
                                              <div style={{ fontWeight: 'bold' }}>Les replays sont instantanéments disponibles sur facebook à la fin de la diffusion du live.</div>
                                              <br /><br />
                                                      Recharger la page du groupe jusqu’à apparition de la vidéo live à partir de 18h30 (une notification Facebook peut aussi apparaitre). Si vous ne trouvez pas le live :<br /><br /><br />
                                              <div style={{ textAlign: 'left' }}>
                                                        ⁃ Recharger la page du groupe (slider vers le bas ou sortir/entrer à nouveau sur le groupe)<br /><br />
                                                        ⁃ Rechercher le live dans les onglets vidéo / photos en haut du groupe<br /><br />
                                                        ⁃ Cliquer sur la notification si celle-ci apparaît<br /><br />
                                                <FontAwesomeIcon icon={faArrowRight} /> Les replays sont ajoutés sur cette page sous 24h<br /><br />
                                              </div>
                                            </div>
                                          </div>
                                          )

                                            }

                                        { (view === 'list' || !isMobile) && sessions && sessions.map((w, index) => (
                                          <div className="mfc-replay__week" key={`week-${w + index}`}>
                                            <div className="mfc-replay__week__title">Semaine {index + 1}</div>
                                            <div className="mfc-replay__week__hiits">
                                              { Object.entries(w).sort((a, b) => {
                                                return b[1].session - a[1].session
                                              }).map(([k, v], idx) => {
                                                counter++
                                                const unlocked = isLive ? true : (videoUnlocked >= counter - 1)
                                                return (
                                                  <a
                                                    className={`mfc-replay__week__hiits--w${index + 1} mfc-replay__week__hiits__box ${!unlocked ? 'forbidden-box mfc-replay__week__hiits__box--locked' : ''}`}
                                                    style={{ width: newWidth, transition: 'all .2s ease-in-out' }}
                                                    key={`mfc-replay__week__hiits__box-${idx}`}
                                                    onClick={() => ((isLive && !this.hasYoutubeId(index, idx)) ? this.getDoc(w[idx]) : (this.hasYoutubeId(index, idx) ? this.displayVideo(this.hasYoutubeId(index, idx).youtubeId, w[idx], idx, counter, v) : (unlocked
                                                                  && this.unlockVideo(purchaseId,
                                                                    w[idx].videoUrl,
                                                                    w[idx],
                                                                    idx,
                                                                    counter))))

                                                              }
                                                  >
                                                    {
                                                              (w[idx].type && w[idx].type === 'NUTRI')
                                                                ? (
                                                                  <div className="mfc-replay__week__hiits__box__container mfc-replay__week__hiits__box__container__nutri">
                                                                    <div className="mfc-replay__week__hiits__box__container mfc-replay__week__hiits__box__container__nutri__icon">
                                                                      <img alt="mfc-nutri" src={nutriText} />
                                                                    </div>
                                                                  </div>
                                                                )

                                                                : (
                                                                  <div className="mfc-replay__week__hiits__box__container">
                                                                    {isLive ? (this.hasYoutubeId(index, idx) ? 'LIVE' : 'PDF') : 'LIVE'}  { isLive ? w[idx].session : w[idx].day }
                                                                  </div>
                                                                )
                                                            }
                                                  </a>
                                                )
                                              })}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </Element>
                                  </React.Fragment>
                                  )
                                }
                          </div>
                          <Complements name={mfc.name} />
                        </React.Fragment>
                        )
                      }

                </div>
              </div>
              )
            }

            <div className={classNames({
              'mfc__right mfc__right--large mfc__right__replay mfc__right--padding': true,
              'mfc__right--full': view === 'how'
            })}
            >
              <div>
                {
                  view === 'video' && isMobile
                  && (
                  <ReplayNavigation
                    title={name}
                    actionLeft={this.goActionLeft}
                    actionRight={this.goActionRight}
                    actionCenter={this.goActionCenter}
                    index={index}
                    purchaseId={purchaseId}
                    isLive={!mfc.isReplay}
                    isMobile={isMobile}
                    flatifySessions={this.flatifySessions(sessions)}
                  />
                  )
                }
              </div>
              { /* <Element className="videoContainer" name="videoContainer" style={{ width: 'inherit', height: 'inherit'}}> */ }
              {
                (video && ((view === 'video' || !isMobile) && view !== 'how')) || (!isMobile && view === 'list')
                  ? (
                    <React.Fragment>
                      <div className="mfc__right__video">
                        <div className={`mfc__right__video__name ${isMobile ? 'mfc__right__video__name--mobile' : ''}`}>
                          { !isMobile
                              && (
                              <ReplayNavigation
                                title={name}
                                actionLeft={this.goActionLeft}
                                actionRight={this.goActionRight}
                                actionCenter={this.goActionCenter}
                                index={index}
                                isLive={!mfc.isReplay}
                                purchaseId={purchaseId}
                                isMobile={isMobile}
                                flatifySessions={this.flatifySessions(sessions)}
                                desktop
                              />
                              ) }
                        </div>
                        {
                          isMobile && (
                          <div className="mfc__right__video__new-name">
                            { name }
                          </div>
                          )
                        }

                        <iframe
                          className="youtube-player"
                          frameBorder="0"
                          allowFullScreen="1"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          title="YouTube video player"
                          width="640"
                          height="360"
                                // src={`https://www.youtube.com/embed/${video}?rel=0&amp;autoplay=0&amp;autohide=1&amp;showinfo=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A1987&amp;widgetid=1`}
                          src={`https://www.youtube.com/embed/${video}?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent`}
                          id="widget2"
                        />
                        <div className="mfc__right__pdfs">
                          {
                            (mfc.edition !== 5 && mfc.edition !== 55) && (
                              pdfsArrValues && pdfsArrValues.map((pdf, index) => (
                                <a
                                  onClick={() => this.getDoc(pdf.link, this.state.isYoutube)}
                                  className={`mfc__right__pdfs__link ${pdfsArrValues.length === 2 ? 'mfc__right__pdfs__link--mobile--double' : ''} ${isMobile ? 'mfc__right__pdfs__link--mobile' : ''} ${pdf.type === 'nutri' ? 'mfc__right__pdfs__link--nutri' : ''}`}
                                  key={`pdf-key-${index}`}
                                >
                                  <FontAwesomeIcon icon={faFilePdf} />{pdf.name}
                                </a>
                              ))
                            )
                          }
                        </div>
                      </div>
                    </React.Fragment>
                  )
                  : (
                    <div className="mfc__right__video">
                      { view === 'how'
                          && (
                          <React.Fragment>
                            { /* <div style={{ color: 'red' }} className={`mfc__right__video__name ${isMobile ? 'mfc__right__video__name--mobile' : ''}`}>
                              { name }
                            </div> */ }
                            <InstructionsEmbed mfc={mfc} rules={mfc.rules} starterPackPdfLink={mfc.starterPackPdfLink} groupeUrl={mfc.facebookUrl} youtubeCode={mfc.youtubeCode} version={mfc.edition} isLive={isLive} downloadPdf={downloadPdf} name={mfc.name} />
                          </React.Fragment>
                          )
                          }
                    </div>
                  )
              }
              { /* </Element> */ }
            </div>
          </div>
        </AppContainer>
      )
    }
    return (
      <div />
    )
  }
}

export default withRouter(MfcReplay)
