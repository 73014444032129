import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  deletePdf,
  getPdfs,
  reset,
  sendPdf,
  sendYoutube,
  findUser,
  getMfcs,
  createPrice,
  deletePrice,
  editPrice,
  getMfc, saveMfc, saveMfcYoutube
} from '../../../redux/admin/action'
import Dashboard from '../Admin/dashboard'
import Promocodes from '../Admin/promocodes'
import EditUser from '../Admin/edit-user'
import Pdfs from '../Admin/dashboard/pdfs'
import Pricing from '../Admin/settings/mfcs'
import Update from '../Admin/settings/mfcs/update'
import Stats from '../Admin/stats'

class AppRouter extends React.Component {
  render() {
    const {
      findUser,
      users,
      mfcs,
      getMfcs,
      saveMfcYoutube,
      createPrice,
      createSuccess,
      deletePrice,
      deleted,
      editPrice,
      edited,
      isReplay,
      getMfc,
      saveMfc,
      mfc
    } = this.props

    return (
      <Switch>
        <Route
          exact
          path={`/dashboard/users`}
          render={() => (
            <Dashboard
              findUser={findUser}
              users={users}
              saveMfc={saveMfc}
            />
          )}
        />
        <Route
          exact
          path={`/dashboard/user/:userId`}
          render={() => (
            <EditUser />
          )}
        />
        <Route
          exact
          path={`/dashboard/pricing`}
          render={() => (
            <Pricing
              getMfcs={getMfcs}
              mfcs={mfcs}
              createPrice={createPrice}
              createSuccess={createSuccess}
              deletePrice={deletePrice}
              deleted={deleted}
              saveMfc={saveMfc}
              edited={edited}
              isReplay={isReplay}
            />
          )
          }
        />
        <Route
          exact
          path={`/dashboard/statistics`}
          render={() => (
            <Stats
              getMfcs={getMfcs}
              mfcs={mfcs}
            />
          )
          }
        />
        <Route
          exact
          path={`/dashboard/promocodes`}
          render={() => (
            <Promocodes
              getMfcs={getMfcs}
              mfcs={mfcs}
            />
          )
          }
        />
        <Route
          exact
          path={`/dashboard/pdfs/:mfcId`}
          render={() => (
            <Pdfs
              saveMfcYoutube={saveMfcYoutube}
              edited={edited}
            />
          )
          }
        />
        <Route
          exact
          path={`/dashboard/pdfs`}
          render={() => (
            <Pdfs />
          )
          }
        />
        <Route
          exact
          path={`/dashboard/update-myfiit/:myfiit`}
          render={() => (
            <Update
              saveMfc={saveMfc}
              edited={edited}
              editPrice={editPrice}
              getMfc={getMfc}
              getMfcs={getMfcs}
              mfc={mfc}
              mfcs={mfcs}
            />
          )
          }
        />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  sendSuccess: state.Admin.sendSuccess,
  edited: state.Admin.edited,
  pdfs: state.Admin.pdfs,
  users: state.Admin.users,
  mfcs: state.Admin.mfcs,
  mfc: state.Admin.mfc,
  createSuccess: state.Admin.createSuccess,
  deleted: state.Admin.deleted,
  isReplay: state.Admin.isReplay
})


export default withRouter(connect(mapStateToProps, {
  sendPdf,
  sendYoutube,
  getPdfs,
  deletePdf,
  reset,
  findUser,
  getMfcs,
  createPrice,
  deletePrice,
  editPrice,
  getMfc,
  saveMfc,
  saveMfcYoutube
})(AppRouter))
